.link-review {
  font-size: 17px;
  @media screen and (max-width: 1440px) {
    font-size: 12px;
  }
  &_goto {

  }
  &_edit {
    color: $color-light;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

//СЕРТИФИКАТЫ
.submit-result {

}

.calc-submit {
  display: flex;
  align-items: center;
}

.submit-result {
  font-size: 14px;
  font-family: $gothampro;
  margin-left: 20px;
  &_active {
    color: #0dcdae;
  }
  &_error {
    color: #dd0c47;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  & button {
    width: fit-content;
    &:nth-of-type(1) {
      margin-right: 0;
      background-color: $color-violet;
      margin-bottom: 30px;
    }
  }
}

.sertificate-table {
  & input {
    color: $color-grey !important;
    font-weight: 400 !important;
    font-family: GothamPro;
  }
  & thead{
    & tr{
      & th{
        @media screen and (max-width: 768px){
          font-size: 11px !important;
        }
        &:last-child{
          @media screen and (max-width: 768px){
            padding-left: 0;
          }
        }
      }
    }
  }
}

//активный сертификат
.ser-active {
  & td {
    @media screen and (max-width: 768px){
      font-size: 11px !important;
      & input{
        &::placeholder{
          font-size: 11px !important;
        }
      }
    }
    &:nth-of-type(1) {
      background-color: #5ab5bb;
      border-right: 1px solid #5ab5bb !important;
      border-bottom: 1px solid #5ab5bb !important;
      border-top: 1px solid #5ab5bb !important;
      color: #fff !important;
      & input {
        color: #fff !important;
        &::placeholder {
          color: #fff !important;
        }
      }
    }
    &:nth-of-type(2) {
      background-color: #5ab5bb;
      border-right: 1px solid #5ab5bb !important;
      border-bottom: 1px solid #5ab5bb !important;
      border-top: 1px solid #5ab5bb !important;
      color: #fff !important;
      & input {
        color: #fff !important;
        &::placeholder {
          color: #fff !important;
        }
      }
    }
    &:nth-of-type(3) {
      background-color: #5ab5bb;
      border-right: 1px solid #5ab5bb !important;
      border-bottom: 1px solid #5ab5bb !important;
      border-top: 1px solid #5ab5bb !important;
      color: #fff !important;
      & input {
        color: #fff !important;
        &::placeholder {
          color: #fff !important;
        }
      }
    }
  }
}

//использован сертификат
.ser-used {
  & td {
    background-color: #5ab5bb;
    color: #fff !important;
    border-right: 1px solid #5ab5bb !important;
    border-bottom: 1px solid #5ab5bb !important;
    border-top: 1px solid #5ab5bb !important;
    & input {
      color: #fff !important;
      &::placeholder {
        color: #fff !important;
      }
    }
    @media screen and (max-width: 768px){
      font-size: 11px !important;
      & input{
        &::placeholder{
          font-size: 11px !important;
        }
      }
    }
  }
}

//Истекает срок действия
.ser-expire {
  border-top: 1px solid #5ab5bb !important;
  background-color: #5ab5bb;
  & td {
    background-color: #5ab5bb;
    color: #fff !important;
    @media screen and (max-width: 768px){
      font-size: 11px !important;
      & input{
        &::placeholder{
          font-size: 11px !important;
        }
      }
    }
    & input {
      color: #fff !important;
      border-right: 1px solid #5ab5bb !important;
      &::placeholder {
        color: #fff !important;
      }
    }
    &:nth-of-type(1){
      border-right: 1px solid #5ab5bb !important;
      border-bottom: 1px solid #5ab5bb !important;
      border-top: 1px solid #5ab5bb !important;
    }
    &:nth-of-type(2){
      border-right: 1px solid #5ab5bb !important;
      border-bottom: 1px solid #5ab5bb !important;
      border-top: 1px solid #5ab5bb !important;
    }
    &:nth-of-type(3){
      text-align: left;
      border-right: 1px solid #5ab5bb !important;
      border-bottom: 1px solid #5ab5bb !important;
      border-top: 1px solid #5ab5bb !important;
    }
    &:nth-of-type(4){
      border-right: 1px solid #5ab5bb !important;
    }
  }
}

//Сертификат не найден
.ser-undefined {
  & td {
    @media screen and (max-width: 768px){
      font-size: 11px !important;
      & input{
        &::placeholder{
          font-size: 11px !important;
        }
      }
    }
  }
}

.inform{
  color: $color-grey;
  font-family: $gothampromedium;
  font-size: 18px;
}