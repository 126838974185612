.quest-page {
}

@import "../../modules/quest-top-nav/quest-top-nav.scss";
@import "../../modules/about-quest/about-quest.scss";
@import "../../modules/about-quest/premium-legend.scss";
@import "../../modules/quest-icons/quest-icons.scss";
@import "../../modules/booking/booking.scss";
@import "../../modules/contact-quest/contact-quest.scss";
@import "../../modules/reviews/reviews.scss";

#return-to-top {
  z-index: 99999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  //background: rgb(0, 0, 0);
  //background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-violet;
  width: 50px;
  height: 50px;
  //display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  & svg {
    fill: #fff;
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
  @media screen and (max-width: 560px) {
    bottom: 5px;
    right: 5px;
  }
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: $color-green;
}

#return-to-top:hover i {
  color: #fff;
  top: 5px;
}

.similar-quest {
  padding-bottom: 70px;
  @media screen and (max-width: 568px){
    padding-bottom: 57px;
  }
  & .quest-name {
    & img {
      display: none;
    }
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  & a {
    border-radius: 30px;
    background-color: #d24a43d4;
    color: #fff;
    font-size: 32px;
    font-family: $helveticaneuecyrroman;
    padding: 12px 45px;
    transition: .3s;
    text-decoration: none;
    z-index: 2;
    &:hover {
      background-color: #d24a43;
      text-decoration: none;
    }
    @media screen and (max-width: 560px) {
      font-size: 16px;
      margin-bottom: 15px;
      padding: 8px 42px;
    }
  }
}