footer {
  background-color: $color-violet;
  padding-top: 28px;
  padding-bottom: 28px;
  color: #fff;
  font-family: $gothampro;
  @include rfs(16px);

  & .copyright {
    //margin-top: 35px;
    font-size: 14px;
    @media screen and (max-width: 1650px) {
      margin-left: 30px;
    }
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
      max-width: 100%;
      text-align: center;
      line-height: 22px;
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
      margin-left: 0;
      @include rfs(13px);
    }
    @media screen and (max-width: 365px) {
      @include rfs(10px);
    }
  }

  & .footer-subscribe {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
      justify-content: center;
      margin-left: 30px;
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
      margin-left: 0;
    }

    &__inner {
      & .subscribe-info {
        max-width: 300px;
        margin-bottom: 42px;
        @media screen and (max-width: 1199px) {
          max-width: 100%;
          text-align: center;
        }
        @media screen and (max-width: 768px) {
          margin-bottom: 19px;
          max-width: 100%;
        }
      }

      & .title {
        text-transform: uppercase;
        color: #fff
      }

      & .form {
        padding: 3px;
        //width: 335px;
        width: 100%;
        //height: 48px;
        //opacity: 0.46;
        border-radius: 15px;
        background-color: #aab1e3;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1650px) {
          padding: 0;
        }
        @media screen and (max-width: 365px) {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }

  & .legend {
    margin-left: 45px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 1199px) {
      margin-left: 30px;
      align-items: center;
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
      margin-left: 0;
      & .logo {
        width: 100%;
        text-align: center;

        & img {
          max-width: 100px;
        }
      }
    }

    & .info {
      max-width: 85%;
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 560px) {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 28px;
    padding-bottom: 28px;
    & .flex-order {
      display: flex;
      flex-direction: column;

      & .order-item:nth-of-type(1) {
        order: 3;
        @media screen and (max-width: 768px) {
          & .copyright {
            text-align: center;
            @media screen and (max-width: 365px) {
              @include rfs(10px);
            }
          }
        }
      }

      & .order-item:nth-of-type(2) {
        order: 1;
        @media screen and (max-width: 768px) {
          & .footer-subscribe {
            text-align: center;

            &__inner {
              margin: 0 auto;
            }

            @media screen and (max-width: 365px) {
              @include rfs(13px);
            }
          }
        }
      }

      & .order-item:nth-of-type(3) {
        order: 2;
        @media screen and (max-width: 768px) {
          & .legend {
            text-align: left;
            @media screen and (max-width: 365px) {
              @include rfs(11px);
            }
          }
        }
      }

      & .order-item:nth-of-type(4) {
        order: 4;
      }
    }
  }

  & .logo {
    display: flex;
    justify-content: center;
    width: 80%;

    & img {
      max-width: 85px;
    }
  }
}

.input-form {
  padding-top: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  padding-left: 26px;
  @media screen and (max-width: 1650px) {
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  @media screen and (max-width: 1440px) {
    padding: 3px 3px 3px 26px;
  }
  @media screen and (max-width: 365px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & input {
    color: #fff;
    @media screen and (max-width: 365px) {
      max-width: 52%;
    }

    &::placeholder {
      color: #fff;
    }
  }

  & button {
    color: $color-violet;
    background-color: #fff;
    border-radius: 15px;
    padding: 12px 16px;
    font-family: $gothampromedium;
    transition: .2s;

    &:hover {
      color: #fff;
      background-color: $color-violet;
    }
  }
}


.footer-info {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &_links {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      width: 100% !important;
      text-align: center;
    }

    & a {
      color: #fff;
    }
  }
}

.footerlinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footerlinks a {
  text-decoration: none;
  color: #fff;
  margin-bottom: 7px;
  font-size: 18px;
  padding-bottom: 0px;
  border-bottom: 1px solid #fff;
  max-width: max-content;
}

@media screen and (max-width: 1025px) {
  footer .logo {
    width: auto;
  }
}

@media screen and (max-width: 1199px) {
  .footer-info {
    width: 50%;
  }
  footer .logo {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}


@media screen and (max-width: 768px) {
  .footerlinks {
    margin-bottom: 30px;
    margin-top: 20px;
    text-align: center;
    align-items: center;
  }
  .footerlinks a {
    font-size: 18px;
    border-bottom: 1px solid #fff;
    padding-bottom: 1px;
    max-width: max-content;
  }
  .footer-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  footer .legend .logo {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-bottom: 25px;
  }
}

.dev-link {
  margin-bottom: 25px;
  //position: absolute;
  //bottom: 0;
  @media screen and (max-width: 1650px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 1199px) {
    position: initial;
    margin-top: 30px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    font-size: 11px;
    margin-left: 0;
  }

  & a {
    color: #fff;
    font-family: $gothampromedium;
  }
}


.add-quest {
  background-color: #0dcdae;
  color: #fff;
  font-size: 16px;
  font-family: GothamProBold;
  padding: 10px 23px;
  text-decoration: none;
  transition: .3s;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 46px;
  min-width: 200px;
  height: 38px;
  @media screen and (max-width: 1366px) {
    font-size: 13px;
    color: #fff;
    min-width: 174px;
    height: 31px;
    padding: 8px 0px;
  }
  @media screen and (max-width: 1280px) {
    min-width: 153px;
  }
}

.add-quest:hover {
  text-decoration: none;
  background-color: #fff;
  color: $color-green;
}

.social-link-container {
  max-width: 360px;
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
  margin: 0 auto;
  padding-left: 1%;
  @media screen and (max-width: 1366px) {
    max-width: 345px;
  }
  @media screen and (max-width: 1199px) {
    padding-left: 0;
    max-width: initial;
    width: 140px;
    margin: 0 auto;
    padding-top: 0;
    margin-bottom: 23px;
    justify-content: space-between;
  }

  & a {
    margin-right: 20px;
    @media screen and (max-width: 1199px) {
      margin-right: 0;
    }

    & svg {
      transition: .2s;
      width: 25px;
      height: 25px;
      fill: #fff;

      &:hover {
        fill: #9d9db9;
      }
    }
  }
}