.home-genres-container{
  max-width: 1030px;
  margin: 0 auto;
}


.quest-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: 1366px) {
    margin-bottom: 1px;
  }
  @media screen and (max-width: 991px) {
    margin-bottom: 9px;
  }
  @media screen and (max-width: 414px) {
    justify-content: space-around;
  }
  & a {
    color: $color-violet;
    background-color:#eff0fb;
    font-family: $gothampro;
    @include rfs(12px);
    height: 25px;
    width: 106px;
    border: 1px solid $color-violet;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: .3s;
    margin-bottom: 12px;
    text-align: center;
    //text-transform: capitalize;
    //initial-letter: 1;
    @media screen and (max-width: 768px) {
      height: auto;
      width: auto;
      padding: 4px 5px;
      line-height: 10px;
    }
    &:hover {
      border-color: #eff0fb;
      color: #eff0fb;
      background-color: $color-violet;
    }
  }
}

.quest-category::first-letter {
  initial-letter: 2;
  text-transform: capitalize;
  text-transform: uppercase;
}

.quest-category a::first-letter {
  initial-letter: 2;
  text-transform: capitalize;
  text-transform: uppercase;
}