.blog-wrapper {
  & .container {
    max-width: 1320px;
    @media screen and (max-width: 1366px) {
      max-width: 1185px;
    }
  }
}

@import "../../modules/blog/blog-preview.scss";
@import "../../modules/blog/post-content.scss";
@import "../../modules/blog/related-banner.scss";

.new-articles {
  &__items {
    padding-left: 10px;
    padding-right: 10px;
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
}

.blog-content {
  & .home-search {
    margin-bottom: 10px;
  }
}

.blog-title {
  color: $color-grey;
  font-size: 38px;
  font-family: $gothampro;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
}

.blog-switch {
  position: relative;
  margin: 0 auto;
  margin-bottom: 70px;
  border-radius: 27px;
  display: flex;
  justify-content: space-around;
  max-width: 391px;
  background-color: #626ecc;
  box-shadow: -4px 1px 15px 0 rgba(172, 172, 172, .67);
  color: #9d9db9;
  font-size: 1.25rem;
  padding: 10px 11px;
  & a {
    color: $color-light;
    font-family: $gothampromedium;
    font-size: 20px;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
    &.active {
      color: #fff;
    }
  }
  & .separator {
    width: 2px;
    color: #9d9db9;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
  }
  &:before{
    content: "";
    width: 20px;
    height: 1px;
    background-color: $color-light;
    transform: rotate(90deg);
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top:22px;
  }
}

