.quest-icon-block {
  max-width: 1040px;
  margin: 0 auto 34px;
  width: 100%;
  margin-bottom: vw(94px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 300px;
    margin-bottom: 43px;
  }
  .icon-half-block {
    &:nth-of-type(2) {
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    & .icon-item {
      display: flex;
      margin-right: 47px;
      margin-bottom: 30px;
      align-items: center;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        margin-right: 0;
      }
      @media screen and (max-width: 600px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      &__name {
        display: flex;
        align-items: center;
        margin-right: 33px;
        @media screen and (max-width: 991px) {
          flex-direction: column;
          margin-right: 0;
        }
        @media screen and (max-width: 600px) {
          flex-direction: row;
          align-items: center;
        }
        & .icon-bg {
          background-color: $color-green;
          border-radius: 50%;
          width: 48px;
          max-height: 48px;
          min-width: 48px;
          height: 48px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 991px) {
            margin-right: 0;
            margin-bottom: 12px;
          }
          @media screen and (max-width: 600px) {
            margin-right: 25px;
          }
          & svg {
            fill: #fff;
            width: 30px;
            height: 30px;
          }
        }
        & .icon-name {
          @include rfs(14px);
          color: $color-grey;
          font-family: $helveticaneuecyrroman;
          text-transform: uppercase;
        }
      }
      &__value {
        @include rfs(36px);
        font-family: $helveticaneuecyrroman;
        color: $color-green;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 600px) {
          font-size: 34px;
        }
        & span {
          &:nth-of-type(1) {
          }
        }
      }
    }

  }
}

.bonus-items-wrapper {
  position: relative;
  border-radius: 20px;
  border: 1px solid $color-green;
  padding: 10px 38px;
  min-height: 200px;

  @media screen and (max-width: 991px) {
    width: 85%;
    margin: 0 auto;
    margin-top: 100px;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 568px) {
    width: 100%;
  }
  &::before {
    content: "";
    position: absolute;
    left: -4px;
    top: 54px;
    background-color: #fff;
    width: 8px;
    height: 112px;
    transform: rotate(180deg);
    @media screen and (max-width: 991px) {
      left: -4px;
      top: -2px;
      background-color: #fff;
      width: 45%;
      height: 5px;
      transform: rotate(0deg);
    }
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    @media screen and (max-width: 670px) {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
    @media screen and (max-width: 568px) {
      display: none;
    }
  }
  &__title {
    position: absolute;
    left: -212px;
    top: 44px;
    font-family: $gothamproblack;
    font-size: 42px;
    background: -webkit-linear-gradient($color-green, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 235px;
    background-color: #fff;
    z-index: 16;
    padding: 10px;
    & span {
      float: right;
    }
    @media screen and (max-width: 991px) {
      left: 15px;
      top: -95px;
    }
    @media screen and (max-width: 670px) {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
    @media screen and (max-width: 568px) {
      font-size: 31px;
    }
  }
  &__container {
    & .bonus-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      &__icon {
        margin-right: 25px;
        & svg {
          fill: $color-green;
          width: 25px;
          height: 25px;
        }
      }
      &__title {
        color: $color-grey;
        font-size: 14px;
        font-family: $helveticaneuecyrroman;
      }
    }
  }
}

.icon-half-block {
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}


.quest-icon-block{
  &.standart{
    & .icon-half-block{
      display: flex;
      justify-content: space-between;
    }
  }
}