.header {
  &_sertificate {
    @media screen and (max-width: 768px) {
      height: 255px;
    }
  }
}

.sertificate-intro {
  margin-bottom: 78px;
  color: #fff;
  font-family: $gothampro;
  font-size: 32px;
  line-height: 48px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  @media screen and (max-width: 560px) {
    font-size: 19px;
    line-height: 24px;
  }
  &__title {
    & div {
      &:nth-of-type(1) {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}

.gift {
  margin-bottom: 110px;
  @media screen and (max-width: 1366px) {
    margin-bottom: 76px;
  }
  &__title {
    color: $color-grey;
    font-size: 34px;
    line-height: 48px;
    font-family: $gothampro;
    margin-bottom: 70px;
    text-align: center;

    @media screen and (max-width: 768px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 560px) {
      font-size: 30px;
      margin-top: 20px;
      line-height: 32px;
    }
    @media screen and (max-width: 414px) {
      font-size: 23px;
      line-height: 27px;
    }
  }
  & .gift-icons {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 559px) {
      flex-direction: column;
      align-items: center;
    }
    & .gift-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $color-grey;
      font-family: $gothampro;
      font-size: 18px;
      text-align: center;
      max-width: 330px;
      @media screen and (max-width: 1024px) {
        max-width: 250px;
      }
      @media screen and (max-width: 960px) {
        font-size: 14px;
      }
      @media screen and (max-width: 768px) {
        //margin-bottom: 43px;
        max-width: 170px;
      }
      @media screen and (max-width: 560px) {
        font-size: 14px;
      }
      @media screen and (max-width: 559px) {
        margin-bottom: 22px;
        max-width: 200px;
      }

      & div {
        &:nth-of-type(1) {
          margin-bottom: 23px;
          & img {
            max-width: 168px;
            @media screen and (max-width: 1366px) {
              max-width: 120px;
            }
            @media screen and (max-width: 1280px) {
              max-width: 100px;
            }
            @media screen and (max-width: 560px) {
              max-width: 71px;
            }
            @media screen and (max-width: 559px) {
              max-width: 88px;
            }
          }
        }

      }
    }
  }
}

.rate {
  margin-bottom: 132px;
  @media screen and (max-width: 560px) {
    margin-bottom: 0;
  }
  & .plan-align {
    @media screen and (min-width: 1367px) {
      justify-content: space-between;
      & .col-plan {
        max-width: 30%;
      }
    }
  }
}

.plan-column {
  position: relative;
  @media screen and (max-width: 991px) {
    margin: 0 30px 80px;
  }
  @media screen and (max-width: 560px) {
    margin: 0 5px 80px;
  }
  @media screen and (max-width: 414px) {
    margin: 0 12px 80px;
  }
  &__title {
    text-align: center;
    font-size: 24px;
    font-family: $helveticaneuecyrroman;
    text-transform: uppercase;
    margin-bottom: 35px;
    &_silver {
      color: $color-light;
    }
    &_gold {
      color: #bd904d;
    }
    &_blue {
      color: #5ab5bb;
    }
  }
  &__content {
    border-radius: 20px;
    //box-shadow: 0px 8px 13.3px 2.7px rgba(37, 37, 37, 0.27);
    box-shadow: 0 8px 13.3px 12px rgba(37, 37, 37, .27);
    padding: vw(47px) vw(57px) vw(33px);
    @media screen and (max-width: 1440px) {
      box-shadow: 0 8px 13.3px 5.7px rgba(37, 37, 37, .27);
    }
    @media screen and (max-width: 1280px) {
      padding: vw(47px) vw(15px) vw(33px);
    }

    @media screen and (max-width: 991px) {
      padding-bottom: 35px;
      padding: vw(80px) vw(15px) vw(33px);
    }
  }

}

.plan-term {
  margin-bottom: 41px;
  @media screen and (max-width: 1440px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 560px) {
    margin-bottom: 23px;
  }
  &__title {
    color: $color-grey;
    font-size: 20px;
    line-height: 17px;
    font-family: $gothampro;
    margin-bottom: 17px;
    text-align: center;
    @media screen and (max-width: 1280px) {
      font-size: 14px;
      margin-bottom: 12px;
    }

  }
  &__info {
    color: $color-green;
    font-family: $helveticaneuecyrbold;
    line-height: 17px;
    font-size: 30px;
    text-align: center;

  }
}

.plan-info {
  margin: 0 auto;
  margin-bottom: 31px;
  max-width: 330px;
  font-size: 18px;
  font-family: $gothampro;
  color: $color-grey;
  text-align: center;

  @media screen and (max-width: 1280px) {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    width: 255px;
  }
  @media screen and (max-width: 414px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

.plan-city {
  margin: 0 auto;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #acacac;
  max-width: 200px;
  height: 20px;
  @media screen and (max-width: 768px) {
    border-bottom: none;
  }
  & .city-form {
    display: flex;
    justify-content: center;
    & input {
      text-align: center;
      color: $color-light;
      font-family: $gothampro;
      font-size: 14px;
      border-bottom: 1px solid $color-light;
      &::placeholder {
        color: $color-light;

      }
    }
  }
  & .dropdown-select {
    @media screen and (max-width: 768px) {
      border-bottom: 1px solid #acacac;
      max-width: 200px;
    }
    & .city {
      @media screen and (max-width: 768px) {
        position: relative;
      }

      & .dropdown-select-current {
        & .active {
          color: $color-light;
          border-bottom: none;
        }
        & svg {
          fill: $color-light;
        }
      }
    }
    & .dropdown-select-list {
      @media screen and (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        min-width: 130px;
      }
      left: -37px;
      & a {
        color: $color-light;
        &:hover {
          color: $color-violet;
        }
      }
    }
  }
}

.plan-more {
  text-align: center;
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;
  bottom: 21px;
  & a {
    color: $color-violet;
    font-family: $helveticaneuecyrroman;
    font-size: 14px;
    text-align: center;
    transition: .3s;
    text-decoration: none;
    border-bottom: 1px solid #626ecc;
    &:hover {
      color: $color-green;
      border-bottom: 1px solid $color-green;
    }
  }
}

.plan-btn {
  //background-color: #fff;
  //font-size: 24px;
  //font-family: $gothampro;
  //padding: vw(20px) vw(37px);
  //text-transform: uppercase;
  //text-align: center;
  //border-radius: 30px;
  //transition: .3s;
  //max-width: 246px;
  //margin: 0 auto;
  //margin-bottom: 48px
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 48px;
  z-index: 3;
  & button {
    width: 100%;
    background-color: #fff;
    font-size: 24px;
    font-family: $gothampro;
    padding: vw(20px) vw(37px);
    text-transform: uppercase;
    text-align: center;
    border-radius: 30px;
    transition: .3s;
    max-width: 246px;
    z-index: 3;
    @media screen and (max-width: 1024px) {
      padding: vw(15px) vw(24px);
    }
    @media screen and (max-width: 960px) {
      font-size: 18px;
      padding: vw(20px) vw(20px);
    }
    @media screen and (max-width: 560px) {
      font-size: 14px;
      padding: 15px 10px;
    }
  }
  &_grey {
    & button {
      color: #949696;
      border: 1px solid #949696;
      &:hover {
        cursor: pointer;
        background-color: #949696;
        color: #fff;
        box-shadow: 0 8px 13.3px -4.3px rgba(37, 37, 37, 0.15);
      }
    }
  }
  &_gold {
    & button {
      color: #bd904d;
      border: 1px solid #bd904d;
      &:hover {
        cursor: pointer;
        background-color: #bd904d;
        color: #fff;
        box-shadow: 0 8px 13.3px -4.3px rgba(37, 37, 37, 0.15);
      }
    }
  }
  &_blue {
    & button {
      color: #5ab5bb;
      border: 1px solid #5ab5bb;
      &:hover {
        cursor: pointer;
        background-color: #5ab5bb;
        color: #fff;
        box-shadow: 0 8px 13.3px -4.3px rgba(37, 37, 37, 0.15);
      }
    }
  }
}

.plan-result {
  @media screen and (max-width: 1280px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 1280px) {
    padding-right: 5px;
    padding-left: 5px;
  }
  & .quest-item-wrapper {
    height: 478px;
    overflow: hidden;
    @media screen and (max-width: 1440px) {
      height: 400px;
    }
    @media screen and (max-width: 1280px) {
      height: 370px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 560px) {
      height: 330px;
    }
    @media screen and (max-width: 414px) {
      margin-bottom: -24px;
    }
    @media screen and (max-width: 320px) {
      margin-bottom: -34px;
    }
  }
  & .quest-item {
    @media screen and (max-width: 1366px) {
      height: 386px;
    }
    @media screen and (max-width: 991px) {
      max-width: 360px;
    }
    @media screen and (max-width: 560px) {
      max-width: 329px;
      height: 346px;
    }
  }
  & .quest-name {
    & img {
      display: none;
    }
  }
  & .quest-item__rating {
    top: 21px;
    left: 19px;
  }
  & .stars svg {
    width: 19px;
    height: 15px;
    margin-right: 3px;
  }
  & .quest-item__icons {
    top: 19px;
    right: 10px;
    & .level_brain {
      margin-bottom: 20px;
      width: 36px;
      height: 36px;
      & svg {
        width: 22px;
        height: 22px;
      }
    }
    & .level_mask {
      width: 36px;
      height: 36px;
      & svg {
        width: 22px;
        height: 22px;
      }
    }
  }
  & .q-top {
    height: 37%;
    @media screen and (max-width: 1440px) {
      height: 45%;
    }
    @media screen and (max-width: 1280px) {
      height: 55%;
    }
  }
  & .q-bottom__btns {
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
    @media screen and (max-width: 991px) {
      align-items: center;
    }
  }
}

@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .plan-align {
    display: flex;
    justify-content: center;
  }
  .col-plan {
    max-width: 30.333333%;
  }
}

.plan-result .quest-item__rating {
  & .stars {
    & svg {
      @media screen and (max-width: 1024px) {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.plan-result .quest-item-wrapper .quest-item .rating-count .star {
  height: 20px;
  left: -104px;
  @media screen and (max-width: 991px) {
    left: -115px;
    height: 16px;
  }
}