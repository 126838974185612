.account-worksheet {
  background-color: #f0f2f9;
  padding-top: 40px;
  padding-bottom: 40px;
  & .worksheet-wrapper {
    padding-top: 32px;
    margin: 0 auto;
    max-width: 1020px;
    border-radius: 30px;
    background-color: #fff;
    &__title {
      text-align: center;
      font-size: 30px;
      font-family: $gothampro;
      color: $color-grey;
      margin-bottom: 44px;
      @media screen and(max-width: 460px) {
        font-size: 18px;
      }
    }
    /* width */
    & ::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    /* Handle */
    & ::-webkit-scrollbar-thumb {
      background: #908c8c;
      border-radius: 10px;
    }
    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
      background: $color-light;
    }
  }
}

.fieldsetwrapper {

  padding: 32px 207px 32px 57px;
  @media screen and (max-width: 768px) {
    padding: 32px 20px 32px 20px;
  }
}

.worksheet-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  &__label {
    margin-bottom: 28px;
    color: $color-light;
    font-size: 18px;
    font-family: $gothampro;
    @media screen and(max-width: 460px) {
      font-size: 13px;
    }
    &:hover {
      cursor: default;
    }
  }
  & input {
    background-color: #f4f4f4;
    border: 1px solid #b2b3b3;
    font-family: $gothampromedium;
    font-size: 16px;
    color: $color-grey;
    border-radius: 7px;
    padding: 7px 16px;
    width: 100%;
    transition: .3s;
    &:focus {
      background-color: #e0e0fa;
    }
    &.tel {
      &::placeholder {
        color: $color-light;
      }
    }
  }
  &_checker {
    & .filter-checker {
      margin-bottom: 19px;
      & label {
        color: $color-grey;
        font-size: 14px;
        font-family: $helveticaneuecyrroman;
        & a {
          color: $color-grey;
          font-size: 14px;
          font-family: $helveticaneuecyrroman;
          text-decoration: underline;
          transition: .3s;
          &:hover {
            color: $color-violet;
          }
        }
      }
    }
  }
  &_btn {
    & button {
      background-color: $color-violet;
      color: #fff;
      font-size: 16px;
      font-family: GothamProBold;
      padding: 13px 27px;
      text-decoration: none;
      transition: .3s;
      text-align: center;
      border-radius: 15px;
      box-shadow: 0px 4px 21px -4px rgba(0, 0, 0, .5);
      &:hover {
        cursor: pointer;
        background-color: $color-green;
        box-shadow: -1px 0px 29px -3px rgba(0, 0, 0, 0.5);
      }
      &.wide {
        padding: 13px 42px;
      }
    }
  }
  &_link {
    & a {
      color: $color-green;
      font-family: $helveticaneuecyrroman;
      font-size: 14px;
      transition: .3s;
      &:hover {
        color: $color-violet;
      }
    }
  }
  &_center {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 110px;
    & .center-inner {
      width: 100%;
    }
  }
}

/* Dropdown Button */
//.dropbtn {
//  border-radius: 7px;
//  background-color: #f4f4f4;
//  color: #acacac;
//  padding: 7px 16px;
//  font-family: GothamProMedium;
//  font-size: 16px;
//  border: none;
//  cursor: pointer;
//}
//
///* Dropdown button on hover & focus */
//.dropbtn:hover, .dropbtn:focus {
//}
//
///* The search field */
//#myInput {
//  border-box: box-sizing;
//  background-image: url('searchicon.png');
//  background-position: 14px 12px;
//  background-repeat: no-repeat;
//  font-size: 16px;
//  font-family: $gothampro;
//  border-radius: 7px;
//  padding: 7px 16px;
//  border: none;
//  border: 1px solid $color-light;
//  &:focus {
//    background-color: #fff;
//    text-transform: none;
//    font-family: $gothampro;
//    font-size: 16px;
//  }
//}

.custom-selectpicker {
  .bootstrap-select .dropdown-toggle .filter-option {
    background-color: #f4f4f4;
    border-radius: 7px;
    border: 1px solid #b2b3b3;
    //padding: 4px 16px;
  }
  .bootstrap-select > .dropdown-toggle.bs-placeholder {
    padding: 4px 16px;
    border-radius: 7px;
    position: relative;
  }
  //.bootstrap-select .dropdown-toggle:before {
  //  transition: .3s;
  //  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDUxLjg0NyA0NTEuODQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NTEuODQ3IDQ1MS44NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjI1LjkyMywzNTQuNzA2Yy04LjA5OCwwLTE2LjE5NS0zLjA5Mi0yMi4zNjktOS4yNjNMOS4yNywxNTEuMTU3Yy0xMi4zNTktMTIuMzU5LTEyLjM1OS0zMi4zOTcsMC00NC43NTEgICBjMTIuMzU0LTEyLjM1NCwzMi4zODgtMTIuMzU0LDQ0Ljc0OCwwbDE3MS45MDUsMTcxLjkxNWwxNzEuOTA2LTE3MS45MDljMTIuMzU5LTEyLjM1NCwzMi4zOTEtMTIuMzU0LDQ0Ljc0NCwwICAgYzEyLjM2NSwxMi4zNTQsMTIuMzY1LDMyLjM5MiwwLDQ0Ljc1MUwyNDguMjkyLDM0NS40NDlDMjQyLjExNSwzNTEuNjIxLDIzNC4wMTgsMzU0LjcwNiwyMjUuOTIzLDM1NC43MDZ6IiBmaWxsPSIjYmFiYWJhIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
  //  background-position: 1px 8px;
  //  width: 18px;
  //  height: 20px;
  //  background-size: 13px;
  //  background-repeat: no-repeat;
  //  margin-left: 0;
  //  border-top: none;
  //  border-right: none;
  //  border-bottom: 0;
  //  border-left: none;
  //}
  & .bootstrap-select {
    & .dropdown-menu {
      & li {
        & a {
          font-family: $gothampro;
          font-size: 14px;
          color: #acacac;
          &:hover {
            //background: rgb(98,110,204);
            background: linear-gradient(90deg, rgba(98, 110, 204, 1) 0%, rgba(129, 140, 226, 1) 100%);
            color: #fff;
          }
        }
      }
    }
    & > .dropdown-toggle.bs-placeholder:hover {
      background-color: #f4f4f4;
    }
    &.show {
      .dropdown-toggle::after {
        transform: rotate(180deg);
        background-position: 1px -1px;
      }
      .dropdown-toggle .filter-option {
        background-color: #f4f4f4;
      }
    }
  }
  & .dropdown-item.active, .dropdown-item:active {
    background-color: $color-violet;
    color: #fff;
  }
  & .dropdown-item.active, .dropdown-item:active a span.text {
    color: #fff;
  }
  .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    font-family: $gothampro;
    font-size: 16px;
    color: #acacac;
  }
  .bs-searchbox .form-control {
    border: 1px solid $color-light;
    outline: none;
    background-color: #fff;
    box-shadow: none;
  }
  .dropdown-toggle::after {
    transition: .3s;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDUxLjg0NyA0NTEuODQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NTEuODQ3IDQ1MS44NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjI1LjkyMywzNTQuNzA2Yy04LjA5OCwwLTE2LjE5NS0zLjA5Mi0yMi4zNjktOS4yNjNMOS4yNywxNTEuMTU3Yy0xMi4zNTktMTIuMzU5LTEyLjM1OS0zMi4zOTcsMC00NC43NTEgICBjMTIuMzU0LTEyLjM1NCwzMi4zODgtMTIuMzU0LDQ0Ljc0OCwwbDE3MS45MDUsMTcxLjkxNWwxNzEuOTA2LTE3MS45MDljMTIuMzU5LTEyLjM1NCwzMi4zOTEtMTIuMzU0LDQ0Ljc0NCwwICAgYzEyLjM2NSwxMi4zNTQsMTIuMzY1LDMyLjM5MiwwLDQ0Ljc1MUwyNDguMjkyLDM0NS40NDlDMjQyLjExNSwzNTEuNjIxLDIzNC4wMTgsMzU0LjcwNiwyMjUuOTIzLDM1NC43MDZ6IiBmaWxsPSIjYmFiYWJhIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
    background-position: 1px 8px;
    width: 18px;
    height: 20px;
    background-size: 13px;
    background-repeat: no-repeat;
    margin-left: 0;
    border-top: none;
    border-right: none;
    border-bottom: 0;
    border-left: none;
    position: absolute;
    right: 9px;
  }
}

//Изменить количество в анкете
.worksheet-item form {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
}

.worksheet-item .value-button {
  display: inline-block;
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
  padding: 11px 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.worksheet-item .value-button:hover {
  cursor: pointer;
}

.worksheet-item form #decrease {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

.worksheet-item form #increase {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
}

.worksheet-item form #input-wrap {
  margin: 0px;
  padding: 0px;
}

.worksheet-item input#number {
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 40px;
}

.worksheet-item input[type=number]::-webkit-inner-spin-button,
.worksheet-item input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.worksheet-item {
  &_checker {
    & .filter-checker {
      margin-bottom: 19px;
      & label {
        color: $color-grey;
        font-size: 14px;
        font-family: $helveticaneuecyrroman;
        & a {
          color: $color-grey;
          font-size: 14px;
          font-family: $helveticaneuecyrroman;
          text-decoration: underline;
          transition: .3s;
          &:hover {
            color: $color-violet;
          }
        }
      }
    }
  }
}

.custom-tag-selector {
  & .select2-container {
    background-color: #f4f4f4;
    font-family: GothamProMedium;
    font-size: 16px;
    color: #393939;
    border-radius: 7px;
    padding: 4px 16px;
    width: 100% !important;
    transition: .3s;
    position: relative;
    height: 100%;
    &::before {
      transition: .3s;
      content: ' ';
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDUxLjg0NyA0NTEuODQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NTEuODQ3IDQ1MS44NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjI1LjkyMywzNTQuNzA2Yy04LjA5OCwwLTE2LjE5NS0zLjA5Mi0yMi4zNjktOS4yNjNMOS4yNywxNTEuMTU3Yy0xMi4zNTktMTIuMzU5LTEyLjM1OS0zMi4zOTcsMC00NC43NTEgICBjMTIuMzU0LTEyLjM1NCwzMi4zODgtMTIuMzU0LDQ0Ljc0OCwwbDE3MS45MDUsMTcxLjkxNWwxNzEuOTA2LTE3MS45MDljMTIuMzU5LTEyLjM1NCwzMi4zOTEtMTIuMzU0LDQ0Ljc0NCwwICAgYzEyLjM2NSwxMi4zNTQsMTIuMzY1LDMyLjM5MiwwLDQ0Ljc1MUwyNDguMjkyLDM0NS40NDlDMjQyLjExNSwzNTEuNjIxLDIzNC4wMTgsMzU0LjcwNiwyMjUuOTIzLDM1NC43MDZ6IiBmaWxsPSIjYmFiYWJhIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
      background-position: 1px 8px;
      width: 18px;
      height: 20px;
      background-size: 13px;
      background-repeat: no-repeat;
      margin-left: 0;
      border-top: none;
      border-right: none;
      border-bottom: 0;
      border-left: none;
      position: absolute;
      right: 9px;
    }
    &.select2-container--open {
      &::before {
        transform: rotate(180deg);
        top: 11px;
      }
    }
  }
  & .select2-selection.select2-selection--multiple {
    min-height: auto;
    background-color: #f4f4f4;
    border: none;
    border-radius: 7px;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: $color-green;
    font-size: 12px;
    font-family: $helveticaneuecyrroman;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 4px 21px;
    position: relative;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $color-violet !important;
    font-size: 14px;
    font-family: $gothampro;
    color: white
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    position: absolute;
    right: 4px;
    color: #fff;
    font-size: 14px;
  }

  .tagselector {
    /* width */
    & ::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    /* Handle */
    & ::-webkit-scrollbar-thumb {
      background: #908c8c;
      border-radius: 10px;
    }
    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
      background: $color-light;
    }
  }
}

.worksheet-item {
  &.textfield {
    & label {
      max-width: 100%;
      text-align: left;
      & span {
        &:nth-of-type(1) {
        }
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
    & textarea {
      font-family: GothamProMedium;
      font-size: 16px;
      color: #393939;
      border-radius: 7px;
      padding: 7px 16px;
      width: 100%;
      transition: .3s;
    }
  }
  &.numberfield {
    position: relative;
    max-width: 253px;
    @media screen and(max-width: 460px) {
      max-width: 100%;
    }
    & label {
      max-width: 60%;
      text-align: left;
      & span {
        &:nth-of-type(1) {
        }
        &:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
    & input {
      font-family: GothamProMedium;
      font-size: 16px;
      color: #393939;
      border-radius: 7px;
      padding: 7px 16px;
      width: 100%;
      transition: .3s;
    }
    & .numberfield-secondary {
      position: absolute;
      right: 0;
      bottom: 0px;
      background-color: #acacac;
      font-size: 16px;
      font-family: GothamPro;
      color: #fff;
      width: 56px;
      height: 34px;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.counter {
    margin-right: 46px;
    @media screen and(max-width: 460px) {
      margin-right: 0;
    }
    & .btn-outline-secondary {
      background-color: $color-violet;
      border-color: inherit;
      color: #fff;
      font-family: $gothampro;
      font-size: 18px;
      padding: 0px .75rem;
      border-radius: 7px;
    }
    & .form-control {
      border: none;
    }
    & .counter-title {
      margin-bottom: 28px;
      color: #acacac;
      font-size: 18px;
      font-family: GothamPro;
      @media screen and(max-width: 460px) {
        text-align: center;
      }
    }
    & input {
      &.form-control {
        border: 1px solid #b2b3b3;
        max-width: 225px;
        background-position: initial;
        @media screen and(max-width: 991px) {
          max-width: 115px;
        }
        @media screen and(max-width: 460px) {
          max-width: 225px;
        }
      }
    }
    & .input-group-prepend {
      & button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        box-shadow: 0px 0px 10px 0px rgba(98, 110, 204, 0.5);
        border: 1px solid #626ecc;

      }
    }
    & .input-group-append {
      & button {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: 0px 0px 10px 0px rgba(98, 110, 204, 0.5);
        border: 1px solid #626ecc;
      }
    }
  }
}

.worksheet-item {
  &_file-upload {
    & .container {
      text-align: center;
      position: relative;
      & form {
        padding-top: 0;
        width: 100%;
      }
      & .ff_fileupload_wrap {
        margin-bottom: 33px;

      }
      & button {
        &.uploader-submit {
          background-color: #626ecc;
          color: #fff;
          font-size: 16px;
          font-family: GothamProBold;
          padding: 10px 27px;
          text-decoration: none;
          transition: .3s;
          text-align: center;
          border-radius: 12px;
          box-shadow: 0 4px 21px -4px rgba(0, 0, 0, .5);
          &:hover {
            cursor: pointer;
            background-color: #0dcdae;
            box-shadow: -1px 0 29px -3px rgba(0, 0, 0, .5);
          }
        }
      }
      & .file-upload__title {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 33px;
        font-size: 20px;
        color: #acacac;
        font-family: $gothampro;
        z-index: 1;
        @media screen and(max-width: 560px) {
          display: none;
        }
        &_mobile {
          display: none;
          @media screen and(max-width: 560px) {
            display: block;
          }
        }
      }
    }
  }
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
  background-image: url("/img/fancy_okay.png");
}

.worksheet-item.custom-selectpicker .bootstrap-select .dropdown-menu.inner {
  max-height: 200px;

}

//текстовый редактов
.trumbowyg-button-pane {
  background-color: #dfdfe4;
}
.select2-results__options{
  /* width */
  & ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #908c8c;
    border-radius: 10px;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: $color-light;
  }
}


.select2-container--classic .select2-results>.select2-results__options, .select2-container--default .select2-results>.select2-results__options {
  /* width */
  & ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #908c8c;
    border-radius: 10px;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: $color-light;
  }
}



.select2-container select2-container--default.select2-container--open{
  /* width */
  & ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #908c8c;
    border-radius: 10px;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: $color-light;
  }
}

.select2-dropdown select2-dropdown--below{
  /* width */
  & ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #908c8c;
    border-radius: 10px;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: $color-light;
  }
}


.select2-results{
  /* width */
  & ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #908c8c;
    border-radius: 10px;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: $color-light;
  }
}


///Перетягивание картинок (логотипа)



.worksheet-item .worksheet-item__label svg {
  fill: #b34039;
  width: 15px;
  height: 20px;
  transition: .2s;
}
.worksheet-item .worksheet-item__label svg:hover {
  fill: #626ecc;
  cursor: pointer;
}