.worksheet-item__label{
  &_hide{
    display: none;
  }
}


//ТАБЫ
.lang-switchable .lang-switches {
  cursor: pointer;
  display: flex;
}
.lang-switchable .lang-switches label {
  width: 50px;
  height: 20px;
  margin-bottom: 0;
  text-align: center;
}
.lang-switchable .lang-items {
  width: 100%;
}
.lang-switchable > .lang-items > input,
.lang-switchable > .lang-items > div{
  display: none;
}
.lang-switchable > .lang-items > input.current,
.lang-switchable > .lang-items > div.current{
  display: block;
}

.lang-switchable{
  & label{
    text-align: center;
    line-height: 31px;
    color: $color-grey;
    font-family: $gothampromedium;
    font-size: 16px;
    text-transform: uppercase;
    &.current{
      font-family: $gothamprobold;
      font-size: 16px;
      color: $color-violet;
      display: block;
    }
  }
}

.lang-items{
  margin-top: 10px;
}

.filter-checker input[type=radio], .filter-checker input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  visibility: visible;
  z-index: -1;
}


