.reviews {
  margin: 0 auto;
  margin-bottom: 94px;
  @media screen and (max-width: 991px) {
    padding-right: 25px;
    padding-left: 25px;
  }
  @media screen and (max-width: 640px) {
    padding-right: 10px;
    padding-left: 10px;
  }
  & .rating-count{
    & .star{
      fill:$color-green;
      stroke:$color-green;
      left: -111px;
      height: 20px;
      & .star--empty, .star-0{
        stroke:$color-green;
      }
    }
  }
}

.new-review {
  max-width: 785px;
  margin: 0 auto;
  margin-bottom: 70px;
  &__title {
    font-family: $helveticaneuecyrroman;
    font-size: 20px;
    color: $color-grey;
    margin-bottom: 21px;
    padding-left: 26px;
    @media screen and (max-width: 640px) {
      font-size: 11px;
      text-align: left;
      margin-bottom: 14px;
    }
  }
  & .review-form {
    position: relative;
    padding-left: 26px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      padding-left: 4px;
    }
    & textarea {
      padding: 10px;
      border-radius: 20px;
      border: 1px solid $color-light;
      padding: 18px 9px;
      color: $color-light;
      text-indent: 20px;
      display: flex;
      justify-content: center;
      //margin-bottom: 14px;
      height: 80px;
      width: 100%;
      @media screen and (max-width: 768px) {
        font-size: 13px;
        border: 1px solid #acacac;
        padding: 11px 9px;
        border-radius: 10px;
      }
      &#textareaauto{
        font-smoothing:subpixel-antialiased;
      }
    }
    & button {
      border-radius: 15px;
      padding: 10px 14px;
      font-size: 20px;
      background-color: $color-green;
      color: #fff;
      &:hover {
        background-color: $color-violet;
      }
      @media screen and (max-width: 768px) {
        font-size: 11px;
        border-radius: 7px;
        padding: 10px 14px;
      }
    }
    .alert-info{
      //position: absolute;
      padding: 4px 0px;
      border-radius: 14px;
      color: #fff;
      font-family: GothamPro;
      font-size: 12px;
      //box-shadow: 1px 3px 9px 0px rgba(172,172,172,.67);
      background-color: initial;
      &__warning{
        bottom: 0;
        right: 0;
        color: red;
        margin-left: 14px;
        display: none;
        &.active{
          display: block;
        }
      }
      &__success{
        color: $color-green;
        display: none;
        &.active{
          display: block;
        }
      }
      &__inform{
        color: $color-grey;
        display: none;
        &.active{
          display: block;
        }
      }
    }
  }
}

.your-rating {
  margin-top: 14px;
  display: flex;
  margin-bottom: 15px;
  &__title {
    font-family: $gothampro;
    color: $color-grey;
    font-size: 16px;
    margin-right: 22px;
    @media screen and (max-width: 640px) {
      font-size: 8px;
      text-align: left;
      margin-right: 15px;
    }
  }
  .stars {
    display: flex;
    width: 280px;
    justify-content: flex-start;
    @media screen and (max-width: 768px) {
      width: 170px;
    }
    &.hover {
      cursor: pointer;
      .active {
        fill: none;
        cursor: pointer;
      }
      .hover {
        fill: $color-green;
        cursor: pointer;
        stroke: $color-green;
      }
    }
    svg {
      cursor: pointer;
      @include animate(0.1);
      stroke: $color-light;
      stroke-width: 2px;
      fill: none;
      width: 22px;
      height: 20px;
      margin-right: vw(2px);
      @media screen and (max-width: 991px) {
        width: 22px;
        height: 20px;
        margin-right: 5px;
      }
      @media screen and (max-width: 768px) {
        width: 22px;
        height: 20px;
        margin-right: 2px;
      }
      @media screen and (max-width: 640px) {
        width: 16px;
        height: 17px;
      }
      &.active,
      &:hover {
        cursor: pointer;
        fill: $color-green;
        stroke: $color-green;
      }
    }
  }

  .stars.disable {
    cursor: default;
    svg {
    }
    .hover {
      fill: $color-green;
      .active {
        fill: $color-green;
        stroke: $color-green;
      }
    }
  }
  .stars.disable svg:hover {
    cursor: default;
    svg {
    }
  }
}

//блок готово отзыва
.reviews-item {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 932px;
  margin-bottom: 16px;
  &__left {
    margin-right: 31px;
    @media screen and (max-width: 768px) {
      margin-right: 7px;
    }
    & .avatar {
      border-radius: 50%;
      border: 1px solid $color-green;
      width: 71px;
      height: 71px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        width: 60px;
        height: 60px;
        & img {
          max-width: 60px;
          @media screen and (max-width: 768px) {
            max-width: 60px;
          }
        }
      }
    }
  }
  &__main {
    width: 100%;
    max-width: 760px;
    padding-top: 25px;
    color: $color-grey;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
}

.reviews-content {
  &__top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    & .review-author {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 14px;
    }
    & .name {
      font-family: $gothampromedium;
      font-size: 18px;
      text-align: left;
      margin-right: 21px;
    }
    & .rating {
      .stars {
        display: flex;
        width: 280px;
        justify-content: flex-start;
        @media screen and (max-width: 768px) {
          width: 170px;
        }
        &.hover {
          .active {
            fill: none;
          }
          .hover {
            stroke: $color-green;
            fill: $color-green;
          }
        }
        svg {
          @include animate(0.1);
          stroke: $color-light;
          stroke-width: 2px;
          fill: none;
          width: 22px;
          height: 20px;
          margin-right: 2px;
          @media screen and (max-width: 991px) {
            width: 22px;
            height: 20px;
            margin-right: 2px;
          }
          @media screen and (max-width: 768px) {
            width: 22px;
            height: 20px;
            margin-right: 2px;
          }
          @media screen and (max-width: 640px) {
            width: 18px;
            height: 20px;
          }
          &.active,
          &:hover {
            fill: $color-green;
            stroke: $color-green;
          }
        }
      }
    }
    & .review-text {
      border-radius: 20px;
      border: 1px solid $color-light;
      padding: 32px 9px;
      color: $color-light;
      text-indent: 20px;
      width: 100%;
      @media screen and (max-width: 768px) {
        font-size: 13px;
        border: 1px solid #acacac;
        padding: 11px 9px;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    & .date {
      color: $color-grey;
      font-family: $helveticaneuecyrroman;
      font-size: 11px;
      @media screen and (max-width: 768px) {
        font-size: 9px;
      }
    }
  }

}

.all-reviews {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  & button {
    text-decoration: none;
    text-align: center;
    color: $color-green;
    font-size: 14px;
    font-family: $gothampro;
    transition: .3s;
    &:hover {
      color: $color-violet;
    }
  }
}

.reviews-content__bottom .likes-bnts {
  @media screen and (max-width: 768px){
    max-width: 175px;
  }
}

.reviews-content__bottom .likes-bnts label {
  font: bold 20px Helvetica, sans-serif;
  display: block;
  float: left;
  text-align: right;
  padding: 5px 10px 0 0;
  width: 110px;
}

.reviews-content__bottom .likes-bnts input[type=text] {
  float: left;
  width: 40px;
  font: bold 20px Helvetica, sans-serif;
  padding: 3px 0 0 0;
  text-align: center;
}

.reviews-content__bottom .likes-bnts {
  overflow: hidden;
  margin: 0 0 5px 0;
}

.reviews-content__bottom .likes-bnts .button {
  margin: 0 0 0 5px;
  text-indent: -9999px;
  cursor: pointer;
  width: 29px;
  height: 29px;
  float: left;
  text-align: center;
  //background: url(../images/buttons.png) no-repeat;

}

.reviews-content__bottom .likes-bnts .dec {
  background-position: 0 -29px;
}

.rating-btn {
  width: 125px;
  height: 45px;
  &.positive {
    position: relative;
    width: 79px;
    & label {
      text-align: left;
      color: $color-green;
      font-family: $helveticaneuecyrroman;
      font-size: 10px;

    }
    & input {
      color: $color-green;
      font-family: $helveticaneuecyrroman !important;
      font-size: 10px !important;
      right: -11px;
      position: absolute !important;
      bottom: 8px;
    }
    & span {
      color: $color-green;
      font-family: $helveticaneuecyrroman;
      font-size: 10px;
      position: absolute;
      bottom: 8px;
      right: 18px;
    }
    & .inc {
      &.button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $color-green;
        position: absolute;
        top: 0;
        right: 0;
        background: url(../../../img/plusbg.png) no-repeat;
        background-position: center;
      }
    }
    @media screen and (max-width: 560px){
      width: 72px;
    }
  }
  &.negative {
    position: relative;
    & label {
      text-align: right;
      color: #d14d46;
      font-family: $helveticaneuecyrroman;
      font-size: 10px;

    }
    & input {
      color: #d14d46;
      font-family: $helveticaneuecyrroman !important;
      font-size: 10px !important;
      left: 9px;
      position: absolute !important;
      bottom: 8px;
    }
    & span {
      color: #d14d46;
      font-family: $helveticaneuecyrroman;
      font-size: 10px;
      position: absolute;
      bottom: 8px;
      left: 13px;
    }
    & .inc {
      &.button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #d14d46;
        position: absolute;
        top: 0;
        left: 11px;
        background: url(../../../img/minusbg.png) no-repeat;
        background-position: center;
      }
    }
  }
}

.reviews-content__bottom .likes-bnts form{
  display: flex;
}

.reviews-content .rating .stars svg{
  pointer-events:none;
}

.reviews-content .rating .stars svg.active{
  fill: $color-green;
  stroke: $color-green;
}

.reviews-content__bottom .likes-bnts label{
  @media screen and (max-width: 560px){
    padding: 0px 10px 0 0;
  }
}