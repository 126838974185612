/* Load basic styles/utilities */
@import "lib/normalize/normalize";
//@import "../../node_modules/bulma/bulma";
//@import "base/bulma-vars";
//@import "node_modules/bulma/sass/utilities/functions.sass";
//@import "node_modules/bulma/sass/utilities/derived-variables.sass";
//@import "node_modules/bulma/sass/utilities/animations.sass";
//@import "node_modules/bulma/sass/utilities/mixins.sass";
//@import "node_modules/bulma/sass/utilities/controls.sass";
//@import "node_modules/bulma/sass/base/_all";
//@import "node_modules/bulma/sass/elements/_all";
//@import "node_modules/bulma/sass/components/_all";
//@import "node_modules/bulma/sass/grid/_all";
//@import "node_modules/bulma/sass/layout/_all";

//Bootstrap grip
@import "lib/bootstrap/bootstrap.min.css";
@import "lib/bootstrap/bootstrap-grid.min.css";
@import "lib/bootstrap/bootstrap-select.css";

@import "lib/select2/tag-select.css";
@import "lib/fileuploader/fileuploader-source.css";

//@import "lib/datepicker/classic.css";
//@import "lib/datepicker/classic.date.css";
//@import "lib/datepicker/classic.time.css";
//@import "lib/datepicker/rtl.css";
//@import "lib/datepicker/datepicker-custom.css";

@import "lib/datepicker/zebra_datepicker-bootstrap.css";



@import "node_modules/rfs/scss/rfs";

@import "base/mixin";
/*@import "lib/grid/container";*/
@import "base/vars";
@import "base/fonts";
/*@import "base/icons";*/
@import "base/setting";
//@import "base/form";
@import "base/base";

@import "utilities/u-buttons";

//@import "modules/alerts/alerts.scss";

@import "lib/owl-carousel/owl.carousel.min.css";
@import "lib/owl-carousel/owl.theme.default.min.css";
@import "lib/datetimepicker/datetimepicker.css";



@import "layout/header/header.scss";
@import "layout/header/header-quest.scss";
@import "layout/account/account.scss";
@import "layout/search-page/search-page.scss";
@import "layout/recovery-pass/recovery-pass.scss";
@import "layout/brand/brand-page.scss";

@import "modules/home-search/home-search.scss";
@import "modules/ajax-search/ajax-search.scss";
@import "modules/breadcrumbs/breadcrumbs.scss";
@import "modules/quest-promo/quest-promo.scss";
@import "modules/quest-items/quest-item.scss";
@import "modules/rating-stars/rating-stars.scss";
@import "modules/rating-stars/rating-count.scss";
@import "modules/filter/filter.scss";
@import "modules/page404/page404.scss";
@import "modules/navigation-arrows/navigation-arrows.scss";
@import "layout/footer/footer.scss";

@import "layout/quest/quest.scss";
@import "layout/quest/quest-sertificate.scss";
@import "layout/quest/quest-genre.scss";
@import "layout/modal/modal.scss";
@import "layout/blog/blog.scss";
@import "modules/pagination/pagination.scss";
@import "modules/map/map-detail.scss";
@import "modules/partner/partner.scss";
@import "modules/lang-tabs/lang-tabs.scss";
@import "modules/scroll/scroll-custom.scss";


@import "lib/texteditor/trumbowyg.min.css";




//Account cabinet styles
//@import "layout/account/account.scss";



//Load style for other page
//@import "layout/section-faq/section-faq.scss";

//Load dtyles for common modules

//@import "modules/offer-item-carousel/offer-item-carousel.scss";


//@import "layout/l-footer";
//@import "layout/l-main";
//@import "layout/l-secondary";
//@import "layout/l-header-content";
//@import "layout/l-line-devide";
//@import "layout/**/*.scss";

//TODO объеденить папки и сделать везде мейн файл которые уже собирать тут

/* Load utilities */
/*@import "utilities/u-indents";*/

//@import "utilities/u-dropdowns";
/*@import "utilities/u-tooltips";*/
//@import "utilities/u-main";
//@import "utilities/u-text";
/*@import "utilities/u-forms";*/

//libs
//@import "lib/owl.carousel/owl.carousel";
//@import "lib/owl.carousel/owl.theme.default.min";
//@import "lib/magnific-popup/main";

//modules/pages

/*@import "modules!**!*.scss";*/

/* Load states  */
//@import "states/*.scss";



//page list
#showMenu {
  position: fixed;
  z-index: 9999;
  right: 0px;
  top: 60px;
  //display: none;
}
//
#showMenu a{
  padding: 5px 30px;
}

#showMenu{
  &:hover{
    & .dropdown-menu{
      overflow-y: scroll;
      display: block;
      top: 61%;
      left: -200px;
      height: 400px;
      & .dropdown-item{
        margin-bottom: 10px;
      }
    }
  }
}

input, button{
  outline: none !important;
}
input, button:hover{
  outline: none !important;
}
input, button:focus{
  outline: none !important;
}
input, button:active{
  outline: none !important;
}

input, button:focus {outline:0;}

button:active {
  outline: none  !important;
  border: none  !important;
}

input, button:focus {outline:0 !important;
}

*:focus {
  outline: 0 !important;
}


.modal-tabs input:-webkit-autofill,
.modal-tabs input:-webkit-autofill:hover,
.modal-tabs input:-webkit-autofill:focus,
.modal-tabs input:-webkit-autofill:active,
.modal-tabs textarea:-webkit-autofill,
.modal-tabs textarea:-webkit-autofill:hover
.modal-tabs textarea:-webkit-autofill:focus,
.modal-tabs select:-webkit-autofill,
.modal-tabs select:-webkit-autofill:hover,
.modal-tabs select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  color: #b6bbe9 !important;
  -webkit-text-fill-color: #b6bbe9 !important;
}



#search input:-webkit-autofill,
#search input:-webkit-autofill:hover,
#search input:-webkit-autofill:focus,
#search input:-webkit-autofill:active,
#search textarea:-webkit-autofill,
#search textarea:-webkit-autofill:hover
#search textarea:-webkit-autofill:focus,
#search select:-webkit-autofill,
#search select:-webkit-autofill:hover,
#search select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}


.filter-checker input[type=radio], .filter-checker input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  visibility: visible;
  z-index: -1;
  width: 2px;
  height: 1px;
}
body{
  display: block;
}

