.parnter-link {
  .partner-item {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 75px;
    & .partner-round {
      width: 225px;
      height: 225px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      transition: .2s;
      @media screen and (max-width: 768px) {
        width: 175px;
        height: 175px;
      }
      & img {
        max-width: 185px;
        height: auto;
        @media screen and (max-width: 768px) {
          width: 150px;
        }
      }
      &_dark {
        background-color: #232323;
        overflow: hidden;
      }
      &_light {
        background-color: #ffffff;
        box-shadow: 1px 3px 15px 0px #464b754a;
        overflow: hidden;
      }
    }

    .partner-info {
      font-size: 16px;
      font-family: $gothampro;
      text-decoration: none;
      color: $color-grey;
      transition: .2s;
      text-align: center;
      max-width: 200px;
    }
  }

  &:hover {
    text-decoration: none;
    & .partner-round {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
    & .partner-info {
      color: $color-violet;
      font-family: $gothampromedium;
      text-decoration: none;
    }
  }
}





