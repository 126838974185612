.quest-item-wrapper {
  //height: 540px;
  //height: 560px;
  //height: 425px;
  height: 528px;
  position: relative;
  @media screen and (max-width: 1560px) {
    height: 500px;
  }
  @media screen and (max-width: 1440px) {
    height: 445px;
  }
  @media screen and (max-width: 1366px) {
    height: 500px;
  }
  @media screen and (max-width: 1025px) {
    height: 416px;
  }
  @media screen and (max-width: 991px) {
    height: 330px;
  }
}



//.quest-promo {
//  & > .container {
//    padding-left: 0;
//    padding-right: 0;
//    @media screen and (max-width: 768px) {
//      padding-left: 10px;
//      padding-right: 10px;
//    }
//  }
//}

//Quest item
.quest-item {
  max-width: 510px;
  //height: vw(540px);
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: .2s;
  @media screen and (max-width: 991px) {
    margin: 0 auto;
    //margin-bottom: 133px;
    max-width: 287px;
    height: 100%;
  }
  @media screen and (max-width: 360px) {
    margin-bottom: 91px;
  }
  &:hover{

  }

  &__info {

  }

  &__rating {
    position: absolute;
    top: vw(31px);
    left: vw(24px);
    @media screen and (max-width: 1024px) {
      left: 21px;
      top: 10px;
    }
  }

  &__promo {
    position: absolute;
    left: 35px;
    top: 78px;

    & .quest-promo-label {
      border-radius: 10px;
      padding: 5px 11px;
      font-size: 11px;
      font-family: $helveticaneuecyrbold;
      color: #fff;
      margin-bottom: 18px;

      &_w1 {
        background-color: #d24a43;
      }

      &_w2 {
        //background-color:rgba(13, 205, 174, 0.5);
        background-color: $color-green;
      }

      &_w3 {
        //background-color:rgba(98, 110, 204, 0.5);
        background-color: $color-violet;
      }
    }

    @media screen and (max-width: 360px) {

    }
  }

  &__icons {
    position: absolute;
    top: vw(31px);
    right: vw(20px);
    @media screen and (max-width: 1024px) {
      top: 17px;
      right: 17px;
      z-index: 1;
    }
    @media screen and (max-width: 991px) {
      top: 17px;
      right: 16px;
    }
  }

  &__discount {
    position: absolute;
    left: 17px;
    top: 78px;
    color: #fff;
    font-size: 11px;
    font-family: $helveticaneuecyrmedium;
    text-align: center;
    @media screen and (max-width: 1366px) {
      left: 14px;
    }
    @media screen and (max-width: 1280px) {
      left: 14px;
      top: 65px;
    }
    @media screen and (max-width: 1024px) {
      left: 13px;
      top: 41px;
    }
    @media screen and (max-width: 991px) {
      left: 13px;
      top: 41px;
      font-size: 10px;
    }

    & .discount-percent {
      background-color: #b34039;
      border-radius: 10px;
      padding: 5px 10px;
      margin-bottom: 14px;
      @media screen and (max-width: 1024px) {
        font-size: 9px;
        margin-bottom: 9px;
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
      }

      &_green {
        //background-color: #0dcdae;
        background-color: rgba(13, 205, 174, 0.82);
        border-radius: 10px;
        padding: 5px 10px;
        margin-bottom: 14px;
        @media screen and (max-width: 991px) {
          margin-bottom: 0px;
        }
      }
    }
  }

  &__action-btn {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 35px;
    //margin: 0 auto;
    @media screen and (max-width: 991px) {
      margin-bottom: -6px;
    }
    //@media screen and (max-width: 360px) {
    //  margin-bottom: 20px;
    //}
    & span {
      color: #fff;
      border-radius: 15px;
      //background-color: #fff3;
      font-family: $gothampro;
      @include rfs(17px);
      border: 1px solid #fff;
      padding: vw(7px) vw(15px);
      text-decoration: none;
      transition: .3s;
      background-color: #39393982;
      display: block;
      min-width: 230px;
      max-width: 80%;
      margin: 0 auto;
      width: 230px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      @media screen and (max-width: 1366px) {
        font-size: 13px;
      }
      @media screen and (max-width: 1024px) {
        padding: 6px 14px;
        @include rfs(13px);
      }
      @media screen and (max-width: 991px) {
        font-size: 9px;
        padding: 6px 14px;
      }
      &:hover {
        background-color: #fff;
        border: 1px solid #fff;
        color: $color-grey;
      }
    }
  }

}

.level_brain {
  background-color: $color-green;
  width: vw(47px);
  height: vw(47px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: vw(26px);
  position: relative;
  @media screen and (max-width: 1024px) {
    //width: vw(25px);
    //height: vw(25px);
    margin-bottom: vw(15px);
  }
  @media screen and (max-width: 991px) {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
  }

  & svg {
    fill: #fff;
    width: vw(30px);
    height: vw(30px);
    @media screen and (max-width: 991px) {
      width: 19px;
      height: 19px;
    }
  }

  & .level-status {
    display: none;
    height: 52px;
    position: absolute;
    right: 51px;
    top: -71px;
    background-color: #fff;
    border-radius: 10px;
    padding: 4px 15px;
    transition: .5s;
    flex-direction: column;
    box-shadow: 0 4px 9.5px 0.5px rgba(119, 118, 118, .53);
    @media screen and (max-width: 768px) {
      right: 20px;
    }

    &__title {
      font-family: $gothampro;
      @include rfs(12px);
      margin-bottom: 5px;
    }

    &__wrapper {
      width: 137px;
      height: 7px;

      & .range {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-bottom: 0px;

        & .gradient {
          height: 7px;
          border-top-left-radius: 3.5px;
          border-bottom-left-radius: 3.5px;
          background: rgb(98, 110, 204);
          background: linear-gradient(90deg, rgba(98, 110, 204, 1) 0%, rgba(13, 205, 174, 1) 60%, rgba(255, 255, 255, 1) 100%);
        }

        & .white {
          width: auto;
          border-top-right-radius: 3.5px;
          border-bottom-rightt-radius: 3.5px;
        }
      }

      & .indicator {
        display: flex;
        font-family: $helveticaneuecyrlight;
        font-size: 11px;
        color: $color-grey;
        width: 100%;
        display: flex;
        justify-content: space-between;

        & div {
          //opacity: 0;
          //width: 10%;
          opacity: 10;
          width: 50%;
          display: flex;
          justify-content: flex-end;

          &.active {
            opacity: 10;
          }
        }
      }
    }
  }

  &:hover {
    & .level-status {
      display: block;
      top: -14px;
      z-index: 1;
    }
  }
}

.level_mask {
  background-color: $color-green;
  width: vw(47px);
  height: vw(47px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    width: 25px;
    height: 25px;
  }

  & svg {
    fill: #fff;
    width: vw(35px);
    height: vw(35px);
    @media screen and (max-width: 991px) {
      width: 19px;
      height: 19px;
    }
  }

  & .level-status {
    display: none;
    width: 144px;
    height: 52px;
    position: absolute;
    right: 51px;
    top: 68px;
    background-color: #fff;
    border-radius: 10px;
    padding: vw(10px) vw(15px);
    transition: .5s;
    font-family: $gothampro;
    @include rfs(12px);
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 9.5px 0.5px rgba(119, 118, 118, .53);
    @media screen and (max-width: 768px) {
      right: 20px;
      top: 46px;
    }
  }

  &:hover {
    & .level-status {
      display: flex;
      z-index: 1;
    }
  }
}

.quest-genre {
  color: $color-grey;
  font-family: $helveticaneuecyrbold;
  @include rfs(14px);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: vw(10px);
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
  @media screen and (max-width: 991px) {
    font-size: 9px;
  }
}

.quest-name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;
  position: relative;
  @media screen and (max-width: 991px) {
    margin-bottom: 7px;
  }

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 62px;
    font-family: $gothamprolight;
    vertical-align: middle;
    @include rfs(26px);
    width: 100%;
    color: $color-grey;
    text-decoration: none;
    text-align: center;
    @media screen and (max-width: 1366px) {
      font-size: 22px;
    }
    @media screen and (max-width: 1280px) {
      @include rfs(18px);
    }
    @media screen and (max-width: 1024px) {
      @include rfs(14px);
    }

  }

  & img {
    position: absolute;
    left: 40px;
    @media screen and (max-width: 1440px) {
      left: 32px;
      max-width: 40px;
    }
    @media screen and (max-width: 1024px) {
      left: 22px;
      max-width: 30px;
    }
  }

  & div {
    font-family: $gothamprolight;
    @include rfs(26px);
    color: $color-grey;
    border-bottom: 1px solid $color-grey;
    width: 100%;
    @media screen and (max-width: 991px) {
      border-bottom: none;
    }
  }
}

.q-top {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
  //height: 343px;
  //height: 343px;
  //height: 53%;
  height: 289px;
  //margin-bottom: 26px;
  margin-bottom: 0;
  padding-bottom: 0px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  //  @media screen and (max-width: 1440px){
  //  height: 310px;
  //}
  //  @media screen and (max-width: 1280px){
  //    height: 280px;
  //    padding-bottom: 85px;
  //  }
  //  @media screen and (max-width: 1024px) {
  //    height: 238px;
  //    margin-bottom: 0;
  //  }
  @media screen and (max-width: 991px) {
    height: 183px;
    overflow: hidden;
  }
  //  @media screen and (max-width: 360px) {
  //    background-size: cover;
  //    background-origin: content-box;
  //    padding-bottom: 0;
  //    border-radius: 15px;
  //    height: 183px;
  //  }
  @media screen and (min-width: 1921px) {
    max-height: 332px;
  }
}

.q-bottom {
  //height: 25%;
  padding-top: vw(10px);
  //position: absolute;
  width: 100%;
  //height: 144px;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //justify-content: flex-start;
  //padding-top: 36px;
  background-color: #fff;
  //bottom: -95px;
  //bottom: auto;
  //bottom: calc(-20% - 30px);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  @media screen and (max-width: 991px) {
    padding-top: 5px;
  }
  //@media screen and (max-width: 360px) {
  //  height: 62px;
  //  bottom: -90px;
  //}
  &__btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
    //height: 30px;
    color: $color-grey;
    font-family: $gothamprolight;
    @include rfs(16px);
    margin-bottom: 45px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      @include rfs(13px);
    }
    @media screen and (max-width: 768px) {
      font-size: 9px;
    }

    & .quest-capacity {
      width: 122px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-left: 1px solid $color-grey;
      border-right: 1px solid $color-grey;
      @media screen and (max-width: 768px) {
        border-left: 1px solid $color-violet;
        border-right: 1px solid $color-violet;
        & div {
          width: 50%;
          display: flex;
          justify-content: center;

          &:nth-of-type(1) {
            justify-content: center;
          }

          &:nth-of-type(2) {
            justify-content: flex-start;
          }
        }
      }

      & svg {
        fill: $color-grey;
        height: 29px;
        width: 39px;
        @media screen and (max-width: 991px) {
          height: 20px;
          width: 25px;
        }
      }
    }
  }

  &__more {
    display: none;

    & a {
      background-color: #fff;
      color: $color-violet;
      padding: vw(12px) vw(66px);
      border-radius: 15px;
      text-decoration: none !important;
      font-family: $gothampro;
      @include rfs(17px);
      //transition: .3s;
      &:hover {
        text-decoration: none !important;
        color: #fff;

      }
    }
  }
}

.quest-item {
  &.active {
    & .q-top {
      //padding-bottom: 50px;
      //@media screen and (max-width: 1440px){
      //  height: 320px;
      ////  padding-bottom: 60px;
      ////}
      //@media screen and (max-width: 1366px){
      //  height: 310px;
      //}
    }

    & .q-bottom {
      //position: absolute;
      bottom: 0px;
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      //bottom: calc(-20% - 30px);
      //padding-top: 20px;
      background-color: $color-violet;
      box-shadow: 0px 4px 9.5px 0.5px rgba(119, 118, 118, 0.53);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      padding-bottom: vw(23px);
      z-index: 2;
      position: inherit;
      bottom: 23px;
      margin-top: 7px;
      @media screen and (max-width: 991px) {
        padding-bottom: 11px;
      }
    }

    & .q-bottom__btns {
      color: #fff;
      margin-bottom: vw(25px);
      @media screen and (max-width: 1280px) {
        margin-bottom: vw(18px);
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
        margin-top: 5px;
      }

      & .quest-capacity {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;

        & svg {
          fill: #fff;
        }
      }
    }

    & .q-bottom__more {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      & a {
        max-width: 360px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        background-color: #fff;
        color: $color-violet;
        //padding: 9px 60px;
        padding: 9px 48px;
        border-radius: 15px;
        text-decoration: none !important;
        font-family: $gothampro;
        @include rfs(17px);
        transition: .3s;

        &:hover {
          text-decoration: none !important;
        }

        @media screen and (max-width: 1366px) {
          padding: 9px 48px;
        }
        @media screen and (max-width: 991px) {
          font-size: 9px;
          padding: 9px 14px;
          min-width: 195px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {

}

//.similar-quest {
//   & .quest-item {
//     height: 530px;
//   }
// }

.similar-quest .filter-result-more {
  padding-top: 0;
  @media screen and (max-width: 991px){
    padding-top: 40px;
  }
  @media screen and (max-width: 568px){
    padding-top: 0;
    margin-bottom: 0;
  }
}