.about-quest {
  position: relative;
  max-width: 1040px;
  margin: 0 auto;
  color: $color-grey;
  font-family: $gothamprolight;
  @include rfs(16px);
  line-height: 22px;
  text-align: left;

  &.seo-text {
    & .toggle-text-ellipses {
      display: none !important;
    }

    & .toggle-text {
      &::before {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: -20px;
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.7987570028011204) 0%, rgba(255, 255, 255, 0.01) 100%);
      }
    }

    &.active {
      & .toggle-text {
        &::before {
          display: none;
        }
      }
    }
  }

  & .toggle-text-content {
    background-color: #fff;
  }
}


.toggle-text {
  //max-width: 862px;
  max-width: 100%;
}

.toggle-text-content {
  span {
    display: none;
  }
}

.toggle-text-link {
  text-decoration: none;
  display: block;
  margin: 20px 0;
  font-family: $helveticaneuecyrroman;
  color: $color-grey;
  @include rfs(14px);
  transition: .3s;
  border-bottom: 1px solid $color-grey;
  padding-bottom: 0px;
  max-width: max-content;

  &:hover {
    border-bottom: 1px solid $color-green;
    text-decoration: none;
    color: $color-green;

  }
}

#about-quest {
  margin-bottom: 94px;
}


//SEO TEXT EXPAND

.readmore {
  position: relative;
}

.readmore__hide {
  position: relative;
  max-height: 230px;
  overflow: hidden;
  transition: all .5s ease-in-out;
  //transition: .3s;
  //max-width: 1040px;
  max-width: 100%;
  margin: 0 auto;
  color: #393939;
  font-family: GothamProLight;
  font-size: 1rem;
  margin-bottom: 22px;
}

.readmore__hide:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4em;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7987570028011204) 0%, rgba(255, 255, 255, 0.01) 100%);
}

.readmore__hide.active {
  //max-height: 5000px;
  max-height: initial;
  overflow: inherit;
}

.readmore__hide.active:before {
  display: none;
}

.readmore__link {
  display: block;
  margin: 20px 0;
  font-family: HelveticaneuecyrRoman;
  color: #393939;
  font-size: .875rem;
  border-bottom: 1px solid #393939;
  padding-bottom: 0;
  max-width: max-content;
  margin-bottom: 20px;
  transition: .3s;
  float: right;

  &:hover {
    color: $color-green;
    cursor: pointer;
  }
}

.readmore__link span:last-of-type {
  display: none;
}

.active + .readmore__link span:first-of-type {
  display: none;
}

.active + .readmore__link span:last-of-type {
  display: inline;
}

#about-quest {
  & .seo-text {
    max-width:1040px;
    margin: 0 auto;
    & .readmore__hide {
      max-height: 201px;
      &::before {
        display: none;
      }
      &.active {
        max-height: initial;
      }
    }
    & .readmore__link {
      float: initial;
    }
  }
}

.booking-outdoore-section {
  margin-bottom: 120px;

  & .booking-outdoore-inner {
    box-shadow: 0 10px 23.5px 3.5px rgba(0, 0, 0, .09), 0 3px 7px 0 rgba(0, 0, 0, .35);
    padding: 25px 110px;
    border-radius: 15px;
    max-width: 840px;
    margin: 0 auto;
    @media screen and (max-width: 590px) {
      padding: 20px 15px;
    }

    & .modal_booking__title {
      margin-bottom: 30px;
      text-align: center;
    }

    & .modal_booking__date {
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 310px;
      text-align: center;
    }
  }
}
