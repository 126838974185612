#premium-info {
  padding-bottom: 85px;
  @media screen and (max-width: 768px) {
    padding-bottom: 15px;
  }
}

.premium-image {
  display: flex;
  justify-content: center;
  width: 100%;
  & img {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    margin-top: 85px;
    margin-bottom: 85px;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.premium-info {
  text-align: center;
  max-width: 670px;
  margin: 0 auto;
  &__detail {
    font-size: 24px;
    font-family: $gothampro;
    color: $color-grey;
    margin-bottom: 56px;
  }
  &__text {
    & div {
      &:nth-of-type(1) {
        font-family: $gothampromedium;
        font-size: 28px;
        max-width: 450px;
        margin: 0 auto;
        margin-bottom: 30px;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      &:nth-of-type(2) {
        font-family: $gothamprolight;
        font-size: 24px;
        text-align: center;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
        & span {
          font-family: $gothampromedium;
        }
      }
    }
  }
}

.premium-description {
  padding-left: 90px;
  padding-right: 90px;
  @media screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
  }
  &__title {
    color: $color-grey;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    margin-bottom: 24px;;
    line-height: 35px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
    & span {
      text-align: center;
      font-weight: normal;
      font-family: $gothamproitalic;
    }
  }
  &__text {
    text-align: left;
    font-family: $gothamprolight;
    font-size: 18px;
  }
}

.premium-rules {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  &__title {
    font-size: 28px;
    font-family: $gothampromedium;
    margin-bottom: 25px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  &__info {
    font-family: $gothamprolight;
    font-size: 24px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
    & div {
      margin-bottom: 20px;
    }
  }
}

.about-premium {
  max-width: 560px;
}

.premium-video {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  @media screen and (max-width: 1199px) {
    margin-bottom: 40px;
  }
}

.premium-column {
  @media screen and (max-width: 1199px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}