.input-form {
  position: relative;
}

.ajax-search {
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: 0 3px 4px 1px rgba(172, 172, 172, .67);
  padding-bottom: 15px;
  border-top: 7px solid #e1e1e1;
  display: none;
  transition: 1s;
  -webkit-animation: swing-bottom-bck 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: swing-bottom-bck 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  &.active {
    display: block;
  }
  @media screen and (max-width: 768px) {
    top: 40px;
  }
  &__result {
    padding-left: 26px;
    max-height: 210px;
    overflow-y: scroll;
    line-height: 30px;
    margin-right: 11px;
    margin-top: 7px;
    @media screen and (max-width: 768px) {
      line-height: 22px;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border-radius: 2px;
    }
    & ul {

      padding-top: 17px;
      & li {
        & a {
          color: $color-grey;
          font-family: $gothampro;
          transition: .2s;
          font-size: 14px;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
          &:hover {
            border-bottom: 1px solid $color-violet;
            padding-bottom: 2px;
            text-decoration: none;
          }
          & span {
            &.word{
              color: $color-violet;
            }
            &.type{
              color: #000;
              font-size: 10px;
              font-style: italic;
            }
          }
        }
      }
    }
  }
}