.account-content{
  height: 100vh;
  background-color: #f0f2f9;

}
@import "../../layout/header/account-header";
@import "../../layout/header/offcanvas-menu";
@import "../../modules/account/account-intro";
@import "../../modules/account/account-workheet";
@import "../../modules/account/account-cabinet";
@import "../../modules/account/account-constructor";
@import "../../modules/account/account-booking";
@import "../../modules/account/account-booking";
@import "../../modules/account/account-review";
