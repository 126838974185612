.search-result {
  & .quest-item {
    margin: 0 auto;
  }
  & .main-filter__title{
    margin-bottom: 60px;
  }
  & .main-filter__total{
    text-align: center;
    color: $color-green;
    font-size: 18px;
    font-family: $gothampro;
    margin-bottom: 60px;
  }
}