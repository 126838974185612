.main-filter {
  &__title {
    @include rfs(34px);
    font-family: $gothampro;
    color: $color-grey;
    margin-bottom: 50px;
    text-align: center;
    @media screen and (max-width: 1366px){
      font-size: 27px;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 19px;
    }
    @media screen and (max-width: 768px) {
      @include rfs(18px);
    }
  }
}

@import "filter-setting.scss";
@import "filter-result.scss";

.filter-tabs {
  margin-bottom: 69px;
  @media screen and (max-width: 991px) {
    margin-bottom: 19px;
  }
  & .ftabs {
    margin: 0 auto;
    margin-bottom: 70px;
    border-radius: 27px;
    display: flex;
    justify-content: space-around;
    max-width: 391px;
    background-color: $color-violet;
    font-family: $gothampromedium;
    //box-shadow: 0px 7px 12px 0 rgba(172, 172, 172, 0.67);
    box-shadow: -4px 1px 15px 0 rgba(172,172,172,.67);
    color: #9d9db9;
    @include rfs(20px);
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }
    & .tab-filter-info {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #9d9db9;
      width: 100%;
      &.current {
        color: #fff;
      }
    }
    & .separator {
      width: 2px;
      /* height: 65px; */
      color: #9d9db9;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
}

ul.ftabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  height: 47px;
}

ul.ftabs li {
  display: inline-block;
  cursor: pointer;
  transition: .4s;
}

ul.ftabs li.current {
  border: none;
}

.filter-tab-content {
  display: none;
}

.filter-tab-content.current {
  display: inherit;
}

.return-to-match{
  text-align: right;
  & button{
    color: #0dcdae;
    font-size: 1rem;
    font-family: $gothampromedium;
    transition: .3s;
    padding-top: 4px;
    margin-bottom: 27px;
  }
}


#withsale.active{
  color: #fff;
}

.filter-tabs .ftabs #onmap.active{
  color: #fff;
}

