.pagination-pages {
  padding-left: 0;
  margin: 0 auto;
  margin-bottom: 70px;
  display: flex;
  flex-direction: row;
  &__prev {
    &.active{
      & svg{
        fill: $color-green;
      }
    }
    & svg {
      transition: .3s;
      width: 12px;
      height: 20px;
      fill: $color-light;
      transform: rotate(-180deg);
      & .active{
        fill: $color-green;
      }
      &:hover {
        cursor: pointer;
        fill: $color-green;
      }
    }
  }
  &__next {
    &.active{
      & svg{
        fill: $color-green;
      }
    }
    & svg {
      transition: .3s;
      width: 12px;
      height: 20px;
      fill: $color-light;

      &:hover {
        cursor: pointer;
        fill: $color-green;
      }
    }
  }
}

.pages-numbers {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 20px;
    font-family: $gothampro;
    color: $color-light;
    transition: .3s;
    text-decoration: none;
    &.active{
      border: 1px solid $color-green;
      color: $color-green;
      border-radius: 4px;
    }
    &:hover {
      border: 1px solid $color-green;
      color: $color-green;
      border-radius: 4px;
    }
  }
  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 20px;
    font-family: $gothampro;
    color: $color-light;
  }
}