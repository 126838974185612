.modal-auth-success{
  &.is-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__content{
    padding: 25px 45px;
    //padding: 25px 200px 30px 65px;
    border-radius: 15px;
    //background-color: $color-violet;
    background: #626ecc;
    background: linear-gradient(90deg,#626ecc 0,#7480dc 100%);
    max-width: 1200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 990px) {
      flex-direction: column;
      max-width: 800px;
      padding-left: 0;
      @media screen and (max-width: 560px) {
        padding: 25px 15px 30px 15px;
      }
    }
    & .auth-title {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 20px;
      font-family: $helveticaneuecyrroman;
      margin-bottom: 25px;
      @media screen and (max-width: 990px) {
        margin: 0 auto;
        margin-bottom: 34px;
        width: 70%;
        text-align: center;
      }
      @media screen and (max-width: 560px) {
        padding-right: 0;
        font-size: 14px;
        line-height: 22px;
      }
      @media screen and (max-width: 460px) {
        font-size: 13px;
      }
    }
    & .auth-ok{
      font-size: 18px;
      color: #fff;
      font-family: GothamProBlack;
      position: inherit;
      padding: 10px 75px;
      height: auto;
      background-image: linear-gradient(to right,#0dcdae 0,#626ecc 51%,#0dcdae 100%);
      border-radius: 15px;
      transition: 0.5s;
      background-size: 200% auto;
      &:hover{
        background-position: right center;
        cursor: pointer;
      }
    }
  }
}

.modal-auth-success {
  @media screen and (max-width: 1220px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 460px) {
    padding-right: 0px;
    padding-left: 0px;
    & .modal {
      &__content {
        margin: 5px 5px;
      }
    }
  }
}
