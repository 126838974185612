.cabinet-menu-btn {
  &.active {
    & .opennav {
      display: none;
    }

    & svg:nth-of-type(2) {
      display: block;
      @media screen and(max-width: 768px) {
        display: none;
      }
    }
  }
}

.cabinet-menu {
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -300px;
  z-index: 2;
  background: #fff;
  padding: 18px 0 15px 13px;
  text-align: center;
  @media screen and(max-width: 414px) {
    width: 280px;
  }

  & button.toggle-nav {
    position: absolute;
    top: 17px;
    right: 17px;
    display: none;
    z-index: 1;
    @media screen and(max-width: 768px) {
      display: block;
    }

    & svg {
      fill: $color-violet;
      transition: .3s;
      width: 30px;
      height: 30px;
    }
  }

  & .site-menu-header {
    position: relative;
    padding-right: 13px;

    & .logo {
      margin-bottom: 26px;
      text-align: center;
      @media screen and(max-width: 768px) {
        margin-bottom: 12px;
      }

      & img {
        max-width: 63px;
      }
    }

    & button {
      background-color: #0dcdae;
      color: #fff;
      font-size: 16px;
      font-family: GothamProBold;
      padding: 10px 23px;
      text-decoration: none;
      transition: .3s;
      text-align: center;
      border-radius: 20px;
      margin-bottom: 35px;

      &:hover {
        background-color: $color-violet;
        color: #fff;
      }

      & svg {
        transition: .2s;
        fill: #626ecc;
        width: 28px;
        height: 30px;
      }
    }

    & .mobilemenu-close {
      display: none;
      background-color: #fff;
      position: absolute;
      top: -8px;
      right: 11px;
      padding: 3px;

      & svg {
        fill: $color-violet;
        width: 28px;
        height: 30px;
      }

      @media screen and(max-width: 768px) {
        display: block;
      }
    }
  }

  & .cabinetsidemenu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    //transition: .5s;
    padding: 18px 0 0 0;
    overflow-x: hidden;
    //overflow-y: scroll;
    /* width */
    & ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    & ::-webkit-scrollbar-thumb {
      background: #908c8c;
      border-radius: 10px;
    }

    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
      background: $color-light;
    }

    & ul {
      & li {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 10px 19px;
        margin-bottom: 20px;
        @media screen and(max-width: 1366px) {
          margin-bottom: 15px;
        }
        @media screen and(max-width: 768px) {
          padding: 19px 19px;
          margin-bottom: 8px;
        }
        @media screen and(max-width: 560px) {
          padding: 7px 19px;
        }

        & span {
          margin-right: 10px;

          & svg {
            width: 25px;
            height: 25px;
            fill: #acacac;
            @media screen and(max-width: 768px) {
              width: 20px;
              height: 20px;
            }
          }
        }

        & a {
          font-size: 18px;
          color: #acacac;
          font-family: $gothampro;
          text-decoration: none;
          transition: .4s;
          width: 100%;
          @media screen and(max-width: 768px) {
            font-size: 16px;
          }

          &:hover {
            color: $color-green;
          }
        }

        &:hover {
          background: rgb(98, 110, 204);
          background: linear-gradient(90deg, rgba(98, 110, 204, 1) 0%, rgba(129, 140, 226, 1) 100%);
          border-top-left-radius: 22px;
          border-bottom-left-radius: 22px;

          & svg {
            fill: #fff;
          }

          & a {
            color: #fff;
          }
        }

        &.active {
          background: rgb(98, 110, 204);
          background: linear-gradient(90deg, rgba(98, 110, 204, 1) 0%, rgba(129, 140, 226, 1) 100%);
          border-top-left-radius: 22px;
          border-bottom-left-radius: 22px;

          & svg {
            fill: #fff;
          }

          & a {
            color: #fff;
          }
        }
      }
    }

    &.active {
      width: 300px;
      overflow-x: initial;
      padding: 18px 0 15px 13px;
      background-color: #fff;
      @media screen and(max-height: 769px) {
        overflow-y: scroll;
        & .ui-timepicker-viewport {
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background: #e1e1e1;
            border-radius: 2px;
          }

          & .ui-corner-all {
            font-family: $gothampro;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.cabinet-wrapper {
  margin-left: 0;
  background-color: #f0f2f9;
  height: 100vh;

  &.active {
    margin-left: 300px;
    height: 100vh;
    background-color: #f0f2f9;
    @media screen and(max-width: 768px) {
      margin-left: 0;
    }
  }
}

.cabinet-content-side {
  transition: margin-left 2s;
  //transform: translateX(0);
  //transform: translate3d(0,0,0);
  //transition: .3s ease all;
  //backface-visibility: hidden;

}



.menu-toggle-title {
  position: relative;
  & span {
    &:nth-of-type(2) {
      position: absolute;
      right: 0px;
      top: 10px;
      & svg {
        fill: #acacac;
        width: 15px !important;
        height: 25px !important;
      }
    }
  }

  & ul {
    display: none;
    //position: absolute;
    //top: 1px;
    //left: 1px;
    & li {
      padding: 5px 19px;
      margin-bottom: 0px;

      & a {
        font-size: 16px;
        color: $color-grey;
        transition: .3s;

        &:hover {
          color: $color-violet;
          padding-bottom: 2px;
          border-bottom: 1px solid $color-violet;
        }
      }
    }
  }

  &.open {
    display: flex;
    flex-direction: column;
    & span {
      &:nth-of-type(2) {
        position: absolute;
        right: 0px;
        bottom: 10px;
        & svg {
          fill: #fff;
          width: 15px;
          height: 25px;
        }
      }
    }

    & ul {
      &.active {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.menu-toggle-title{
  &.open{
    padding: 0 !important;
    background: #fff !important;
    color: $color-grey;
    & .menu-toggle-inner{

      width: 100%;
      background: #626ecc;
      background: linear-gradient(90deg,#626ecc 0,#818ce2 100%);
      border-top-left-radius: 22px;
      border-bottom-left-radius: 22px;
      align-items: center;
      text-align: left;
      padding: 10px 19px;
      margin-bottom: 10px;
      border-top-left-radius: 22px;
      border-bottom-left-radius: 22px;
      & svg{
        fill: #fff !important;
      }
      & a{
        color: #fff !important;
      }
    }
    & ul{
      width: 100%;
      text-align: left;
      padding-left: 59px;
      &.active{
        display: block;
        & li{
          background: #fff;
          padding-bottom: 0;
          margin-bottom: 0;
          padding-left: 0;
          & a{
            color: $color-grey !important;
            padding-bottom: 1px;
            border-bottom: 1px solid #fff;
          }
          &:hover{
            background-color: #fff !important;
            background: initial !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            padding-bottom: 0;
            margin-bottom: 0;
            padding-left: 0;
            & a{
              color: $color-violet !important;
              padding-bottom: 1px;
              border-bottom: 1px solid $color-violet;
            }
          }
          &.active{
            & a{
              color: $color-violet !important;
            }
          }
        }
      }
    }
  }
}