.header-quest {
  //max-height: 100vh;
  //margin-bottom: -23px;
  //margin-bottom: 59px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 768px;
  max-height: 1080px;
  //height: 100vh;
  margin-bottom: 40px;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
    //background-color: rgba(51, 50, 50, 0.25)
    background-color: rgba(51, 50, 50, 0.70)
  }
  @media screen and (max-width: 560px) {
    background-position: center;
    margin-bottom: 30px;
    min-height: auto;
  }
  & header {
    //height: 100%;
    color: #fff;
    align-items: flex-start;
    padding-bottom: vw(172px);
    @media screen and (max-width: 768px) {
      padding-top: 14px;
      background-image: none;
      background-position: center;
      background-size: cover;
      height: auto;

    }
    & .container {
      //max-width: 1600px;
      max-width: 1110px;
      display: flex;
    }
  }
  @import "../../modules/top-links/top-quest-links";
  & .quest-category {
    max-width: 1160px;
    margin: 0 auto;
    justify-content: space-around;
    //margin-bottom: 145px;
    margin-bottom: 22px;
    //@media screen and (max-width: 1440px){
    //  padding-left: 35px;
    //}
    @media screen and (max-width: 560px) {
      justify-content: space-between;
      margin-bottom: 19px;
    }
  }
}



.top-quest-info-container{
  z-index: 1;
  & .top-quest-info{
    &.flex-order{
      display: flex;
      flex-direction: column;
      & .order-item{
        &:nth-of-type(1){
          @media screen and (max-width: 768px){
            order: 3;
          }
        }
        &:nth-of-type(2){
          @media screen and (max-width: 768px){
            order: 2;
          }
        }
        &:nth-of-type(3){
          @media screen and (max-width: 768px){
            order: 1;
            & a{
              margin-bottom: 60px;
              display: block;
            }
          }
        }
      }
    }
  }
}

.header-quest{
  & .dropdown-select{
    & .city{
      background-color: inherit;
    }
  }
}


.header-quest .system-links .top-links {
  width: 100%;
  @media screen and (max-width: 991px){
    width: 90%;
  }
}


@media screen and (max-width: 768px){
  .header-quest .system-links .top-links .cabinet-link svg {
    display: block;
  }
}


