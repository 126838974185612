.post-content {
  max-width: 1085px;
  margin: 0 auto;
  & .post-heading {
    color: #302f30;
    font-size: 30px;
    font-family: $gothampromedium;
    margin-bottom: 30px;
  }
  & p {
    font-size: 18px;
    margin-bottom: 23px;
    font-family: $gothamprolight;
    color: #302f30;
    @media screen and (max-width: 1366px) {
      //font-size: 21px;
    }
  }
  & .post-image {
    background-position: center;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 35px;
    //& img {
    //  margin-top: 40px;
    //  margin-bottom: 70px;
    //  max-width: 1095px;
    //  height: auto;
    //  width: 100%;
    //}
  }
}

.post-banner {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 60px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;
  @media screen and (max-width: 320px) {
    display: flex;
  }
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
    background-color: rgba(51, 50, 50, .30);
  }
  &__info {
    position: absolute;
    left: 15%;
    top: 30%;
    max-width: 820px;
    @media screen and (max-width: 768px) {
      position: inherit;
      left: 0;
      max-width: 820px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @media screen and (max-width: 320px) {
      position: inherit;
      left: 0;
      max-width: 820px;
      padding-left: 10px;
      padding-right: 10px;
    }
    & .blog-date {
      color: #fff;
    }
    & .blog-rating {
      & .stars {
        &.hover {
          & svg {
            &.hover {
              stroke: #fff;
              fill: #fff;
            }
          }
        }
        & svg {
          stroke: #fff;
          fill: #fff;
          &:hover {
            stroke: #fff;
            fill: #fff;
          }

          &.active {
            stroke: #fff;
            fill: #fff;
            &.hover {
              stroke: #fff;
              fill: #fff;
            }
          }
        }
      }
    }
    & .blog-views {
      color: #fff;
      & svg {
        fill: #fff;
      }
    }
    & .blog-comment-count {
      color: #fff;
      & svg {
        fill: #fff;

      }
    }
  }
}

.post-title {
  color: #fff;
  font-size: 38px;
  font-family: $gothampromedium;
  margin-bottom: 33px;
  @media screen and (max-width: 320px) {
    font-size: 26px;
    text-align: center;
  }
}

.post-blockquote {
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 3px 9.5px 0.5px rgba(119, 118, 118, 0.34);
  padding: 23px 52px 23px 25px;
  font-size: 21px;
  font-family: $gothamprolight;
  color: #302f30;
  text-align: left;
  margin: 0 auto;
  max-width: 826px;
  margin-bottom: 69px;
  margin-top: 45px;
  overflow: hidden;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 7px;
    background-color: $color-green;
    height: 100%;
  }
  & p {
    font-size: 20px;
  }
}

.post-read-more {
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 3px 9.5px 0.5px rgba(119, 118, 118, 0.34);
  padding: 23px 52px 23px 25px;
  font-size: 21px;
  font-family: $gothamprolight;
  color: #302f30;
  text-align: left;
  margin: 0 auto;
  max-width: 826px;
  margin-bottom: 69px;
  margin-top: 45px;
  overflow: hidden;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: $color-green;
    height: 7px;
  }
  & a {
    font-family: $gothamprolight;
    font-size: 20px;
    text-align: left;
    line-height: 40px;
    transition: .3s;
    color: #302f30;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid $color-green;
    @media screen and (max-width: 1366px){
      font-size: 18px;
    }
    &:hover {
      color: $color-green;
    }
  }
  &__title {
    font-size: 32px;
    font-family: $gothampromedium;
    color: #302f30;
    margin-bottom: 19px;
    margin-left: 10px;
    @media screen and (max-width: 1366px){
      font-size: 26px;
    }
  }
}

.post-banner__info {
  & .blog-statistics {
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  & .post-title {
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
  & .blog-rating {
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      & .stars {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.new-articles {
  &__title {
    font-size: 26px;
    color: #302f30;
    font-family: $gothampromedium;
    margin-bottom: 51px;
  }
}

.new-posts {
  padding-left: 0;
}