.quest-top {
  max-width: 1140px;
  margin: 0 auto;
  margin-bottom: 34px;
}

.quest-top-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  //margin-bottom: 34px;
  margin-bottom: 0;
  @media screen and (max-width: 991px) {
    flex-direction: column;

  }

  & > div {
    &:nth-of-type(1) {
      width: 65%;
      display: flex;
      justify-content: flex-start;
      @media screen and (max-width: 991px) {
        width: 100%;
        margin-bottom: 22px;
        justify-content: center;
      }
    }

    &:nth-of-type(2) {
      width: 35%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      //align-items: flex-end;

      align-items: center;
      padding-right: 7px;
      @media screen and (max-width: 991px) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.quest-top-links {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 17px;

  & > div {
    width: 50%;
    display: flex;
    justify-content: space-around;
  }

  @media screen and (max-width: 535px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    & > div {
      width: 70%;
      display: flex;
      justify-content: space-around;
      line-height: 23px;
    }
  }

  & a {
    transition: .3s;
    color: $color-grey;
    font-family: $gothampromedium;
    font-size: 15px;
    text-decoration: none;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    &:hover {
      color: $color-green;
    }
  }
}

.quest-top-btns {
  display: flex;
  flex-direction: row;
  width: 85%;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  @media screen and (max-width: 535px) {
    width: 100%;
  }

  & a {
    font-family: $gothampromedium;
    @include rfs(15px);
    color: $color-violet;
    border: 1px solid $color-violet;
    padding: 15px 10px;
    border-radius: 20px;
    transition: .3s;
    text-decoration: none;
    margin-bottom: 12px;
    min-width: 110px;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding: 16px 17px;
    }

    &:hover {
      background-color: $color-violet;
      //border: 1px solid $color-green;
      color: #fff;
    }
  }
}

//STANDART
.quest-top-links {
  &.standart {
    @media screen and (max-width: 768px) {
      //padding-right: 30px;
      //padding-left: 30px;
      width: 100%;
      max-width: 600px;
    }
    @media screen and (max-width: 568px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    & > div {
      justify-content: space-between;
      @media screen and (max-width: 568px){
        flex-direction: column;
        align-items: center;
        & a{
          margin-bottom: 20px;
          text-align: center;
        }
      }
      //&:first-of-type {
      //  justify-content: space-between;
      //  padding-right: 50px;
      //  @media screen and (max-width: 768px) {
      //    padding-right: 0;
      //  }
      //}

      //&:last-of-type {
      //  justify-content: center;
      //  @media screen and (max-width: 768px) {
      //    justify-content: flex-end;
      //  }
      //}
    }
  }
}