.account-header {
  background: rgb(98, 110, 204);
  background: linear-gradient(90deg, rgba(98, 110, 204, 1) 0%, rgba(129, 140, 226, 1) 100%);
  height: 55px;
  padding-top: 0;
  margin-bottom: 0;
  position: relative;
  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 80%;
    margin: 0 auto;
    @media screen and(max-width: 460px) {
      width: 100%;
      justify-content: flex-end;
      padding-right: 5px;
    }
    &.cabinet-topbar {
      position: relative;

    }
  }
  & .cabinet-menu-btn {
    position: absolute;
    top: 12px;
    left: 38px;
    z-index: 1;
    outline: none;
    & svg {
      transition: .2s;
      fill: #fff;
      width: 28px;
      height: 30px;
      &:nth-of-type(2) {
        display: none;
      }
    }
    //&:hover {
    //  & svg {
    //    fill: $color-green;
    //  }
    //}
  }
}

.account-top-nav {
  display: flex;
  align-items: center;
  & a {
    color: #fff;
    font-family: $gothamprobold;
    font-size: 16px;
    transition: .3s;
    text-decoration: none;
    margin-right: 15px;
    &:hover {
      color: $color-green;
    }
  }
}

.account-top-lang {
  margin-left: 10px;
  & ul {
    display: flex;
    & li {
      padding: 2px 0;
      padding-left: 10px;
      &:not(:last-child) {
        //padding-left: 10px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          height: 100%;
          width: 2px;
          background-color: #fff;
        }
      }
      &:nth-of-type(2) {
        padding-left: 10px;
      }
      & a {
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        transition: .3s;
        font-family: $gothamprobold;
        &:hover {
          color: $color-green;
        }
        &.active {
          color: $color-green;
        }
      }
    }
  }
}

#site-wrapper {
  &.show-nav {
    & .account-header {
      & .cabinet-menu-btn {
        & svg {
          &:nth-of-type(1) {
            display: none;
          }
          &:nth-of-type(2) {
            display: block !important;
            @media screen and(max-width: 768px){
              display: none !important;
            }
          }
        }
      }
    }
  }
}