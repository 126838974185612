.modal-action {
  &__content {
    padding: 25px 45px;
    //padding: 25px 200px 30px 65px;
    border-radius: 15px;
    //background-color: $color-violet;
    background: #626ecc;
    background: linear-gradient(90deg, #626ecc 0, #7480dc 100%);
    max-width: 600px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 990px) {
      flex-direction: column;
      max-width: 800px;
      padding-left: 0;
      @media screen and (max-width: 560px) {
        padding: 25px 15px 30px 15px;
      }
    }
    & .action-title {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 20px;
      font-family: $helveticaneuecyrroman;
      margin-bottom: 25px;
      text-align: center;
      @media screen and (max-width: 990px) {
        margin: 0 auto;
        margin-bottom: 34px;
        width: 70%;
        text-align: center;
      }
      @media screen and (max-width: 560px) {
        padding-right: 0;
        font-size: 14px;
        line-height: 22px;
      }
      @media screen and (max-width: 460px) {
        font-size: 13px;
      }
    }
    & .action-ok {
      font-size: 18px;
      color: #fff;
      font-family: GothamProBlack;
      position: inherit;
      padding: 10px 75px;
      height: auto;
      background-image: linear-gradient(to right, #0dcdae 0, #626ecc 51%, #0dcdae 100%);
      border-radius: 15px;
      transition: 0.5s;
      background-size: 200% auto;
      &:hover {
        background-position: right center;
        cursor: pointer;
      }
    }
  }

}

.modal-action {
  @media screen and (max-width: 1220px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 460px) {
    padding-right: 0px;
    padding-left: 0px;
    & .modal {
      &__content {
        margin: 5px 5px;
      }
    }
  }
}

.modal-action.error {
  & .modal-action__content {
    background: #c74646;
  }
  & .action-ok {
    background-image: linear-gradient(to right, #e0dcdc 0, #626ecc 51%, #0dcdae 100%);
  }
}

#modal-action {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  overflow: hidden;
  z-index: 20;
  &.is-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#confirmation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  overflow: hidden;
  z-index: 20;
  &.is-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-action {
  &.confirmation {
    //max-width: 400px;
    & .modal-action__content {
      text-align: center;
      background: #fff;
      & .action-title {
        color: #393939;
        font-size: 2.125rem;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
        margin: 0 auto;
        display: block;
      }
      & .text {
        text-align: center;
        font-family: HelveticaneuecyrRoman;
        font-size: 1rem;
        color: #393939;
        margin-bottom: .9375vw;
      }
      & textarea {
        border: 1px solid #acacac;
        font-family: GothamPro;
        font-size: 1rem;
        color: #acacac;
        padding: 12px 19px;
        border-radius: 7px;
        margin-bottom: 1.5625vw;
        width: 100%;
        &::placeholder{
          font-family: GothamPro;
          font-size: 1rem;
          color: #acacac;
        }
      }
    }
    & .conf-btn-group {
      display: flex;
      flex-direction: column;
      & button {
        margin-bottom: 15px;
        max-width: 110px;
        text-align: center;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(to right, #e0dcdc 0, #626ecc 51%, #0dcdae 100%);
        font-size: 18px;
        color: #fff;
        font-family: GothamProBlack;
        position: inherit;
        padding: 10px 75px;
        height: auto;
        border-radius: 15px;
        transition: .5s;
        background-size: 200% auto;
        &:hover{
          background-position: right center;
          cursor: pointer;
        }
      }
    }
    & .action-ok {
      background-image: linear-gradient(to right, #e0dcdc 0, #626ecc 51%, #0dcdae 100%);
    }
  }
}