.home-search {
  //padding: vw(60px) vw(130px);
  padding: 35px 130px;
  background-image: url("/img/home-search-bg.png");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 34px;
  @media screen and (max-width: 1366px){
    padding: 10px 130px 20px;
    margin-bottom: 28px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
  & .search {
    display: flex;
    justify-content: center;
    max-width: 765px;
    margin: 0 auto;
    @media screen and (max-width: 991px){
      margin-bottom: 30px;
    }

    & .form {
      padding: 6px 0px 6px 0px;
      width: 100%;
      border-radius: 15px;
      background-color: #aab1e38c;
      display: flex;
      @media screen and (max-width: 1366px){
        padding: 0;
        height: 52px;
      }
      justify-content: center;
      & .input-form {
        padding-left: 26px;
        padding-right: 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        & input {
          width: 85%;
          font-family: $gothampro;
          font-size: 21px;
        }
        & button {
          padding: 10px 43px;
          @include rfs(21px);
          @media screen and (max-width: 1366px){
            padding: 8px 34px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.search-title {
  font-size: 26px;
  margin-bottom: 14px;
  @include rfs(34px);
  text-align: center;
  font-family: $gothampro;
  color: #fff;
  margin-bottom: 20px;
  @media screen and (max-width: 1366px){
    font-size: 26px;
    margin-bottom: 14px;
  }
  @media screen and (max-width: 768px) {
    @include rfs(18px);
    margin-bottom: 17px;
  }
  @media screen and (max-width: 568px) {
    @include rfs(18px);
    margin-bottom: 32px;
  }
}

.search-info {
  @include rfs(18px);
  text-align: center;
  font-family: $gothampro;
  color: #fff;
  margin-bottom: 25px;
  display: none;
  @media screen and (max-width: 768px) {
    @include rfs(15px);
    margin-bottom: 15px;
  }
  @media screen and (max-width: 560px) {
    @include rfs(13px);
    margin-bottom: 5px;
  }
  @media screen and (max-width: 464px){
    margin-bottom: 20px;
  }
}

.search-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
  & .input-form {
    background-color: rgba(135, 146, 212, 0.5);
    border-radius: 12px;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    padding-left: 10px !important;
    @media screen and (max-width: 768px) {
      padding: 4px;
    }
    @media screen and (max-width: 460px) {
      padding-left: 4px !important;
    }
    & button {
      font-size: 13px;
      border-radius: 12px;
      @media screen and (max-width: 768px) {
        padding: 10px 14px;
      }
    }
    & input {
      font-size: 9px;
      width: 73%;
      max-width: inherit;
      font-family: $gothampro;
      &::placeholder {
        font-size: 13px;
        @media screen and (max-width: 768px){
          font-size: 11px;
        }
        @media screen and (max-width: 424px){
          font-size: 12px;
        }
        @media screen and (max-width: 360px){
          font-size: 11px;
        }
      }
    }
  }
}


.search{
  @media screen and (max-width: 768px){
    margin-bottom: 30px;
  }
}