.breadcrumbs{
  @media screen and (max-width: 1366px){
    & .container{
      max-width: 1040px;
    }
  }
  &__items {
    font-family: $gothampro;
    font-size: 14px;
    color: $color-light;
    margin-bottom: 30px;
    margin-top: -8px;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
      margin-left: 0;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 25px;
      font-size: 14px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 25px;
      margin-left: 0;
    }
    & span {
      //display: flex;
      //align-items: center;
    }
    & a {
      text-decoration: none;
      color: $color-light;
      transition: .2s;
      &:hover {
        color: $color-violet;
      }
    }
    & .active {
      color: $color-violet;
    }
    & svg {
      fill: $color-light;
      width: 11px;
      height: 16px;
      transform: rotate(-90deg);
    }
  }
}
