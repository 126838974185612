.account-intro {
  position: absolute;
  margin-left: auto;
  margin-left: auto;
  right: 0;
  left: 0;
  top: 123px;
  max-width: 604px;
  padding: 25px 15px 35px 15px;
  margin: 0 auto;
  border-radius: 30px;
  background-image: url("/img/reg-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: initial;
  box-shadow: 0px 3px 36px -3px rgba(0,0,0,.2);
  @media screen and(max-width: 630px) {
    max-width: 500px;
    background-position: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  &__title {
    font-family: $gothampro;
    font-size: 18px;
    color: $color-grey;
    text-align: center;
    max-width: 55%;
    margin: 0 auto;
    margin-bottom: 43px;
    @media screen and(max-width: 630px) {
      width: 100%;
    }
  }
}

.account-quest-selector {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  & .account-sett-label {
    color: $color-grey;
    font-size: 14px;
  }
  & button {
    background-color: #0dcdae;
    color: #fff;
    font-size: 16px;
    font-family: GothamProBold;
    padding: 15px 27px;
    text-decoration: none;
    transition: .3s;
    text-align: center;
    border-radius: 20px;
    box-shadow: -1px 10px 29px -3px rgba(0, 0, 0, .5);
    &:hover {
      background-color: $color-violet;
      color: #fff;
      box-shadow: -1px 0px 29px -3px rgba(0, 0, 0, 0.5);
    }
  }
}

.account-quest-selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
  width: 75%;
  @media screen and (max-width: 630px) {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }
  & .filter-checker {
    margin-bottom: 15px;
  }
}