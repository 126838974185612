.cabinet-content {
  padding: 55px 36px;
  //height: 100vh;
  //min-height: 100vh;
  background-color: #f0f2f9;
  @media screen and (max-width: 768px) {
    padding: 10px 13px;
    height: 100%;
  }
  &__title {
    font-size: 24px;
    font-family: $gothampro;
    color: $color-grey;
    margin-bottom: 53px;
    @media screen and (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 30px;
      text-align: center;
    }
    @media screen and (max-width: 460px) {
      font-size: 18px;
    }
  }
  &__wrapper {
    background-color: #fff;
    border-radius: 30px;
    max-width: 1260px;
    padding: 35px 137px 70px 38px;
    @media screen and (max-width: 768px) {
      padding: 35px 38px 70px 38px;
    }
    @media screen and (max-width: 414px) {
      padding: 26px 17px 70px;
    }
    & .account-pagination {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 1280px) {
        justify-content: center;
      }
      & .pagination-pages {
        padding-left: 0;
        margin: 0;
        border: 1px solid $color-light;
        border-radius: 7px;
        font-family: $gothampro;
        color: $color-light;
        &__prev {
          padding: 8px 8px;
          text-decoration: none;
          color: $color-light;
          border-right: 1px solid $color-light;
          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
          &:hover {
            color: $color-violet;
          }
        }
        &__next {
          padding: 8px 8px;
          text-decoration: none;
          color: $color-light;
          //border-left: 1px solid $color-light;
          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
          &:hover {
            color: $color-violet;
          }
        }
        & .pages-numbers {
          display: flex;
          align-items: center;
          margin-right: 0;
          margin-left: 0;

          & a {
            border-right: 1px solid $color-light;
            height: 100%;
            padding: 0 18px;
            border-radius: 0;
            @media screen and (max-width: 768px) {
              font-size: 10px;
              padding: 0 13px;
            }
            &.active {
              border: none;
              background-color: $color-violet;
              color: #fefefe;
              box-shadow: 0 0 18px 0 rgba(98, 110, 204, .9);
              border-top: 1px solid $color-violet;
              border-bottom: 1px solid $color-violet;
              border-right: 1px solid $color-light;
            }
            &:hover {
              border: none;
              background-color: $color-violet;
              color: #fefefe;
              box-shadow: 0 0 18px 0 rgba(98, 110, 204, .9);
              border-top: 1px solid $color-violet;
              border-bottom: 1px solid $color-violet;
              border-right: 1px solid $color-light;
            }
          }
          &__item {
            padding: 8px 8px;
          }
        }
      }
    }
    &.price-wrapper {
      padding: 35px 40px 70px 38px;
      @media screen and (max-width: 460px) {
        padding: 35px 10px;
      }
    }
  }
}

.welcome-block {
  max-width: 254px;
  & p {
    font-size: 18px;
    color: $color-grey;
    text-align: left;
    font-family: $gothampro;
    &:nth-of-type(1) {
      font-size: 20px;
    }
  }
  & a {
    font-size: 18px;
    color: $color-grey;
    text-align: left;
    font-family: $gothampro;
    text-decoration: none;
    transition: 3s;
    &:hover {
      color: $color-violet;
    }
  }
}

.cabinet-table {
  @media screen and (max-width: 1280px) {
    overflow-y: scroll;
    /* width */
    & ::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    /* Handle */
    & ::-webkit-scrollbar-thumb {
      background: #908c8c;
      border-radius: 10px;
    }
    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
      background: $color-light;
    }
  }
  & table {
    margin-bottom: 77px;
    border: 1px solid #acacac;
    & tr {
      border: 1px solid #acacac;
      &.moderate {
        position: relative;
        background-color: #f0bcbe;
        & td {
          &:nth-of-type(1) {
            position: relative;
            &::after {
              position: absolute;
              right: 5px;
              width: 20px;
              height: 20px;
              content: "";
              background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iLTI5MSAzODMuMiAyNy44IDI3LjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTI5MSAzODMuMiAyNy44IDI3Ljg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM3MjFDMUM7fQ0KPC9zdHlsZT4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tMjgwLjYsNDA2aDQuNnY1aC00LjZWNDA2eiBNLTI2OS45LDM4NC44Yy0xLjItMS4xLTMuNC0xLjYtNi43LTEuNmMtMy43LDAtNi4yLDAuNS03LjUsMS41DQoJCWMtMS4zLDEtMS45LDMtMS45LDZsMCwxaDQuNmwwLTFjMC0xLjQsMC4zLTIuNCwwLjktMi45YzAuNi0wLjUsMS44LTAuNywzLjYtMC43YzEuOSwwLDMuMiwwLjIsMy43LDAuN2MwLjYsMC41LDAuOCwxLjUsMC44LDMNCgkJYzAsMS45LTAuNywzLjEtMi4yLDMuOGwtMS44LDAuOGMtMS45LDAuOC0zLDEuNS0zLjUsMi4zYy0wLjQsMC43LTAuNywyLjItMC43LDQuNWg0Ljd2LTAuNmMwLTEuNCwxLTIuNSwzLTMuNA0KCQljMi0wLjksMy4zLTEuNywzLjktMi43YzAuNi0wLjksMC45LTIuNSwwLjktNC44Qy0yNjguMSwzODcuOS0yNjguNywzODUuOS0yNjkuOSwzODQuOHoiLz4NCjwvZz4NCjwvc3ZnPg0K');
              background-repeat: no-repeat;
              @media screen and (max-width: 991px) {
                top: 15px;
                right: 3px;
              }
              @media screen and (max-width: 768px) {
                top: 4px;
                right: -1px;
                width: 13px;
                height: 12px;
              }
            }
          }
          & a{
            color: $color-light;
            text-decoration: none;
          }
        }
      }
      & td {
        border: 1px solid #acacac;
        font-family: $gothampro;
        font-size: 16px;
        color: #acacac;
        padding: 24px 28px;
        text-align: center;
        transition: .3s;
        @media screen and (max-width: 1280px) {
          font-size: 16px;
          padding: 14px 25px;
        }
        @media screen and (max-width: 768px) {
          font-size: 10px;
          padding: 7px 15px;
        }
      }
      &:nth-of-type(1) {
        & td {
          font-size: 18px;
          color: #494949;
          font-family: $gothampromedium;
          text-align: center;
          vertical-align: middle;
          padding: 10px 60px;
          @media screen and (max-width: 1280px) {
            font-size: 16px;
          }
          @media screen and (max-width: 1010px) {
            padding: 5px 20px;
          }
          @media screen and (max-width: 768px) {
            padding: 5px 13px;
            font-size: 12px;
          }
        }
      }
      &:nth-of-type(2) {
        & td {
          font-size: 18px;
          color: #494949;
          font-family: $gothampromedium;
          text-align: center;
          vertical-align: middle;
          padding: 10px 20px;
          @media screen and (max-width: 1280px) {
            font-size: 16px;
          }
          @media screen and (max-width: 768px) {
            font-size: 11px;
          }
        }
      }

      &.active {
        background-color: #f0bcbe;
      }
      &:nth-of-type(1) {
        &:hover {
          background-color: #fff;
        }
      }
      &:nth-of-type(2) {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}

.tooltip {
  & .arrow {
    display: none;
  }
}

.tooltip.show {
  opacity: 1;
  top: 30px;

}

.tooltip.fade.bs-tooltip-right.show {
  top: 80px !important;
  left: -30px !important;
}

.tooltip-inner {
  padding: 27px 15px;
  color: #393939;
  font-family: $helveticaneuecyrroman;
  font-size: 14px;
  text-align: left;
  /* background-color: #000; */
  /* border-radius: .25rem; */
  max-width: 604px;
  height: auto;
  border-radius: 30px;
  box-shadow: 0px 3px 8px 0 rgba(172, 172, 172, 0.53);
  background-color: #ffffff;
}

.account-cab-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1310px) {
    flex-direction: column;
  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: 1310px) {
      align-items: center;
      max-width: 50%;
      margin: 0 auto;
      margin-bottom: 100px;
    }
    @media screen and (max-width: 1005px) {
      width: 100%;
      max-width: 100%;
    }
    @media screen and (max-width: 460px) {
      margin-bottom: 50px;
    }
  }
}

.account-cab-panel {
  display: flex;
  align-items: center;
  margin-bottom: 112px;
  @media screen and (max-width: 1310px) {
    width: 100%;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 1005px) {
    margin-bottom: 35px;
    margin-right: 0;
  }
  @media screen and(max-width: 768px) {

  }
  & .calc-submit {
    height: 100%;
    & button {
      background-color: #626ecc;
      color: #fff;
      font-size: 16px;
      font-family: GothamProBold;
      padding: 10px 27px;
      text-decoration: none;
      transition: .3s;
      text-align: center;
      border-radius: 12px;
      box-shadow: 0 4px 21px -4px rgba(0, 0, 0, .5);
      &:hover {
        cursor: pointer;
        background-color: #0dcdae;
        box-shadow: -1px 0 29px -3px rgba(0, 0, 0, .5);
      }
    }
  }
  & .worksheet-item {
    width: 40%;
    margin-bottom: 0;
    margin-right: 30px;
    @media screen and (max-width: 1310px) {
      width: 55%;
      margin-bottom: 30px;
      flex-direction: column;
      margin-right: 0;
    }
    @media screen and (max-width: 1005px) {
      width: 60%;
      margin-right: 0;
    }
    @media screen and (max-width: 460px) {
      width: 100%;
    }
  }
  &.center {
    justify-content: center;
    @media screen and (max-width: 1310px) {
      flex-direction: column;
    }
  }
}

.calculation-table {
  @media screen and (max-width: 460px) {
    //overflow-y: scroll;
    /* width */
    & ::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    /* Handle */
    & ::-webkit-scrollbar-thumb {
      background: #908c8c;
      border-radius: 10px;
    }
    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
      background: $color-light;
    }
  }
  & table {
    border: 1px solid $color-light;
    & tr {
      border: 1px solid #acacac;
      & td {
        font-family: $gothampro;
        font-size: 16px;
        color: #acacac;
        text-align: center;
        vertical-align: middle;
        padding: 21px 20px;
        border: 1px solid #acacac;
        @media screen and (max-width: 460px) {
          padding: 15px 12px;
          font-size: 14px;
        }
        @media screen and (max-width: 425px) {
          font-size: 12px;
        }
      }
      &:nth-of-type(1) {
        & td {
          font-size: 18px;
          font-family: $gothampromedium;
          color: $color-grey;
          @media screen and (max-width: 460px) {
            font-size: 14px;
          }
          @media screen and (max-width: 425px) {
            font-size: 12px;
          }
        }
      }
      &:last-child {
        & td {
          font-size: 18px;
          font-family: $gothampromedium;
          color: $color-grey;
          @media screen and (max-width: 460px) {
            font-size: 14px;
          }
          @media screen and (max-width: 425px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.total-cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1005px) {
    flex-direction: column;
  }
  &__title {
    font-family: $gothampromedium;
    color: $color-grey;
    font-size: 17px;
    @media screen and (max-width: 1005px) {
      margin-bottom: 20px;
    }
  }
  &__price {
    border-radius: 7px;
    background-color: #f4f4f4;
    padding: 9px 60px;
    text-align: center;
    font-family: $gothampromedium;
    color: $color-grey;
    font-size: 18px;
    @media screen and (max-width: 1005px) {
      margin-bottom: 20px;
    }
  }
  &__action {
    & button {
      background-color: $color-green;
      color: #fff;
      font-size: 16px;
      font-family: GothamProBold;
      padding: 10px 37px;
      text-decoration: none;
      transition: .3s;
      text-align: center;
      border-radius: 12px;
      box-shadow: 0 4px 21px -4px rgba(0, 0, 0, .5);
      &:hover {
        cursor: pointer;
        background-color: $color-violet;
        box-shadow: -1px 0 29px -3px rgba(0, 0, 0, .5);
      }
    }
  }
}

.price-wrapper .custom-selectpicker .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  text-align: center;
}

.game-select {
  @media screen and (max-width: 1310px){
    flex-direction: column;
  }
  & .worksheet-item {
    &.custom-selectpicker {
      width: 30%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}


