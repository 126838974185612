@font-face {
  font-family: "GothamPro";
  src: url("#{$font-path}GothamPro.eot");
  src: url("#{$font-path}GothamPro.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro.woff") format("woff"),
  url("#{$font-path}GothamPro.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GothamProMedium";
  src: url("#{$font-path}GothamPro-Medium.eot");
  src: url("#{$font-path}GothamPro-Medium.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro-Medium.woff") format("woff"),
  url("#{$font-path}GothamPro-Medium.ttf") format("truetype");
  //font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "GothamProLight";
  src: url("#{$font-path}GothamPro-Light.eot");
  src: url("#{$font-path}GothamPro-Light.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro-Light.woff") format("woff"),
  url("#{$font-path}GothamPro-Light.ttf") format("truetype");
  //font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GothamProItalic";
  src: url("#{$font-path}GothamPro-Italic.eot");
  src: url("#{$font-path}GothamPro-Italic.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro-Italic.woff") format("woff"),
  url("#{$font-path}GothamPro-Italic.ttf") format("truetype");
  //font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GothamProBold";
  src: url("#{$font-path}GothamPro-Bold.eot");
  src: url("#{$font-path}GothamPro-Bold.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro-Bold.woff") format("woff"),
  url("#{$font-path}GothamPro-Bold.ttf") format("truetype");
  //font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GothamProBlack";
  src: url("#{$font-path}GothamPro-Black.eot");
  src: url("#{$font-path}GothamPro-Black.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro-Black.woff") format("woff"),
  url("#{$font-path}GothamPro-Black.ttf") format("truetype");
  //font-weight: bold;
  font-style: normal;
}



@font-face {
  font-family: "HelveticaneuecyrBold";
  src: url("#{$font-path}helveticaneuecyr-bold-webfont.eot");
  src: url("#{$font-path}helveticaneuecyr-bold-webfont.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}helveticaneuecyr-bold-webfont.woff") format("woff"),
  url("#{$font-path}helveticaneuecyr-bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaneuecyrLight";
  src: url("#{$font-path}helveticaneuecyr-light-webfont.eot");
  src: url("#{$font-path}helveticaneuecyr-light-webfont.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}helveticaneuecyr-light-webfont.woff") format("woff"),
  url("#{$font-path}helveticaneuecyr-light-webfont.ttf") format("truetype");
  //font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaneuecyrRoman";
  src: url("#{$font-path}helveticaneuecyr-roman-webfont.eot");
  src: url("#{$font-path}helveticaneuecyr-roman-webfont.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}helveticaneuecyr-roman-webfont.woff") format("woff"),
  url("#{$font-path}helveticaneuecyr-roman-webfont.ttf") format("truetype");
  //font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaneuecyrMedium";
  src: url("#{$font-path}helveticaneuecyr-medium-webfont.eot");
  src: url("#{$font-path}helveticaneuecyr-medium-webfont.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}helveticaneuecyr-medium-webfont.woff") format("woff"),
  url("#{$font-path}helveticaneuecyr-medium-webfont.ttf") format("truetype");
  //font-weight: bold;
  font-style: normal;
}