*, *:before, *:after {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

svg {
  font-style: normal;
}

body, input, button, textarea, select {
  //
  outline: none;
  box-shadow: none;
  border: 0;
  padding: 0;
  background: none;
  margin: 0;
  /* @include font-size($main-text-size);*/
  //font-family: $font-regular;
  line-height: 1.2;
  //color: $color-text-main;
}

button, a, label, select, input[type=submit] {
  cursor: pointer;
}

body {
  /*  overflow-x: hidden;*/
  //color: $color-text-main;
}

a {
  //color: $color-blue;
}

b {
  font-weight: normal;
  /* @include font(bold);*/
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*h1 {
  margin: 0;
  @include font-size(40);
  font-weight:inherit;
  @include media(max, xs) {
    @include font-size(30);
  }
}
h2 {
  @include font-size(18);
}
h3 {
  @include font-size(14);
}*/
.no-pad {
  padding: 0 0;
}

p {
  text-indent: rem(40);

}




.center {
  text-align: center;
}
.space-between{
  justify-content: space-around;
  @media screen and (max-width: 1070px){
    justify-content: space-around;
  }
}
.align-center{
  display: flex;
  align-items: center;
  &_flex-start{
    align-items: flex-start;
    @media screen  and (max-width: 722px){
      justify-content: flex-start;
    }
  }
  &_flex-end{
    align-items: flex-end;
    @media screen and(max-width: 460px){
      flex-direction: column;
      align-items: center;
    }
  }
}

.align-container{
  height: 100%;
  display: flex;
  &_evenly{
    justify-content: space-evenly;
    @media screen and (max-width: 770px){
      justify-content: flex-end;
    }
  }
  &_between{
    justify-content: space-between;
  }
  &_around{
    justify-content: space-around;
  }
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.section {
  padding-top: 3rem;
  padding-right: 0px;
  padding-bottom: 3rem;
  padding-left: 0px;
}
.columns{
  display: flex;
}
.container {
  max-width: 1600px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.container-out{
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
}
.mobile-display{
  display: none;
  @media screen  and (max-width: 770px){
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.non-padding{
  @media screen  and (max-width: 590px){
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen  and (max-width: 1060px){
  .kp-mob{
    display: block;
    margin-left: 10px;
  }
  .kp-desc{
    display: none;
  }
}
@media screen  and (max-width: 920px){
  .pc-view{
    display: none;
  }
  .mob-view{
    display: block;
  }
  .kp-mob{
    display: block;
  }
  .kp-desc{
    display: none;
  }
}
.padding{
  width: 80%;
  //padding-left: 24px;
}

.col-mobile{
  @media screen  and (max-width: 590px){
    flex-direction: column;
  }
}
.ui-selectmenu-button-text{
  display: none;
}

//@media screen  and (min-width: 1200px){
//  .container{
//    max-width: 1052px;
//    width: 1052px;
//  }
//}
//@media screen  and (min-width: 1392px){
//  .container{
//    max-width: 1244px;
//    width: 1244px;
//  }
//}
//@media screen and (min-width: 1560px) {
//  .container {
//    max-width: 1140px;
//    width: 100%;
//  }
//}



button.link {
  text-decoration: underline;
  transition: .3s;
  //color: $color-gray;
}

button.link:hover {
  //color: $color-yellow;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

@media screen and (max-width: 1440px) {

}


.dropdown-menu {
  top: 61%;
}

//.select:not(.is-multiple)::after{
//  border: 1px solid $color-lightGray;
//}

.input.is-small, .textarea.is-small {
  border-radius: 0px;
}

.file-cta {
  border: 2px solid;
}

.input.is-active, .input.is-focused, .input:active, .input:focus, .textarea.is-active, .textarea.is-focused, .textarea:active, .textarea:focus {
  //border-color: $color-yellow;
}

//.button.is-primary.is-active, .button.is-primary:active {
//  //background-color: $color-yellow;
//}
//
//.breadcrumb li {
//  //color: $color-gray;
//}
//
//.breadcrumb__menu {
//  li:nth-of-type(1) a {
//    padding-left: 0px !important;
//  }
//}
//
//.breadcrumb__menu {
//  li:last-of-type {
//    &::before {
//      margin-right: 5px;
//    }
//  }
//}

.disable-button {
  transition: .4s;
  //background-color: $color-mediumGray !important;
  //color: $color-lightGray !important;
  //cursor: not-allowed !important;
}

//Animation
//Animation
.animated {
  // IN
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}




//END animation

.first {
  @media screen and (max-width: 424px) {
    & .main-animation{
    margin-left: 5px;
  }
  }
}

.first .main-animation__boomerang {
  @media screen and (max-width: 768px) {
    padding-top: 0;
    //margin-left: 1px;
  }
}

.first {
  @media screen and (max-width: 375px) {
    & .main-animation__anima-hint2{
      top: 221px;
      right: calc(4% - 55px);
    }
    & .main-animation__info__blue_1 {
      top: 116px;
      @include rfs(12px);
    }
    & .main-animation__info__yellow_1 {
      top: 166px;
      @include rfs(12px);
    }
    & .main-animation__info__yellow_2 {
      top: 288px;
      @include rfs(12px);
    }
    & .main-animation__info__blue_2 {
      top: 376px;
      bottom: 76px;
      right: calc(27% - 76px);
      @include rfs(12px);
    }
  }
}

.position-center{
  display: flex;
  justify-content: center;
  max-width: 1600px;
}

.ui-loader.ui-corner-all.ui-loader-default.ui-body-a{
  display: none !important;
}



@media screen and (max-width: 635px) {
  .shop-item-slider .owl-item{
    display: flex;
    justify-content: center;
  }
}


.align{
  margin: 0 auto;
}

.mb0{
  margin-bottom: 0 !important;
}

//Bootstrap alert for all project

.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}


.form-control{
  @media screen and(max-width: 768px){
    font-size: 14px;
  }
}