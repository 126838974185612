ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  height: 41px;
}

ul.tabs li {
  display: inline-block;
  cursor: pointer;
  transition: .4s;
}

ul.tabs li.current {
  font-size: 14px;
  color: #fff;
  border: none;
}

.tab-content {
  display: none;
  &.current {
    display: inherit;
    min-height: 125px;
    max-width: 553px;
    @media screen and (max-width: 990px) {
      max-width: inherit;
    }
  }
}

.authenrification {
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 45px;
  //padding: 25px 200px 30px 65px;
  border-radius: 15px;
  //background-color: $color-violet;
  background: #626ecc;
  background: linear-gradient(90deg,#626ecc 0,#7480dc 100%);
  max-width: 1200px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 990px) {
    flex-direction: column;
    max-width: 800px;
    padding-left: 0;
    @media screen and (max-width: 560px) {
      padding: 25px 15px 30px 15px;
    }
  }
  & .auth-title {
    height: 100%;
    width: 35%;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-family: $helveticaneuecyrbold;
    padding-right: 45px;
    @media screen and (max-width: 990px) {
      margin: 0 auto;
      margin-bottom: 34px;
      width: 70%;
      text-align: center;
    }
    @media screen and (max-width: 560px) {
      padding-right: 0;
      font-size: 13px;
    }
    @media screen and (max-width: 460px) {
      font-size: 11px;
    }
  }
  & .modal-tabs {
    width: 65%;
    margin: 0 auto;
    @media screen and (max-width: 560px) {
      width: 100%;
    }
    & .tabs {
      display: flex;
      justify-content: space-around;
      width: 100%;
      max-width: 553px;
      margin-bottom: 20px;
      & .tab-link {
        text-transform: uppercase;
        text-align: left;
        font-family: $gothampro;
        font-size: 20px;
        color: $color-light;
        width: 50%;
        @media screen and (max-width: 990px) {
          text-align: center;
        }
        @media screen and (max-width: 460px) {
          font-size: 16px;
          &:nth-of-type(1) {
            text-align: left;
            padding-left: 12px;
          }
        }
        &.current {
          color: #fff;
        }
        &.reg-tab {
          text-align: center;
        }
      }
    }
    & .tab-content {
      & form {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 460px) {
          width: 100%;
        }
        & input {
          min-width: 230px;
          padding: 7px 0px;
          color: #b6bbe9;
          font-size: 14px;
          font-family: $gothampro;
          border-bottom: 1px solid #b6bbe9;
          margin-bottom: 15px;
          @media screen and (max-width: 460px) {
            font-size: 11px;
            color: #fff;
          }
          &::placeholder {
            color: #b6bbe9;
            font-size: 14px;
            font-family: $gothampro;
            @media screen and (max-width: 460px) {
              font-size: 11px;
            }
          }
        }
        & .tab-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 40px;
          width: 100%;
          @media screen and (max-width: 990px) {
            flex-direction: column;
            justify-content: center;
          }
          & .tab-form {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            @media screen and (max-width: 990px) {
              margin-bottom: 25px;
              width: 100%;
              align-items: center;
            }

            & a {
              transition: .3s;
              color: #b6bbe9;
              font-size: 14px;
              font-family: $gothampro;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          & .tab-buttons {
            height: 100%;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 0;
            padding-left: 7%;
            @media screen and (max-width: 990px) {
              width: 100%;
              padding-left: 0;
            }
            & button {
              background-color: #fff;
              transition: .3s;
              color: $color-violet;
              font-family: $gothamprobold;
              position: relative;
              border-radius: 20px;
              padding: 12px 55px 12px 21px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              font-size: 16px;
              @media screen and (max-width: 460px) {
                font-size: 14px;
              }
              @media screen and (max-width: 460px) {
                border-radius: 15px;
              }
              & span {
                position: absolute;
                right: 4px;
                width: 40px;
                top:1px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: #e5e5e5;
                @media screen and (max-width: 460px) {
                  width: 25px;
                  height: 25px;
                  top: 7px;
                }
                & svg {
                  fill: $color-violet;
                  width: 22px;
                  height: 35px;
                  @media screen and (max-width: 460px) {
                    height: 15px;
                  }
                }
              }
              &:hover {
                cursor: pointer;
                background-color: $color-green;
                color: #fff;
                & span {
                  animation: nudge 1s linear infinite alternate;
                  background-color: #fff;
                  & svg {
                    fill: $color-green;
                  }
                }
              }
            }
          }
        }
      }

    }
    & .tab-bottom {
      display: flex;
      justify-content: space-between;
      max-width: 553px;
      @media screen and (max-width: 990px) {
        justify-content: center;
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;
      }
      @media screen and (max-width: 990px) {
        max-width: 240px;
      }
      & a {
        background-color: initial;
        color: #fff;
        font-size: 16px;
        font-family: $gothamprobold;
        padding: 15px 27px;
        text-decoration: none;
        transition: .3s;
        text-align: center;
        border-radius: 20px;
        //box-shadow: -1px 10px 29px -3px rgba(0, 0, 0, 0.5);
        @media screen and (max-width: 990px) {
          margin-bottom: 30px;
          font-size: 14px;
        }
        @media screen and (max-width: 460px) {
          font-size: 14px;
        }
        &:hover {
          background-color: #fff;
          color: $color-violet;
          box-shadow: -1px 0px 29px -3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.modal-auth {
  @media screen and (max-width: 1220px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 460px) {
    padding-right: 0px;
    padding-left: 0px;
    & .modal {
      &__content {
        margin: 5px 5px;
      }
    }
  }
  & .modal {
    &__content {
      & .close-modal {
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: initial;
        border: 2px solid #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        z-index: 1;
        & svg {
          fill: #fff;
          width: 16px;
          max-width: 16px;
          height: 16px;
          max-height: 16px;
          @media screen and (max-width: 460px) {
            width: 13px;
            max-width: 13px;
            height: 13px;
            max-height: 13px;
          }
        }
      }
    }
  }
}

@keyframes nudge {
  0%, 100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-5px, 0);
  }

  80% {
    transform: translate(3px, 0);
  }
}

.pass-wrapper{
  position: relative;
}
.toggle-password{
  position: absolute;
  top: 7px;
  right: 1px;
  .icon-invisible{
    fill: #fff;
    width: 20px;
    height: 15px;
  }
  .icon-visibility{
    width: 20px;
    height: 15px;
    display: none;
    fill: #fff;
  }
  &.active{
    & .icon-visibility{
      display: block;
    }
    & .icon-invisible{
      display: none;
    }
  }
}

