.recovery-card{
  margin-bottom: 235px;
  & label{
    color: $color-light;
    font-size: 18px;
    font-family: $gothampro;
  }
  & input{
    font-size: 16px;
    color: $color-grey;
    font-family: $gothampro;
    border-radius: 10px;
    &::placeholder{
      font-size: 16px;
      font-family: $gothampro;
      color: $color-light;
    }
  }
}
.recovery-title {
  color: $color-grey;
  font-size: 34px;
  font-family: $gothampro;
  margin-top: 25px;
  margin-bottom: 95px;
  text-align: center;
}

.recovery-header {
  font-size: 18px;
  font-family: $gothampromedium;
  color: $color-grey;
  margin-bottom: 23px;
  background-color: #edecec;
}
.recovery-confirm-alert{
  color: $color-violet;
  font-size: 16px;
  font-family: $gothampro;
  text-align: center;
  line-height: 24px;
  max-width: 550px;
  margin: 0 auto;
}
.recovery-send{
  background-color: $color-green;
  font-size: 16px;
  font-family: $gothamprobold;
  border-radius: 10px;
  padding: 10px 30px;
  color: #fff;
  box-shadow: 1px 2px 3px 0px rgba(172,172,172,.67);
  transition: .2s;
  &:hover{
    background-color: $color-violet;
  }
}