.modal_booking {
  //padding: vw(60px) vw(300px);
  padding: 25px 215px;
  border-radius: 15px;
  background-image: url("/img/booking-modal-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  &__title {
    @include rfs(30px);
    font-family: $gothampro;
    color: $color-grey;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;
    line-height: 28px;
    @media screen and (max-width: 568px) {
      font-size: 16px;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $helveticaneuecyrroman;
    @include rfs(16px);
    color: $color-grey;
    margin-bottom: vw(15px);
    @media screen and (max-width: 568px) {
      font-size: 14px;
    }
  }
  &__form {
    max-width: 515px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-right: vw(20px);
    padding-left: vw(20px);
    & input {
      border: 1px solid $color-light;
      font-family: $gothampro;
      @include rfs(16px);
      color: $color-light;
      padding: 10px 19px;
      border-radius: 15px;
      margin-bottom: vw(25px);
      @media screen and (max-width: 568px) {
        padding: 8px 12px;
        margin-bottom: 9px;
        font-size: 13px;
      }
      &::placeholder {
        font-family: $gothampro;
        @include rfs(16px);
        color: $color-light;
        @media screen and (max-width: 568px) {
          font-size: 13px;
        }
      }
    }
    & .booking-select-toggle {
      width: 100%;
      margin: 0 auto;
      & .filter-toggle-title {
        margin-bottom: 19px;
        & svg{
          right: -4px;
        }
        @media screen and (max-width: 568px) {
          font-size: 12px;
        }
      }
      & .selected-person {
        border: none;
        margin: 0;
        padding: 0;
      }
      @media screen and (max-width: 568px) {
        width: 100%;
      }
    }
    & .booking__city {
      display: flex;
      justify-content: center;
      color: $color-light;
      font-family: $gothampro;
      @include rfs(14px);
      border-bottom: 1px solid $color-light;
      width: 100%;
      max-width: 360px;
      padding-bottom: 3px;
      margin: 0 auto;
      margin-bottom: 34px;
      @media screen and (max-width: 568px) {
        width: 100%;
        margin-bottom: 6px;
      }
    }
  }
  &__price {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-bottom: 22px;
    & .total-price-title {
      margin-bottom: 5px;
      text-align: center;
      font-family: $helveticaneuecyrroman;
      color: $color-grey;
      @include rfs(20px);
      @media screen and (max-width: 568px) {
        font-size: 13px;
      }
    }
    & .total-price-value {
      text-align: center;
      color: $color-green;
      font-family: $helveticaneuecyrbold;
      @include rfs(26px);
      & span {
        color: $color-green;
        font-family: $helveticaneuecyrbold;
      }
    }
    @media screen and (max-width: 568px) {
      margin-bottom: 6px;
    }
  }
  &__subscribe {
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 30px;
    & .filter-checker {
      & label {
        font-family: $helveticaneuecyrroman;
        @include rfs(14px);
        color: $color-grey;
        @media screen and (max-width: 568px) {
          font-size: 11px;
        }
      }
      &:nth-of-type(1) {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 568px) {
      margin-bottom: 20px;
    }
  }
  &__submit {
    background-color: $color-violet;
    font-family: $gothampro;
    @include rfs(20px);
    border-radius: 20px;
    padding: 15px 46px;
    color: #fff;
    margin: 0 auto;
    @media screen and (max-width: 568px) {
      border-radius: 15px;
      padding: 11px 52px;
    }
  }
  @media screen and (max-width: 568px) {
    padding: 15px 15px;
  }

}
