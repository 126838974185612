.modal-paid {
  //padding: vw(60px) vw(300px);
  padding: 25px 10px;
  border-radius: 15px;
  background-image: url("/img/booking-modal-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  &__title{
    color: $color-grey;
    font-size: 16px;
    text-align: center;
    font-family: $gothampromedium;
    margin-bottom: 30px;
  }
  &__form {
    width: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-right: vw(20px);
    padding-left: vw(20px);
    & input {
      text-align: center;
      border: none;
      border-bottom: 1px solid $color-light;
      font-family: $gothampro;
      @include rfs(16px);
      color: $color-light;
      padding: 10px 19px;
      border-radius: 0;
      margin-bottom: vw(5px);
      @media screen and (max-width: 568px){
        padding: 8px 12px;
        margin-bottom: 9px;
        font-size: 13px;
      }
      &::placeholder {
        font-family: $gothampro;
        @include rfs(16px);
        color: $color-light;
        @media screen and (max-width: 568px){
          font-size: 13px;
        }
      }
      &.paid-total{
        border: none;
        border-bottom: 1px solid $color-light;
        font-size: 14px;
      }
    }
    & .booking-select-toggle {
      width: 265px;
      margin: 0 auto;
      & .filter-toggle-title {
        margin-bottom: 19px;
        @media screen and (max-width: 568px){
          font-size: 12px;
        }
      }
      @media screen and (max-width: 568px){
        width: 100%;
      }
    }
    & .booking__city {
      display: flex;
      justify-content: center;
      color: $color-light;
      font-family: $gothampro;
      @include rfs(14px);
      border-bottom: 1px solid $color-light;
      width: 265px;
      padding-bottom: 3px;
      margin: 0 auto;
      margin-bottom: 34px;
      @media screen and (max-width: 568px){
        width: 100%;
        margin-bottom: 6px;
      }
    }
  }
  &__price {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-bottom: 22px;
    & span {
      &:nth-of-type(1) {
        margin-bottom: 5px;
        text-align: center;
        font-family: $helveticaneuecyrroman;
        color: $color-grey;
        @include rfs(20px);
        @media screen and (max-width: 568px){
          font-size: 13px;
        }
      }
      &:nth-of-type(2) {
        text-align: center;
        color: $color-green;
        font-family: $helveticaneuecyrbold;
        @include rfs(26px);
        & span {
          color: $color-green;
          font-family: $helveticaneuecyrbold;
        }
      }
    }
    @media screen and (max-width: 568px){
      margin-bottom: 6px;
    }
  }
  &__subscribe {
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 30px;
    & .filter-checker {
      & label {
        font-family: $helveticaneuecyrroman;
        @include rfs(14px);
        color: $color-grey;
        @media screen and (max-width: 568px){
          font-size: 11px;
        }
      }
      &:nth-of-type(1) {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 568px){
      margin-bottom: 20px;
    }
  }
  &__submit {
    background-color: $color-violet;
    font-family: $gothampro;
    @include rfs(16px);
    border-radius: 20px;
    padding: 15px 46px;
    color: #fff;
    margin: 0 auto;
    @media screen and (max-width: 568px){
      border-radius: 15px;
      padding: 11px 52px;
    }
  }
  @media screen and (max-width: 568px){
    padding: 15px 15px;
  }
}
