.top-linked {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  @media screen and (max-width: 1366px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    margin-bottom: 10px;
    position: relative;
  }
  @media screen and (max-width: 568px) {
    margin-bottom: 60px;
  }
  //@media screen and (max-width: 375px) {
  //  margin-bottom: 45px;
  //}

  & a {
    color: $color-grey;
    font-family: $gothampro;
    font-size: 16px;
    text-decoration: none;
    transition: .3s;
    @media screen and (max-width: 768px) {
      @include rfs(11px);
      margin-bottom: 6px;
      transition: .0s !important;
    }
  }

  & .logo {
    position: absolute;
    left: -68px;
    top: -12px;
    @media screen and (max-width: 1366px){
      left: -55px;
    }
    @media screen and (max-width: 1280px) {
      display: none;
    }

    & img {
      max-width: 68px;
      @media screen and (max-width: 1366px){
        max-width: 63px;
      }
    }
  }
}

.dropdown-select {
  display: flex;
  position: relative;
  width: 10%;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  & .city {
    //z-index: 4;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-item: center;
    flex-direction: column;
    @media screen and (max-width: 991px) {
      background-color: inherit;
      z-index: 1;
    }

    & .dropdown-select-current {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      @include rfs(16px);

      & svg {
        margin-left: 7px;
        fill: $color-light;
        width: 11px;
        height: 13px;
        transform: rotate(0deg);
        transition: .4s;
        @media screen and (max-width: 768px) {
          fill: #fff;
        }
      }

      & .active {
        border-bottom: 1px solid $color-light;
        margin-top: -7px;
        @media screen and (max-width: 768px) {
          color: #fff;
          @include rfs(18px);
        }
      }
    }

    & .dropdown-select-list {
      display: none;
      z-index: 1;
      position: absolute;
      border-radius: 7px;
      top: 17px;
      background-color: #fff;
      padding-bottom: 20px;
      padding-right: 15px;
      transition: .4s;
      box-shadow: 0 4px 9.5px 0.5px rgba(119, 118, 118, .53);
      padding: 10px 10px;
      padding-left: 25px;
      left: -40px;
      padding-right: 17px;
      @media screen and (max-width: 768px) {
        padding-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
        left: inherit;
      }

      & ul {
        line-height: 30px;

        & a {
          font-size: 16px;
        }
      }
    }

    &:hover {
      cursor: pointer;

      .dropdown-select-list {
        display: block;

        & a {
          transition: .3s;

          &:hover {
            color: $color-violet;
          }
        }
      }

      & svg {
        transform: rotate(90deg);
      }
    }

    @media screen and (max-width: 768px) {
      & a {
        @include rfs(11px);
      }
      & .dropdown-select-current {
        @include rfs(11px);
      }
    }
  }
}

.system-links {
  width: 84%;
  @media screen and (max-width: 768px) {
    width: max-content;
  }

  &.active {
    //width: 135px;
    min-width: 190px;
    max-width: 240px;
    height: 194px;
    background-color: #fff;
    border-radius: 7px;
    z-index: 4;
    box-shadow: 0 4px 9.5px 0.5px rgba(119, 118, 118, .53);
  }

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-left: auto;
    left: 0;
    right: -16px;
    //width: 100%;
  }

  & .top-links {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      display: none;
      &.active {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        //padding-top: 25px;
        padding-top: 10px;
        padding-left: 8px;
        padding-bottom: 10px;
        padding-right: 10px;
        line-height: 25px;
        overflow-y: scroll;
      }
    }

    & a {
      font-size: 16px;

      &:hover {
        color: $color-violet;
      }

      &.active {
        color: $color-violet;
      }
    }

    & .cabinet-link {
      color: $color-green;
      display: flex;
      align-items: center;
      font-family: $gothampromedium;
      transition: .3s;

      & svg {
        fill: $color-green;
        width: 14px;
        height: 14px;
        margin-left: 9px;
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      &:hover {
        color: $color-violet;
      }
    }

    & .cabinet-link-signin {
      position: relative;
      //width: 35px;

      & svg {
        position: absolute;
        top: -10px;
        display: block;
        width: 35px;
        height: 35px;
        transition: .2s;
        fill: $color-green;
        @media screen and (max-width: 1024px){
          width: 25px;
          height: 25px;
        }
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &:hover {
        & svg {
          fill: $color-violet;
        }

        & .signin-menu {
          display: block;
        }
      }

      &.active {
        & svg {
          fill: $color-violet;
        }
      }
    }

  }

  & .dropdown-select {
    justify-content: flex-end;

    & .dropdown-select-list {
      left: 0;
      right: -9px;
    }

    @media screen and (max-width: 768px) {
      & .city {
        & .dropdown-select-current {
          & .active {
            color: $color-violet;
          }
        }
      }
    }
  }

  & .signin-menu {
    display: none;
    position: absolute;
    top: 28px;
    //right: 96px;
    right: 170px;
    font-size: 14px;
    color: $color-grey;
    font-family: $gothampromedium;
    min-width: 146px;
    height: auto;
    background-color: #fff;
    border-radius: 7px;
    z-index: 4;
    box-shadow: 0 4px 9.5px 0.5px rgba(119, 118, 118, .53);
    padding: 12px 12px 10px 12px;
    line-height: 25px;

    &.active {
      display: flex;
      flex-direction: column;
    }

    & a {
      transition: .4s;

      &:hover {
        color: $color-green;
      }
    }
  }
}

.burger {
  display: none;
  border: 1px solid #fff;
  border-radius: 7px;
  padding: 6px 5px;
  width: 28px;
  height: 28px;
  margin-top: 4px;
  margin-right: 4px;

  &.active {
    border: 1px solid $color-violet;
    position: relative;

    & span {
      width: 100%;
      height: 2px;
      background-color: #fff;
      transition: .3;

      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        transition: .4s;
        position: absolute;
        top: 12px;
        left: 3px;
        background-color: $color-violet;
        transform: rotate(45deg);
        width: 75%;
      }

      &:nth-of-type(3) {
        transition: .4s;
        position: absolute;
        top: 12px;
        right: 4px;
        background-color: $color-violet;
        transform: rotate(-45deg);
        width: 75%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    & span {
      width: 16px;
      height: 2px;
      background-color: #fff;
    }
  }
}

.help-wrapper {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    & .mobile-home-logo {
      justify-content: center;
      margin-bottom: 20px;
      @media screen and (max-width: 414px) {
        position: absolute;
        top: 1px;
        justify-content: flex-start;
        & .logo {
          & img {
            max-width: 47px;
          }
        }
      }
      @media screen and (max-width: 360px) {
        & .logo {
          & img {
            //position: absolute;
          }
        }
      }
    }
  }
}

.top-linked .lang-select {
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    width: 25%;
    position: relative;
  }
  @media screen and (max-width: 480px) {
    width: 60%;
  }
}

.top-linked .lang-select .city .dropdown-select-list {
  left: 80px;
  @media screen and (max-width: 768px) {
    right: 0;
    position: absolute;
    left: auto;
  }
}

.system-links {
  &.active {
    //height: 270px;
    height: auto;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      .signin-menu {
        position: inherit;
        //border-top: 1px solid $color-light;
        border-radius: 0;
        left: 11px;
        box-shadow: none;
        bottom: 0;
        top: inherit;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-right: 6px;
        padding-top: 0px;
        & a {
          font-size: 15px;
        }
      }
    }
  }
}


.account-name {
   position: relative;
   font-size: 14px;
   color: $color-grey;
   font-family: $gothampromedium;
   text-overflow: ellipsis;
   white-space: nowrap;
   height: 23px;
   width: 110px;
   padding: 0;
   overflow: hidden;

   &::after {
     content: "";
     position: absolute;
     right: 0;
     top: 0;
     //height: 4em;
     background: #fff;
     background: linear-gradient(0deg, rgba(255, 255, 255, .798757) 0, rgba(255, 255, 255, .01) 100%);
   }

   @media screen and (max-width: 768px) {
     width: 100%;
     padding-right: 10px;
     border-top: 1px solid $color-light;
     height: 25px;
     //border-top: 1px solid #acacac;
     //padding-bottom: 24px !important;
   }
 }
.sales-main-menu{
  color: #b34039 !important;
  font-family: GothamProMedium !important;
  &:hover{
    color: $color-violet !important;
  }
}