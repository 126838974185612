header {
  padding-top: 27px;
  margin-bottom:24px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1366px){
    margin-bottom: 10px;
  }
  & .container {
    max-width: 1110px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 590px) {
    position: relative;
  }
  @media screen and (max-width: 768px) {
    padding-top: 14px;
    background-image: url("/img/header-mobile-bg.png");
    background-position: center;
    background-size: cover;
    min-height: 410px;
    align-items: flex-start !important;
    flex-direction: column;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 360px) {
    position: relative;
  }

  .mobile-home-logo {
    display: none;
    @media screen and (max-width: 1280px) {
      display: flex;
      justify-content: center;
    }
    & img {
      max-width: 85px;
      @media screen and (max-width: 568px) {
      }
      @media screen and (max-width: 460px) {
        max-width: 55px;
      }
    }
  }
}

.home-genres {
  & .container {
    max-width: 1110px;
  }
}
.home-genres-container{

}


@import "../../modules/quest-category/quest-category";
@import "../../modules/top-links/top-links";


.top-search-gender{
  @media screen and (max-width: 991px){
    display: flex;
    flex-direction: column;
    & .flex-order{
      &:nth-of-type(1){
        order: 2;
      }
      &:nth-of-type(2){
        order: 1;
      }
    }
  }
}



