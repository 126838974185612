.lost {
  height: 50vh;
}

.page404 {
  padding-top: 30px;
  &__title {
    margin: 0 auto;
    text-align: center;
    color: $color-violet;
    font-family: $gothampro;
    font-size: 24px;
    //margin-bottom: 70px;
  }
  &__content {
    background-image: url("../img/404bg.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: cover;
    height: 800px;
    //height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    & img {
      max-width: 100%;
      height: auto;
    }
    @media screen and (min-width: 1921px) {
      height: 1140px;
    }
    @media screen and (max-width: 1366px) {
      background-position: center;
    }
    @media screen and (max-width: 1024px) {
      background-position: bottom;
      background-size: cover;
      height: 640px;
      background-image: url("../../img/404-1024bg.png");
    }
    @media screen and (max-width: 768px) {
      background-image: url("../../img/404-768bg.png");
      background-size: cover;
      height: 500px;
      padding-top: 0;
    }
    @media screen and (max-width: 414px) {
      background-image: url("../../img/404-320bg.png");
      background-size: cover;
      height: 455px;
      padding-top: 0;
      background-position: 0px 0px;
    }
    .elements404 {
      margin-left: 295px;
      margin-top: 37px;
      @media screen and (min-width: 1921px) {
        margin-top: 37px;
        margin-left: 455px;
      }
      @media screen and (max-width: 1366px) {
        margin-left: 176px;
        margin-top: -195px;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 235px;
        margin-top: 41px;
      }
      @media screen and (max-width: 414px) {
        margin-left: 70px;
        margin-top: inherit;
      }

      & a {
        text-decoration: none;
        background-color: #fff;
        transition: .3s;
        color: $color-violet;
        font-family: $gothamprobold;
        position: relative;
        border-radius: 20px;
        padding: 12px 55px 12px 21px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 16px;
        @media screen and (max-width: 460px) {
          font-size: 14px;
        }
        @media screen and (max-width: 460px) {
          border-radius: 15px;
        }
        & span {
          position: absolute;
          right: 4px;
          width: 40px;
          top: 1px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #e5e5e5;
          @media screen and (max-width: 460px) {
            width: 25px;
            height: 25px;
            top: 7px;
          }
          & svg {
            fill: $color-violet;
            width: 22px;
            height: 35px;
            @media screen and (max-width: 460px) {
              height: 15px;
            }
          }
        }
        &:hover {
          cursor: pointer;
          background-color: $color-green;
          color: #fff;
          & span {
            animation: nudge 1s linear infinite alternate;
            background-color: #fff;
            & svg {
              fill: $color-green;
            }
          }
        }
      }
    }
  }
}