//.scrollbot-scrollbar{
//  width: 5px;
//  height: 5px;
//  background-color: #e31e24;
//
//}
//.scrollbot-scrollbar-holder{
//  width: 2px !important;
//  right: 3px !important;
//}
//.scrollbot-scrollbar{
//  //height: 17px !important;
//  right: -1px !important;
//  box-shadow: 3px 10px 12px -11px rgba(227,30,36,0.46) !important;
//}
//
//.scroll::-webkit-scrollbar {
//  width: 5px;
//}
//
//.scroll::-webkit-scrollbar-track {
//  background: #e31e24;
//}
//
//.scroll::-webkit-scrollbar-thumb {
//  background:#bababa;
//}


/*
Custom SCroll
*/
.contact-card__text::-webkit-scrollbar {
  width: 5px;
  height: 10px;

}
.contact-card__text::-webkit-scrollbar-button:start:decrement,
.contact-card__text::-webkit-scrollbar-button:end:increment  {
  display: none;
}
.contact-card__text::-webkit-scrollbar-track-piece  {
  background-color: #bababa;
}
.contact-card__text::-webkit-scrollbar-thumb:vertical {
  background-color: #626ecc;
}