.ff_fileupload_hidden {
    display: none;
}

.ff_fileupload_wrap .ff_fileupload_dropzone {
    display: block;
    width: 100%;
    height: 93px;
    box-sizing: border-box;
    border: 1px dashed #acacac;
    border-radius: 3px;
    padding: 0;
    background-color: #fff;
    /*background-image: url('fancy_upload.png');*/
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.85;
    cursor: pointer;
    outline: none;
}

.ff_fileupload_wrap .ff_fileupload_dropzone::-moz-focus-inner {
    border: 0;
}

.ff_fileupload_wrap .ff_fileupload_dropzone:hover,
.ff_fileupload_wrap .ff_fileupload_dropzone:focus,
.ff_fileupload_wrap .ff_fileupload_dropzone:active {
    opacity: 1;
    background-color: #FDFDFD;
    border-color: #157EFB;
}

.ff_fileupload_wrap table.ff_fileupload_uploads {
    width: 100%;
    border-collapse: collapse !important;
    border: 0 none;
}

.ff_fileupload_wrap table.ff_fileupload_uploads tr,
.ff_fileupload_wrap table.ff_fileupload_uploads td {
    margin: 0;
    border: 0 none;
    padding: 0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td {
    vertical-align: top;
    padding: 1em 0;
    white-space: nowrap;
    line-height: normal;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview {
    width: 1px;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image {
    display: block;
    box-sizing: border-box;
    border: 0 none;
    padding: 0;
    background-color: #DDDDDD;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    opacity: 0.75;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #222222;
    overflow: hidden;
    outline: none;
    cursor: default;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image::-moz-focus-inner {
    border: 0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image_has_preview {
    cursor: pointer;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image:hover,
.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image:focus,
.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image:active {
    opacity: 1;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text {
    display: block;
    margin: 0 auto;
    width: 70%;
    overflow: hidden;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button {
    display: inline-block;
    vertical-align: top;
    width: 26px;
    height: 26px;
    box-sizing: border-box;
    border: 1px solid #A2B4CA;
    border-radius: 3px;
    padding: 0;
    background-color: #FCFCFC;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.85;
    cursor: pointer;
    outline: none;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button::-moz-focus-inner {
    border: 0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button:hover,
.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button:focus,
.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button:active {
    opacity: 1;
    background-color: #FDFDFD;
    border-color: #157EFB;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
    margin-right: 0.5em;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_actions_mobile {
    display: none;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_actions_mobile button {
    display: block;
    margin-top: 0.3em;
    width: 100%;
    height: 28px;
    box-sizing: border-box;
    border: 1px solid #A2B4CA;
    border-radius: 3px;
    padding: 0;
    background-color: #FCFCFC;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.85;
    cursor: pointer;
    outline: none;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_actions_mobile button::-moz-focus-inner {
    border: 0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_actions_mobile button:hover,
.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_actions_mobile button:focus,
.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_actions_mobile button:active {
    opacity: 1;
    background-color: #FDFDFD;
    border-color: #157EFB;
}

.ff_fileupload_wrap table.ff_fileupload_uploads button.ff_fileupload_start_upload {
    background-image: url('/img/fancy_okay.png');
}

.ff_fileupload_wrap table.ff_fileupload_uploads button.ff_fileupload_remove_file {
    background-image: url('/img/fancy_remove.png');
}


/* Colored buttons based on file extension for non-images. */

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_with_color {
    color: #FFFFFF;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_a {
    background-color: #F03C3C;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_b {
    background-color: #F05A3C;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_c {
    background-color: #F0783C;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_d {
    background-color: #F0963C;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_e {
    background-color: #E0862B;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_f {
    background-color: #DCA12B;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_g {
    background-color: #C7AB1E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_h {
    background-color: #C7C71E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_i {
    background-color: #ABC71E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_j {
    background-color: #8FC71E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_k {
    background-color: #72C71E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_l {
    background-color: #56C71E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_m {
    background-color: #3AC71E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_n {
    background-color: #1EC71E;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_o {
    background-color: #1EC73A;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_p {
    background-color: #1EC756;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_q {
    background-color: #1EC78F;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_r {
    background-color: #1EC7AB;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_s {
    background-color: #1EC7C7;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_t {
    background-color: #1EABC7;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_u {
    background-color: #1E8FC7;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_v {
    background-color: #1E72C7;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_w {
    background-color: #3C78F0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_x {
    background-color: #3C5AF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_y {
    background-color: #3C3CF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_z {
    background-color: #5A3CF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_0 {
    background-color: #783CF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_1 {
    background-color: #963CF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_2 {
    background-color: #B43CF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_3 {
    background-color: #D23CF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_4 {
    background-color: #F03CF0;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_5 {
    background-color: #F03CD2;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_6 {
    background-color: #F03CB4;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_7 {
    background-color: #F03C96;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_8 {
    background-color: #F03C78;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_text_9 {
    background-color: #F03C5A;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
    padding: 1em;
    font-size: 0.9em;
    white-space: normal;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename {
    width: 100%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input {
    box-sizing: border-box;
    width: 100%;
    padding: 0.3em;
    margin-bottom: 0.1em;
    font-size: 1.0em;
    font-weight: normal;
    line-height: normal;
    border: 1px solid #BBBBBB;
    border-radius: 0;
    box-shadow: none;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input:focus,
.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input:hover {
    border: 1px solid #888888;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_errors {
    color: #A94442;
    font-weight: bold;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_progress_background {
    margin-top: 0.5em;
    background-color: #CCCCCC;
    height: 2px;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_progress_bar {
    background-color: #157EFB;
    width: 0;
    height: 2px;
}

.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions {
    width: 1px;
    text-align: right;
}

@media (max-width: 420px) {
    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image {
        width: 36px;
        height: 36px;
        font-size: 11px;
    }
    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
        padding-right: 0;
    }
    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions {
        display: none;
    }
    .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_actions_mobile {
        display: block;
    }
}

.ff_fileupload_dialog_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 10000;
}

.ff_fileupload_dialog_main {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    text-align: center;
}

.ff_fileupload_dialog_main img {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
}

.ff_fileupload_dialog_main audio {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    width: 100%;
}

.ff_fileupload_dialog_main video {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    max-width: 100%;
    max-height: 100%;
}