.booking {
  margin-bottom: 90px;
  @media screen and (max-width: 560px) {
    margin-bottom: 30px;
  }

  &__title {
    text-align: center;
    color: $color-grey;
    @include rfs(38px);
    font-family: $gothampro;
    margin-bottom: 22px;
  }

}

.booking-person-count {
  //max-width: 615px;
  margin: 0 auto;
  margin-bottom: 46px;
}

.booking-person-icons {
  position: relative;
  height: 32px;
  width: 615px;
  margin: 0 auto;
  margin-bottom: 32px;
  @media screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 615px) {
    width: 290px;
  }
  //& svg{
  //  fill: $color-light;
  //  width: 29px;
  //  height: 30px;
  //}
  & .disable {
    position: absolute;
    top: 0;
    left: 0;

    & .figure-grey-mobile {
      display: none;
    }

    @media screen and (max-width: 615px) {
      position: inherit;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
      & .figure-grey {
        display: none;
      }
      & .figure-grey-mobile {
        display: block;
      }
    }
  }

  & #active-figure {
    position: absolute;
    top: 0;
    width: 0%;
    overflow: hidden;

    & .figure-active-mobile {
      display: none;
    }

    @media screen and (max-width: 615px) {
      //position: inherit;
      //margin-left: auto;
      //margin-right: auto;
      //left: 0;
      //right: 0;
      //display: flex;
      //justify-content: center;
      & .figure-active {
        display: none;
      }
      & .figure-active-mobile {
        display: block;
      }
    }

    &.per5 {
      width: 5%;
    }

    &.per10 {
      width: 10%;
    }

    &.per15 {
      width: 15%;
    }

    &.per20 {
      width: 20%;
    }

    &.per25 {
      width: 25%;
    }

    &.per30 {
      width: 30%;
    }

    &.per35 {
      width: 35%;
    }

    &.per40 {
      width: 40%;
    }

    &.per45 {
      width: 45%;
    }

    &.per50 {
      width: 50%;
    }

    &.per55 {
      width: 55%;
    }

    &.per60 {
      width: 60%;
    }

    &.per65 {
      width: 65%;
    }

    &.per70 {
      width: 70%;
    }

    &.per75 {
      width: 75%;
    }

    &.per80 {
      width: 80%;
    }

    &.per85 {
      width: 85%;
    }

    &.per90 {
      width: 90%;
    }

    &.per95 {
      width: 95%;
    }

    &.per100 {
      width: 100%;
    }
  }
}

.booking-select-toggle {
  max-width: 360px;
  margin: 0 auto;
  height: auto;
  //overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;

  & .filter-toggle__section {
    & input {
      text-align: center;
      width: 100%;
    }

    &.active {
      //margin-bottom: 16px;
    }

  }

  & .filter-wrapper {
    padding-bottom: 20px;
  }
}

.booking-table {
  overflow: hidden;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px 0 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.booking-timeline {
  display: flex;
  justify-content: space-around;
  display: none;
  @media screen and (max-width: 971px) {
    display: none;
  }
}

.booking-row {
  display: flex;
  //align-items: center;
  justify-content: flex-start;
  margin-bottom: 17px;
  align-items: flex-end;
  width: auto;
  @media screen and (max-width: 1280px) {
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;

  }
  @media screen and (max-width: 971px) {
    padding-bottom: 21px;
  }
}

.booking-day {
  color: $color-grey;
  font-size: 16px;
  font-family: $helveticaneuecyrmedium;
  min-width: 165px;
  text-align: left;
  padding-top: 0;
  margin-bottom: 5px;
  @media screen and (max-width: 1280px) {
    margin-bottom: 23px;
    text-align: center;
    width: 100%;
  }

  & span {
    &:nth-of-type(2) {
      margin-left: 4px;
    }
  }
}

.booking-warning {
  font-size: 12px;
  font-family: $gothamprolight;
  color: $color-light;
  text-align: center;
}

.booking-select {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 1280px) {
    //display: flex;
    //flex-wrap: wrap;
    //max-width: 70%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    padding-left: 35px;
  }
  @media screen and (max-width: 640px) {
    //display: flex;
    //flex-direction: row;
    //flex-wrap: wrap;
    //justify-content: center;
    //align-items: center;
    //align-content: stretch;
    //max-width: 300px;
    max-width: 80%;
  }
  @media screen and (max-width: 565px) {
    max-width: 100%;

  }
  @media screen and (max-width: 461px) {
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: 0;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.day-time {
  padding: 10px 13px;
  //max-width: 77px;
  color: $color-grey;
  font-size: 16px;
  font-family: $helveticaneuecyrmedium;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 9px;
  width: 93px;
  display: flex;
  @media screen and (max-width: 1280px) {
    width: auto;
  }
  @media screen and (max-width: 971px) {
    font-size: 12px;
  }
}

.booking-price {
  @include rfs(14px);
  font-family: $helveticaneuecyrmedium;
  border: 1px solid $color-green;
  color: $color-green;
  border-radius: 17px;
  padding: 10px 13px;
  transition: .2s;
  @media screen and (max-width: 971px) {
    font-size: 10px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $color-violet;
    color: $color-violet;
  }

  &.disable {
    border: 1px solid $color-light;
    color: $color-light;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.sale {
    border: none;
    padding: 9px !important;
    color: #fff;
    background-color: rgba(210, 74, 67, 0.86);
    //border: 1px solid #0dcdae38;
    &:hover {
      cursor: pointer;
      background-color: rgb(179, 40, 33);
    }
  }
}

.booking-item {
  margin: 0 6px;
  @media screen and (max-width: 971px) {
    margin-left: 5px;
    margin-right: 5px;
  }

  & .day-time {
    @media screen and (max-width: 971px) {
      //display: none;
      padding: 8px 9px;
    }
  }

  & .booking-price {
    padding: 8px 9px;
    width: auto;
    @media screen and (max-width: 1280px) {
      margin: 8px 9px;
    }
  }
}

.grey-line {
  @media screen and (max-width: 1280px) {
    display: block;
    margin: 0 auto;
    margin-top: 21px;
    width: 150px;
    max-width: 150px;
    height: 1px;
    background-color: $color-light;
  }
}

.sale-booking {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 151px;
  //position: absolute;
  //margin-left: auto;
  //margin-right: auto;
  //left: 0;
  //right: 0;
  //@media screen and (max-width: 870px) {
  //  display: flex;
  //  flex-direction: column;
  //  align-items: center;
  //}
  & .sale-now {
    padding: 8px 16px;
    border-radius: 14px;
    //background-color: #d24a43;
    background-color: rgba(210, 74, 67, 0.86);
    color: #fff;
    font-family: $gothampro;
    font-size: 12px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;

    &_promo {
      background-color: rgba(13, 205, 174, 0.25);
      color: $color-green;
      display: none;
    }
  }
}

.days-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 461px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
  }
}

//n1111

.wrap1 {
  display: flex;
  align-items: flex-start;
}

.wpar2 {
  max-width: 1060px;
  overflow: hidden;
  margin: 0 auto;

  .booking-row {
    & .booking-day {
      display: none;
    }
  }
}

.owl-days {
  margin-top: 42px;
}


.week-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}


.booking-select-toggle .filter-toggle__section.active .filter-content {
  height: auto !important;
}

.booking-select-toggle .filter-checker label:after {
  top: 8px;
}

.booking-person-count .filter-toggle__section.active .filter-content {
  overflow: hidden;
  position: absolute;
  min-height: 110px;
  z-index: 2;
}


.modal_booking .booking-select-toggle .filter-toggle__section.active .filter-content {
  height: auto !important;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1;
}

.modal_booking .booking-select-toggle .filter-checker label:after {
  top: 8px;
}

.modal_booking .booking-person-count .filter-toggle__section.active .filter-content {
  overflow: hidden;
  position: absolute;
  min-height: 110px;
}


//#standart-booking{
//
//}

.filter-toggle {
  &:hover {
    cursor: pointer !important;
  }
}


.booking-table{
  & .owl-item{
    display: flex;
  }
  & .owl-nav{
    margin: 0 auto;
    text-align: center;
    max-width: 240px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    & .owl-prev{
      & span{
        height: 25px;
        width: 25px;
        color: #0dcdae;
        font-size: 75px;
        font-family: cursive;
      }
      &.disabled{
        & span{
          color: $color-light;
        }
      }
    }
    & .owl-next{
      & span{
        height: 25px;
        width: 25px;
        color: #0dcdae;
        font-size: 75px;
        font-family: cursive;
      }
      &.disabled{
        & span{
          color: $color-light;
        }
      }
    }
  }
}

