@mixin media($minmax, $canvas) {
  $temp: "";
  @if ($minmax==max) {
    /*@if $canvas == xs {
      $temp: $screen-xs-max
    } @else if $canvas == sm {
      $temp: $screen-sm-max
    } @else if $canvas == md {
      $temp: $screen-md-max
    } @else if $canvas == lg {
      $temp: $screen-lg-max
    } @else {
      $temp: #{$canvas}px
    }
    @media (max-width: $temp) {
      @content;
    }*/
  } @else if ($minmax == min) {
    @if $canvas == sm {
      $temp: $tablet
    } @else if $canvas == md {
      $temp: $desktop
    } @else if $canvas == lg {
      $temp: $widescreen
    } @else if $canvas == xl {
      $temp: $fullhd
    } @else if $canvas == xxl {
      $temp: $fullhdscreen
    } @else {
      $temp: n#{$canvas}px
    }
    @media (min-width: $temp) {
      @content;
    }
  }
}

@mixin animate($s, $delay:false, $params:null) {
  $delays: "";
  $temp: "";
  $transition: "";
  @if $delay {
    $delays: $delay+s;
  }
  @if $params==null {
    transition: all #{$s}s ease-in-out #{$delays};
  } @else {
    @for $i from 1 through length($params) {
      $temp: (nth($params, $i) #{$s}s ease-in-out #{$delays});
      @if ($transition != "") {
        $transition: $transition, $temp;
      } @else {
        $transition: $temp;
      }
      transition: $transition;
    }
  }
}