.navigation-arrows {
  margin: 0 auto;
  max-width: 240px;
  display: flex;
  justify-content: space-between;
  &__item {
    & button {
      & svg {
        fill: $color-light;
        width: 60px;
        height: 35px;
        transition: .2s;
        &:hover {
          fill: $color-light;
        }
      }

      &.active{
        & svg{
          fill: $color-green;
          &:hover {
            fill: $color-violet;
          }
        }
      }
    }
  }
}