.table-editable1 {
  border: 1px solid #acacac;
  padding: 0;
  width: 515px;
  margin-bottom: 103px;
  & thead {
    border-bottom: 1px solid #acacac;
    font-family: $gothampro;
    & tr {
      & th {
        font-family: GothamPro;
        font-size: 18px;
        padding: 10px 1px;
        font-weight: normal;
        color: #494949;
        font-family: $gothampromedium;
        text-align: center;

        &:nth-of-type(2) {
          border-right: 1px solid #acacac;
          width: 30%;
        }
        &:nth-of-type(3) {
          width: 70%;
        }
        &:last-child {
          border-right: 1px solid #acacac;
        }
      }
    }
  }
  & tbody {
    & tr {
      position: relative;
      height: 55px;
      border: 1px solid #acacac;
      padding: 15px 10px;
      & td {
        &.input {
          text-align: center;
          & input {
            text-align: center;
            font-size: 18px;
            font-family: $gothampromedium;
            color: $color-grey;
            font-weight: normal;
            &::placeholder {
              font-size: 18px;
              font-family: $gothampromedium;
              color: $color-light;
              font-weight: normal;
            }
          }
        }
        &:nth-of-type(2) {
          border-right: 1px solid #acacac;
        }
        &:nth-of-type(3) {
          border-right: 1px solid #acacac;
          &.input {
            border-right: 1px solid #acacac;
            & input {
              text-align: center;
              font-size: 18px;
              font-family: $gothampromedium;
              color: $color-light;
            }
          }
        }
      }
      & .btn-delete-row {
        position: absolute;
        top: 15px;
        left: 1px;
        & svg {
          width: 20px;
          height: 20px;
          fill: $color-grey;
          transition: .3s;
          &:hover {
            fill: $color-violet;
          }
        }
        &:disabled {
          opacity: .2;
          cursor: default;
        }
      }
    }
  }
  & tfoot {
    background-color: $color-green;
    & .table-submit {
      & button {
        height: 45px;
        text-align: left;
        width: 100%;

        font-family: $gothampromedium;
        color: #fff;
        font-size: 18px;
        padding-left: 60px;
        transition: .3s;
      }
    }
    &:hover {
      background-color: $color-violet;
    }
  }

  & .table-zapper {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .table-controls {
    position: relative;
    padding-top: 15px;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
  }
  &.table-editable3 {
    width: 515px;
    & thead {
      & tr {
        width: 100%;
        & th {
          &:nth-of-type(1) {
            width: 30%;
          }
          &:nth-of-type(2) {
            width: 70%;
          }
        }
      }
    }
    & tbody {

    }
  }
}

.table-editable2 {
  max-width: 1185px;
  //margin-bottom: 103px;
  border: 1px solid #acacac;
  padding: 0;
  & thead {
    border-bottom: 1px solid #acacac;
    font-family: $gothampro;
    & tr {
      & th {
        width: 265px;
        font-family: GothamPro;
        font-size: 18px;
        padding: 10px 1px;
        font-weight: normal;
        color: #494949;
        font-family: $gothampromedium;
        text-align: center !important;
        border-right: 1px solid #acacac;
        &:nth-of-type(1) {
          border-right: 1px solid #acacac;
          border-bottom: 1px solid #acacac;
          width: 135px;
          //width: 30%;
          @media screen and(max-width: 768px) {
            font-size: 14px;
          }
        }
        &:nth-of-type(2) {
          //width: 70%;
          border-bottom: 1px solid #acacac;
          @media screen and(max-width: 768px) {
            font-size: 14px;
          }
        }
        &:last-child {
          border-right: 1px solid #acacac;
          //text-align: left !important;
          padding-left: 20px;
          @media screen and(max-width: 768px) {
            font-size: 14px;
          }
        }
        @media screen and(max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  & tbody {
    & tr {
      position: relative;
      height: 55px;
      border: 1px solid #acacac;
      padding: 15px 10px;
      & td {
        &.input {
          text-align: center;
          font-size: 18px;
          font-family: $gothampromedium;
          color: $color-light;
          font-weight: normal !important;
          border-right: 1px solid #acacac;
          & input {
            text-align: center;
            font-weight: normal !important;
            font-family: GothamPro;
            font-size: 15px;
            color: #acacac;
            width: 100% !important;
            border: none;
            background-color: #fff;
            &::placeholder {
              font-family: GothamPro;
              font-size: 15px;
              color: #acacac;
              font-weight: normal !important;
              @media screen and(max-width: 768px) {
                font-size: 14px;
              }
            }
            @media screen and(max-width: 768px) {
              font-size: 14px;
            }
          }
        }
        &:nth-of-type(2) {
          border-right: 1px solid #acacac;
        }
        &:nth-of-type(3) {
          border-right: 1px solid #acacac;
          &.input {
            border-right: 1px solid #acacac;
            & input {
              text-align: center;
              font-size: 18px;
              font-family: $gothampromedium;
              color: $color-light;
              width: 100% !important;
            }
          }
        }
      }
      & .btn-delete-row {
        //position: absolute;
        //top: 14px;
        //right: 10px;
        & svg {
          width: 20px;
          height: 17px;
          fill: #393939;
          transition: .3s;
          margin-top: 2px;
          &:hover {
            fill: $color-green;
          }
        }
        &:disabled {
          opacity: .2;
          cursor: default;
        }
      }
    }
  }
  & tfoot {
    background-color: $color-green;
    & .table-submit {
      & button {
        height: 45px;
        text-align: left;
        width: 100%;
        font-family: $gothampromedium;
        color: #fff;
        font-size: 18px;
        padding-left: 60px;
        transition: .3s;
        @media screen and(max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    &:hover {
      background-color: $color-violet;
    }
  }

  & .table-zapper {
    //width: 30px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    font-size: 18px;
    font-weight: 400;
    color: #494949;
    font-family: GothamProMedium;
    border-right: 1px solid #acacac;
    @media screen and(max-width: 768px) {
      font-size: 14px;
    }
  }
  & .table-controls {
    //display: flex;
    //justify-content: center;
    //align-items: center;

    position: relative;
    padding-top: 15px;
    padding-bottom: 18px;
    height: 100%;
    width: 135px;
    vertical-align: middle;
    text-align: center;
    //display: flex;
    //flex-direction: row-reverse;
    //align-items: center;
    //justify-content: center;
  }
  & .custom-selectpicker .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    font-size: 15px;
    text-align: center;
  }
}

.constructor-step {
  width: 516px;
  border: 1px solid #acacac;
  margin-bottom: 30px;
  &__header {
    font-size: 18px;
    font-family: $gothampromedium;
    color: $color-grey;
    border-bottom: 1px solid #acacac;
    display: flex;
    justify-content: space-around;
    & div {
      //padding: 24px 41px;
      padding: 7px 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-of-type(1) {
        //width: 30%;
        border-right: 1px solid #acacac;

      }
      &:nth-of-type(2) {
        //width: 70%;
      }
    }
  }
  &__body {
    font-size: 16px;
    font-family: $gothampromedium;
    color: $color-grey;
    & .fieldrow {
      padding: 16px 41px;
      & input {
        color: #acacac;
        font-size: 16px;
        font-family: $gothampro;
        width: 100%;
        &::placeholder {
          color: #acacac;
          font-size: 16px;
          font-family: $gothampro;
        }
      }
      &:nth-of-type(1) {
        border-bottom: 1px solid #acacac;
      }
    }
  }
}

.constructor-confirm {
  margin-bottom: 45px;
  padding-bottom: 20px;
  overflow: hidden;
  & button {
    padding: 13px 34px;
    border-radius: 10px;
    background-color: $color-green;
    font-family: $gothamprobold;
    font-size: 16px;
    color: #fff;
    transition: .3s;
    box-shadow: 1px 5px 17px -3px rgba(0, 0, 0, 0.35);
    &:hover {
      background-color: $color-violet;

      box-shadow: 1px 5px 17px -3px rgba(98, 110, 204, 0.35);
    }
    &:nth-of-type(1) {
      margin-right: 120px;
      @media screen and(max-width: 1440px) {
        margin-bottom: 30px;
      }
      @media screen and(max-width: 768px) {
        font-size: 14px;
      }
    }
    @media screen and(max-width: 768px) {
      font-size: 14px;
    }
  }
}

.table-select {
  & .worksheet-item {
    margin-bottom: 0;
  }
  & .custom-selectpicker .bootstrap-select .dropdown-toggle .filter-option {
    border: none;
    background-color: #fff;
    border-radius: 0;
    border-color: #fff;
  }
  & .custom-selectpicker .dropdown-toggle::after {
    top: 1px;
  }
  & .bootstrap-select .dropdown-menu li {
    text-align: center;
  }
  & .btn-light:hover {
    border-color: #fff;
    border: 1px solid #fff;
  }
  & .show > .btn-light.dropdown-toggle {
    border-color: #fff;
  }
  & .datepicker {
    padding-right: 0;
  }
}

//.table-controls {
//  &.table-zapper {
//    width: 135px;
//  }
//}

.table-editable2 {
  /* width */
  & ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #908c8c;
    border-radius: 10px;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: $color-light;
  }

  & .worksheet-item {
    &.custom-tag-selector {
      flex-direction: row;
      align-items: center;

    }
  }
  & .custom-tag-selector .select2-selection.select2-selection--multiple {
    background-color: #fff;
  }
  & .custom-tag-selector .select2-container {
    background-color: #fff;
  }
  & .select2-search__field {
    //display: none;
  }
  & .select2.select2-container.select2-container--focus {
    & .select2-search__field {
      display: none;
    }
  }
  & .custom-tag-selector .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #fff;
    color: #acacac;
    font-size: 16px;
    padding: 4px 11px;
  }
  & .custom-tag-selector .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    right: 0px;
    top: -3px;
    color: #acacac;
    transition: .2s;
    &:hover {
      color: $color-violet;
    }
  }
}

.datepicker-wrapper {
  position: relative;
}

#datepicker {
  border: none;
  background-color: #fff;
  border-radius: 0;
  font-family: GothamPro;
  font-size: 15px;
  color: #acacac;
}

.Zebra_DatePicker {
  border: none;
  box-shadow: 3px 5px 7px 0px #ccc;
}

.Zebra_DatePicker .dp_actions .dp_caption {
  font-family: $gothampro;
  font-size: 14px;
  color: #393939;
}

.Zebra_DatePicker .dp_daypicker th {
  color: #626ecc;
}

.Zebra_DatePicker .dp_body .dp_current {
  color: #626ecc;
}

.Zebra_DatePicker td, .Zebra_DatePicker th {
  border-radius: 14px;
  color: #737373;
  font-family: GothamPro;
}

.Zebra_DatePicker .dp_body .dp_selected {
  background-color: #626ecc;
}

.dp_previous, .dp_next {
  font-size: 21px;
}

.table-wrapper {
  margin-bottom: 103px;
}

@media screen and(max-width: 1550px) {
  .table-wrapper {
    overflow-y: scroll;
    //padding-bottom: 15px;
  }
}

@media screen and(max-width: 1440px) {
  .table-wrapper {
    overflow-y: scroll;
    //padding-bottom: 15px;
  }
}

.Zebra_DatePicker_Icon_Wrapper {
  float: none;
  position: inherit;
  display: block;
}

.cabinet-table {
  @media screen and(max-width: 1440px) {
    overflow-y: scroll;
  }
}

.step-table {
  max-width: 1080px;
  overflow-y: scroll;

  & thead {
    & tr {
      & th {
        text-align: center;
        padding: 10px 5px;
        font-family: $gothampromedium;
        font-size: 18px;
        color: #494949;
        &:nth-of-type(1) {
          border-right: 1px solid $color-light;
        }
        @media screen and(max-width: 768px) {
          font-size: 14px;
        }
      }
      & td {
        padding: 15px 0px !important;
      }
    }
  }
  & tr {
    & td {
      width: 65px !important;
      color: $color-grey !important;
      font-family: $gothampromedium !important;
      padding: 5px 0px !important;
      & input {
        &.timepicker {
          width: 65px;
          text-align: center;
          font-size: 17px;
          color: $color-light;
          @media screen and(max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.ui-timepicker-standard {
  width: 90px;
}

.ui-timepicker {
  width: 100px;
  & .ui-timepicker-viewport {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border-radius: 2px;
    }
    & .ui-corner-all {
      font-family: $gothampro;
      font-size: 14px;
    }
  }
}

.table-select {
  & .input-group-append, .input-group-prepend {
    display: none;
  }
  //& .table-step{
  //  margin-right: 10px;
  //}
}

.custom-table {
  width: 700px;
  //margin-bottom: 103px;
  border: 1px solid #acacac;
  padding: 0;
  & thead {
    border-bottom: 1px solid #acacac;
    font-family: $gothampro;
    & tr {
      & th {
        width: 265px;
        font-family: GothamPro;
        font-size: 18px;
        padding: 10px 1px;
        font-weight: normal;
        color: #494949;
        font-family: $gothampromedium;
        text-align: center !important;
        border-right: 1px solid #acacac;
        &:nth-of-type(1) {
          border-right: 1px solid #acacac;
          border-bottom: 1px solid #acacac;
          width: 135px;
          //width: 30%;
        }
        &:nth-of-type(2) {
          //width: 70%;
          border-bottom: 1px solid #acacac;
        }
        &:last-child {
          border-right: 1px solid #acacac;
          //text-align: left !important;
          padding-left: 20px;
        }
        @media screen and(max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  & tbody {
    & tr {
      position: relative;
      height: 55px;
      border: 1px solid #acacac;
      padding: 15px 10px;
      & td {
        & input {
          text-align: left;
          font-weight: normal !important;
          font-family: GothamPro;
          font-size: 15px;
          color: #acacac;
          width: 100%;
          border: none;
          background-color: #fff;
          padding-left: 20px;
          &::placeholder {
            font-family: GothamPro;
            font-size: 15px;
            color: #acacac;
            font-weight: normal !important;
            @media screen and(max-width: 768px) {
              font-size: 14px;
            }
          }
          @media screen and(max-width: 768px) {
            font-size: 14px;
          }
        }
        &:nth-of-type(2) {
          border-right: 1px solid #acacac;
        }
        &:nth-of-type(3) {
          border-right: 1px solid #acacac;
          &.input {
            border-right: 1px solid #acacac;
            & input {
              text-align: center;
              font-size: 18px;
              font-family: $gothampromedium;
              color: $color-light;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  @media screen and(max-width: 690px) {
    overflow-y: scroll;
  }
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.table-editable li.select2-search.select2-search--inline {
  display: none;
}

.cabinet-content__title {
  position: relative;
  max-width: fit-content;
}

.cabinet-content__title svg {
  position: absolute;
  top: 8px;
  right: -17px;
  fill: #b34039;
  width: 15px;
  height: 15px;
  transition: .2s;
  @media screen and(max-width: 330px){
    top: 43px;
    right: 6px;
  }
  &:hover {
    fill: $color-violet;
    cursor: pointer;
  }
}

.cabinet-content__wrapper.price-wrapper {
  & .tooltiptable {
    transition: .9s;
    background-color: #fff;
    font-family: HelveticaneuecyrRoman;
    font-size: 14px;
    color: #393939;
    max-width: 520px;
    border-radius: 20px;
    padding: 20px 15px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .5);
    line-height: 19px;
    position: absolute;
    top: 33px;
    right: 25%;
    z-index: 6;
    display: none;
    &.active {
      display: block;
    }
  }
}


.table-editable2 thead tr th:last-child{
  position: relative;
}

.table-editable2 thead tr th:last-child svg {
  position: absolute;
  top: 14px;
  right: 9px;
  fill: #b34039;
  width: 15px;
  height: 15px;
  transition: .2s;
  @media screen and(max-width: 330px){
    top: 43px;
    right: 6px;
  }
  &:hover {
    fill: $color-violet;
    cursor: pointer;
  }
}

.table-editable2 thead tr th:last-child {
  & .tooltiptable {
    transition: .9s;
    background-color: #fff;
    font-family: HelveticaneuecyrRoman;
    font-size: 14px;
    color: #393939;
    max-width: 520px;
    border-radius: 20px;
    padding: 20px 15px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .5);
    line-height: 19px;
    position: absolute;
    top: 33px;
    right: 25%;
    z-index: 6;
    display: none;
    &.active {
      display: block;
    }
  }
}