.map-detail {
  background: #fff;
  padding: 17px 13px;
  max-width: 337px;
  min-height: 205px;
  //border-radius: 15px;
  //box-shadow: 0 0 13px 4px rgba(0, 0, 0, .25);
  @media screen and (max-width: 768px){
    max-width: 310px;
  }
  & .map-detail-inner {
    &__rating {
      margin-bottom: 20px;
      & .stars {
        width: 310px;
        display: flex;
        justify-content: center;
        & svg {
          pointer-events: none;
          fill: $color-green;
          stroke: $color-green;
          stroke-width: 1px;
          width: 20px;
          height: 20px;
          margin-right: 6px;
          &.active {
            fill: $color-green;
            stroke: $color-green;
          }
        }
      }
    }
    &__info {
      display: flex;
      & .left-map-popup {
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: space-between;
        & .quest-capacity {
          width: 80px;
          display: flex;
          align-items: center;
          margin-bottom: 11px;
          & svg {
            fill: #393939;
            height: 25px;
            width: 31px;
          }
        }
        & a {
          color: $color-grey;
          font-size: 14px;
          text-decoration: underline;
          font-family: $gothamprolight;
          margin-bottom: 23px;
          &:hover {
            color: $color-green;
          }
        }
        & button {
          background-color: $color-violet;
          color: #fff;
          font-family: $gothampromedium;
          font-size: 14px;
          padding: 10px 20px;
          border-radius: 10px;
          &:hover {
            background-color: $color-green;
          }
        }
      }
    }
  }
}

.map-detail-title {
  text-align: center;
  font-family: $helveticaneuecyrmedium;
  color: $color-grey;
  font-size: 15px;
  margin-bottom: 5px;
}