.stars {
  display: flex;
  width: 280px;
  justify-content: flex-start;
  //cursor: pointer;
  @media screen and (max-width: 768px){
    width: 170px;
  }
  &.hover {
    .active {
      fill: none;
    }
    .hover {
      fill: #fff;
    }
  }
  svg{
    @include animate(.5);
    stroke: #fff;
    stroke-width: 2px;
    fill: none;
    width: 28px;
    height: 28px;
    margin-right: vw(10px);
    @media screen and (max-width: 991px){
      width: 20px;
      height: 18px;
      margin-right: 5px;
    }
    @media screen and (max-width: 768px){
      width: 28px;
      height: 16px;
      margin-right: 4px;
    }
    &.active,
    &:hover {
      fill: #fff;
    }
  }
}

.stars.disable{
  cursor: default;
  svg{
    //fill: $color-lightGray;
    //stroke:$color-lightGray;
  }
  .hover {
    fill: #fff;
    .active {
      fill: #fff;
    }
  }
}
.stars.disable svg:hover{
  cursor: default;
  svg{
    //fill: $color-lightGray;
    //stroke:$color-lightGray;
  }
}


