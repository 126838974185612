.filter-result {
  & .quest-item-wrapper {
    height: 425px;
    margin-bottom: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1366px) {
      height: 395px;
    }
    @media screen and (max-width: 1200px) {
      height: 330px;
    }
    @media screen and (max-width: 991px) {
      height: 290px;
    }
    & .quest-item {
      height: 100%;
      max-width: 330px;
      width: 100%;
      & a{
        font-size: 20px;
      }
      //margin-bottom: -90px;
    }
    & .quest-name img {
      display: none;
    }
    & .quest-item__rating {
      top: vw(16px);
      left: vw(20px);
      @media screen and (max-width: 1024px) {
        top: 4px;
        left: 59px;
      }
      @media screen and (max-width: 991px) {
        top: 10px;
        left: 78px;
      }
    }
    & .stars {
      max-width: 220px !important;
    }
    & .q-top {
      //min-height: 43%;
      min-height: 55%;
      height: 215px;
      transition: .3s;
      //height: auto;
      @media screen and (max-width: 1200px) {
        height: 172px;
      }
    }
    & .q-bottom {
      margin-top: 5px;
    }
    & .quest-item.active .q-bottom__more a {
      @media screen and (max-width: 1200px) {
        font-size: 10px;
      }
      @media screen and (max-width: 991px) {
        font-size: 9px;
      }
    }
    .quest-item__action-btn {
      bottom: 40px;
    }
    & .q-bottom__btns {
      @media screen and (max-width: 1200px) {
        font-size: 10px !important;
      }
      @media screen and (max-width: 991px) {
        font-size: 9px !important;
      }
    }

    .quest-item.active .q-bottom {
      padding-bottom: vw(10px);
      @media screen and (max-width: 991px) {
        padding-bottom: 7px;
      }
    }
    .quest-item.active .q-bottom__btns {
      margin-bottom: vw(11px);
      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
    .quest-item.active .q-bottom__btns .quest-capacity svg {
      @media screen and (max-width: 1200px) {
        width: 23px;
        height: 20px;
      }
    }
    .quest-item__action-btn a {
      @media screen and (max-width: 1280px) {
        font-size: 13px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 10px;
      }
      @media screen and (max-width: 991px) {
        font-size: 9px;
      }
    }
    .quest-item__icons {
      top: 15px;
      right: 16px;
      @media screen and (max-width: 991px) {
        top: 10px;
        right: 10px;
      }
      & .level_brain {
        width: vw(35px);
        height: vw(35px);
        margin-bottom: vw(15px);
        @media screen and (max-width: 991px) {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
        & svg {
          width: vw(20px);
          height: vw(20px);
          @media screen and (max-width: 991px) {
            width: 20px;
            height: 20px;
          }
        }
      }
      & .level_mask {
        width: vw(35px);
        height: vw(35px);
        @media screen and (max-width: 991px) {
          width: 30px;
          height: 30px;
        }
        & svg {
          width: vw(20px);
          height: vw(20px);
          @media screen and (max-width: 991px) {
            width: 20px;
            height: 20px
          }
        }
      }
    }
    & .quest-item__discount {
      top: 43px;
      @media screen and (max-width: 1024px) {
        left: 9px;
        top: 32px;
        font-size: 9px;
        & .discount-percent {
          margin-bottom: 9px;
        }
      }
      & .discount-percent {
        margin-bottom: 9px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .filter-result .quest-item-wrapper .q-top {
    min-height: 37%;
  }
  .filter-result .quest-item-wrapper .q-bottom__btns {
    @include rfs(14px);
  }
  .q-bottom__btns .quest-capacity {
    width: 86px;
  }
}

//@media screen and (max-width: 1200px) {
//  .filter-result .quest-item-wrapper .q-bottom__btns {
//    font-size: 10px;
//  }
//}
//
//@media screen and (max-width: 991px) {
//  .filter-result .quest-item-wrapper .q-bottom__btns {
//    font-size: 9px;
//  }
//}

.filter-result-more {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 65px;
  margin-bottom: 30px;
  z-index: 7;
  @media screen and (max-width: 1366px){
    padding-top: 0;
  }
  @media screen and (max-width: 568px){
    padding-top: 25px;
  }
  & button {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: $color-green;
    border-radius: 50%;
    transition: .2s;
    &:hover {
      background-color: $color-violet;
      cursor: pointer;
    }
    & svg {
      width: 25px;
      height: 31px;
      fill: #fff;
    }
  }
}

.home-map {
  & iframe {
    @media screen and (max-width: 768px) {
      max-height: 420px;
    }
    @media screen and (max-width: 415px) {
      max-height: 220px;
    }
  }
}

.filter-result {
  & .stars {
    & svg {
      @media screen and (max-width: 1024px) {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.filter-result .quest-item-wrapper .quest-item .rating-count .star {
  height: 20px;
  left: -104px;
  @media screen and (max-width: 1024px) {
    height: 15px;
  }
  @media screen and (max-width: 991px) {
    left: -115px;
    height: 16px;
  }
}