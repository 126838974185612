.modal-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  overflow: hidden;
  z-index: 20;
  &__content {
    border-radius: 15px;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    position: relative;
    @media (max-height: 850px) {
      //overflow-y: scroll;
      overflow-x: inherit;
    }
    .close-modal {
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: $color-green;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      & svg {
        fill: #fff;
        width: 16px;
        max-width: 16px;
        height: 16px;
        max-height: 16px;
      }
      @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
        & svg {
          width: 10px;
          max-width: 10px;
          height: 10px;
          max-height: 10px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  &.is-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.85;
  background-color: #ffffff;
}

.modal-sertificate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  overflow: hidden;
  z-index: 20;
  &__content {
    border-radius: 15px;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    position: relative;
    @media (max-height: 850px) {
      //overflow-y: scroll;
      overflow-x: inherit;
    }
    .close-modal {
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: $color-green;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      & svg {
        fill: #fff;
        width: 16px;
        max-width: 16px;
        height: 16px;
        max-height: 16px;
      }
      @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
        & svg {
          width: 10px;
          max-width: 10px;
          height: 10px;
          max-height: 10px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  &.is-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-auth {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  overflow: hidden;
  z-index: 20;
  &__content {
    border-radius: 15px;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    position: relative;
    @media (max-height: 850px) {
      //overflow-y: scroll;
      overflow-x: inherit;
    }
    .close-modal {
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: $color-green;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      & svg {
        fill: #fff;
        width: 16px;
        max-width: 16px;
        height: 16px;
        max-height: 16px;
      }
      @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
        & svg {
          width: 10px;
          max-width: 10px;
          height: 10px;
          max-height: 10px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  &.is-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-paid-services{
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 20;
  max-width: 455px;
  margin: 0 auto;
  &.is-active{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}





@import "../../modules/modal/modal-booking.scss";
@import "../../modules/modal/modal-sertificate.scss";
@import "../../modules/modal/modal-auth.scss";
@import "../../modules/modal/modal-paid.scss";
@import "../../modules/modal/modal-auth-success.scss";
@import "../../modules/modal/modal-action.scss";




