.top-quest-links {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 55px;
  @media screen and (max-width: 1560px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 1366px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 870px) {
    position: relative;
    margin-bottom: 55px;
  }

  & .main-links {
    display: flex;
    justify-content: space-between;

    & a {
      color: #fff;
      font-size: 21px;
      font-family: $gothampro;
      transition: .4s;

      &:hover {
        color: $color-violet;
      }

      &.login-links {
        color: $color-green;
      }
    }
  }

  & .system-links {
    //width: 70%;
    width: 100%;
    margin-left: 25px;

    @media screen and (max-width: 1560px) {
      width: 100vw;
    }
    @media screen and (max-width: 870px) {
      width: 71vw;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 0px;
      right: 0;
    }
    @media screen and (max-width: 768px) {
      right: 12px;
      width: 96%;
      &.active {
        top: 0px;
        width: 190px;
        overflow: hidden;
        height: auto;
        right: 3px;
        margin-left: auto;

        & .burger {
          z-index: 5;

          &.active {
            & span {
              &:nth-of-type(2) {
                left: 4px;
              }

              &:nth-of-type(3) {
                right: 3px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 568px) {
      width: 92%;
    }

    & .top-links {
      & a {
        color: #fff;
        transition: .3s;
        font-size: 16px;

        &:last-child {
          //display: none;
        }

        @media screen and (max-width: 768px) {
          color: $color-grey;
          &:hover {
            color: $color-violet;
          }
          &:last-child {
            display: flex;
          }
        }

        &:hover {
          color: $color-green;
        }
      }
    }
  }

  @media screen and (max-width: 870px) {
    & .burger {
      display: none;
      border: 1px solid #fff;
      border-radius: 7px;
      padding: 6px 5px;
      width: 28px;
      height: 28px;
      margin-top: 4px;
      margin-right: 4px;

      &.active {
        border: 1px solid $color-violet;
        position: relative;

        & span {
          width: 100%;
          height: 2px;
          background-color: #fff;
          transition: .3s;

          &:nth-of-type(1) {
            display: none;
          }

          &:nth-of-type(2) {
            position: absolute;
            top: 12px;
            left: 3px;
            transition: .4s;
            background-color: $color-violet;
            transform: rotate(45deg);
            width: 74%;
          }

          &:nth-of-type(3) {
            position: absolute;
            top: 12px;
            right: 4px;
            transition: .4s;
            background-color: $color-violet;
            transform: rotate(-45deg);
            width: 74%;
          }
        }
      }

      @media screen and (max-width: 870px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & span {
          width: 16px;
          height: 2px;
          background-color: #fff;
        }
      }
    }
    & .top-links {
      display: none;

      &.active {
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 16px;
        padding-left: 14px;
        padding-bottom: 23px;
        line-height: 26px;
        height: auto;
        overflow-y: scroll;

        & .burger {
          z-index: 5;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          & div {
            width: 100%;
            height: 2px;
            background-color: $color-violet;

            &:nth-of-type(2) {
              position: absolute;
              top: 12px;
              left: 3px;
              background-color: $color-violet;
              transform: rotate(45deg);
              width: 75%;
            }

            &:nth-of-type(3) {
              position: absolute;
              top: 12px;
              right: 4px;
              background-color: $color-violet;
              transform: rotate(-45deg);
              width: 75%;
            }
          }
        }
      }
    }
  }

  & .logo {
    position: absolute;
    left: -68px;
    top: 0;
    @media screen and (max-width: 1280px) {
      display: none;
    }

    & img {
      max-width: 85px;
    }
  }
  & .account-name{
    color: #fff;
    @media screen and (max-width: 768px){
      color: $color-grey;
      padding-top: 3px;
    }
  }
}

.top-quest-info {
  &__genre {
    text-transform: uppercase;
    font-family: $helveticaneuecyrroman;
    @include rfs(26px);
    color: #fff;
    text-align: center;
    margin-bottom: vw(23px);
    @media screen and (max-width: 560px) {
      font-size: 11px;
      margin-bottom: 10px;
    }
  }

  &__name {
    font-family: $gothampro;
    @include rfs(62px);
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: vw(26px);
    @media screen and (max-width: 560px) {
      font-size: 25px;
      margin-bottom: 35px;
    }
  }

  &__rating {
    margin: 0 auto;
    margin-bottom: vw(26px);
    display: flex;
    justify-content: center;
    @media screen and (max-width: 560px) {
      margin-bottom: 11px;
    }

    & .stars {
      justify-content: center;
    }

    & .rating-count {
      & .star {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        left: -70px;
      }
    }
  }

  &__manufactured {
    font-family: $gothampro;
    @include rfs(27px);
    color: #fff;
    text-align: center;
    margin-bottom: vw(49px);
    @media screen and (max-width: 560px) {
      font-size: 11px;
      margin-bottom: 20px;
    }
  }

  &__description {
    max-width: 583px;
    text-align: center;
    margin: 0 auto;
    font-family: $gothampro;
    @include rfs(27px);
    line-height: 38px;
    color: #fff;
    margin-bottom: vw(100px);
    @media screen and (max-width: 1024px) {
      margin-bottom: 17px;
    }
    @media screen and (max-width: 560px) {
      font-size: 11px;
      line-height: 15px;
      margin-bottom: 15px;
      max-width: 240px;
    }
  }

  &__action-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    & a {
      border-radius: 30px;
      text-align: center;
      background-color: #d24a43d4;
      color: #fff;
      font-size: 32px;
      font-family: $helveticaneuecyrroman;
      padding: 12px 45px;
      transition: .3s;
      text-decoration: none;
      z-index: 2;

      &:hover {
        background-color: #d24a43;
        text-decoration: none;
      }

      @media screen and (max-width: 560px) {
        font-size: 16px;
        margin-bottom: 15px;
        padding: 8px 42px;
      }
    }
  }
}

.relative-container {
  position: relative;

  & .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    //position: absolute;
    //left: 4px;
    //top: 23px;
    & img {
      max-width: 100px;
      @media screen and (max-width: 1440px) {
        max-width: 70px;
      }
    }
  }
}

.quest-adress-wrapper {
  position: absolute;
  top: 0;
  right: -170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1500px) {
    top: 161px;
    right: 0px;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }

  &__icon {
    & svg {
      fill: #fff;
      width: vw(54px);
      height: vw(72px);
    }
  }

  &__link {
    text-align: center;

    & a {
      text-decoration: none;
      color: $color-green;
      @include rfs(21px);
      font-family: $helveticaneuecyrroman;
      transition: .3s;
      border-bottom: 1px solid $color-green;
      padding-bottom: 1px;

      &:hover {
        color: $color-violet;
        border-bottom: 1px solid $color-violet;
      }
    }
  }

  & .quest_adress {
    max-width: 220px;

    & .adress__city {
      text-align: center;
      color: #fff;
      @include rfs(30px);
      font-family: $helveticaneuecyrroman;
      margin-bottom: 15px;
    }

    & .adress__street {
      text-align: center;
      color: #fff;
      @include rfs(21px);
      font-family: $helveticaneuecyrroman;
      margin-bottom: 10px;
    }
  }
}

.mobile-adress {
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    & .quest-adress-wrapper {
      position: initial;
      display: flex;
      margin-bottom: 40px;

      &__icon {
        display: none;
      }

      & .quest_adress {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
          margin-bottom: 10px;
        }

        & .adress__city {
          font-size: 16px;
          margin-right: 10px;
          margin-bottom: 0;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        & .adress__street {
          font-size: 16px;
          margin-bottom: 0;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      &__link {
        & a {
          font-size: 16px;
          @media screen and (max-width: 560px) {
            font-size: 10px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }
}

.quest-page-navigation {
  & .quest-category {
    & a {
      background-color: rgba(57, 57, 57, 0.25);
      color: #fff;
      border: #fff 1px solid;

      &:hover {
        background-color: #fff;
        color: $color-violet;
        border: #fff 1px solid;
      }
    }
  }
}

.top-quest-links {
  &.active {
    & .system-links {
      &.active {
        //width: 204px;
        width: auto;
        & .top-links {
          &.active {
            padding-right: 10px;
            & a {
              color: $color-grey !important;

              &.cabinet-link {
                color: $color-green;
                transition: .3s;

                & svg {
                  fill: $color-green;
                  display: block;
                }

                &:hover {
                  color: $color-violet;
                }
              }
            }
          }
        }

        & .signin-menu {
          position: initial;
          top: 138px;
          height: auto;
          line-height: 27px;
          //overflow: hidden;
          background-color: initial;
          padding-top: 0;
          padding-left: 0;
          margin-top: 5px;

          &.active {
            & a {
              color: $color-grey;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  & .lang-select {
    justify-content: flex-end;
    margin-right: 20px;
    @media screen and (max-width: 991px) {
      width: auto;
    }
    @media screen and (max-width: 768px) {
      position: absolute;
      top: 15px;
      right: 0;
      justify-content: flex-start;
      width: 100%;
      margin-right: 0;
    }
  }
}

.top-quest-links .lang-select .city .dropdown-select-list {
  left: 80px;
  @media screen and (max-width: 991px) {
    left: -15px;
    width: auto;
  }
  @media screen and (max-width: 768px) {
    position: inherit;
    left: auto;
    right: 0;
    top: 17px;
    justify-content: flex-start;
    width: auto;
    margin-right: 0;
  }
}

.top-quest-links .system-links.active {
  @media screen and (max-width: 768px) {
    //  top: 117px;
    //  line-height: 16px;
    //  overflow: hidden;
    //  background-color: initial;
    //  padding-top: 4px;
    //  & a {
    //    color: $color-grey;
    //    font-size: 14px;
    //  }
    //}
  }
}


.top-quest-links .system-links .top-links .cabinet-link-signin svg {
  fill: #fff;
}


.top-quest-links .signin-menu.active a {
  color: $color-grey;
}


.quest_adress-wrapper {
  height: auto;
  overflow: hidden;
  &.containe{
    height: 195px;
  }
  @media screen and (max-width: 1024px) {
    height: 95px;
    flex-direction: column !important;
    align-items: center !important;
    & .adress__city {
      margin-right: 0 !important;
    }
  }

  &.active {
    height: auto;
  }

}

.quest-adress-wrapper__more {
  line-height: 10px;
  z-index: 2;
  display: none;
  &.visible {
    display: block;
  }

  & button {
    color: $color-green;
    font-size: 21px;
    font-family: $helveticaneuecyrroman;
    padding-bottom: 2px;
    border-bottom: 1px solid $color-green;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }

    & span {
      &:nth-of-type(2) {
        display: none;
      }
    }

    &.active {
      & span {
        &:nth-of-type(1) {
          display: none;
        }

        &:nth-of-type(2) {
          display: block;
        }
      }
    }
  }
}