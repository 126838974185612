.modal_booking {
  //padding: vw(60px) vw(300px);
  padding: 25px 215px;
  border-radius: 15px;
  background-image: url("/img/booking-modal-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  &__title {
    @include rfs(30px);
    font-family: $gothampro;
    color: $color-grey;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;
    line-height: 28px;
    @media screen and (max-width: 1366px){
      @include rfs(24px);
    }
    & span {
      &:nth-of-type(1) {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 768px){
      font-size: 18px;
    }
    @media screen and (max-width: 568px) {
      font-size: 16px;
    }
    & .sertificate-type {
      font-family: $helveticaneuecyrroman;
      font-size: 30px;
      text-transform: uppercase;
      &_silver {
        color: #949696;
      }
      &_gold {
        color: #bd904d;
      }
      &_diamond {
        color: #5ab5bb;
      }
    }
  }

  & .modal-sertificate-cost {
    margin-bottom: 13px;
    text-align: center;
    & div {
      font-size: 28px;
      @media screen and (max-width: 1366px){
        font-size: 24px;
      }
      @media screen and (max-width: 768px){
        font-size: 16px;
      }
      &:nth-of-type(1) {
        font-family: $gothampro;
        color: $color-grey;
      }
      &:nth-of-type(2) {
        color: #0dcdae;
        font-family: $helveticaneuecyrbold;
      }
    }
  }
  & .additional-information {
    color: #0dcdae;
    font-family: $gothampro;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    & span {
      &:nth-of-type(1) {
        margin-right: 5px;
      }
    }
  }
  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $helveticaneuecyrroman;
    @include rfs(16px);
    color: $color-grey;
    margin-bottom: vw(15px);
    @media screen and (max-width: 568px) {
      font-size: 14px;
    }
  }
  &__form {
    max-width: 515px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-right: vw(20px);
    padding-left: vw(20px);
    & input {
      border: 1px solid $color-light;
      font-family: $gothampro;
      @include rfs(16px);
      color: $color-light;
      padding: 12px 19px;
      border-radius: 15px;
      margin-bottom: vw(25px);
      @media screen and (max-width: 1366px){
        margin-bottom: 10px;
        padding: 7px 19px;
        border-radius: 10px;

      }
      @media screen and (max-width: 568px) {
        padding: 8px 12px;
        margin-bottom: 9px;
        font-size: 13px;
      }
      &::placeholder {
        font-family: $gothampro;
        @include rfs(16px);
        color: $color-light;
        @media screen and (max-width: 568px) {
          font-size: 13px;
        }
      }
    }
    & .sertificate-delivery-info__title {
      color: $color-grey;
      font-size: 20px;
      font-family: $gothampro;
      margin-bottom: 15px;
      text-align: center;
    }
  }
  &__subscribe {
    max-width: 480px;
    margin-bottom: 30px;
    @media screen and (max-width: 1366px){
      margin-bottom: 10px;
    }
    & .filter-checker {
      & label {
        font-family: $helveticaneuecyrroman;
        @include rfs(14px);
        color: $color-grey;
        @media screen and (max-width: 568px) {
          font-size: 11px;
        }
      }
      &:nth-of-type(1) {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 568px) {
      margin-bottom: 20px;
    }
  }
  &__submit {
    background-color: $color-violet;
    font-family: $gothampro;
    @include rfs(20px);
    border-radius: 20px;
    padding: 15px 46px;
    color: #fff;
    margin: 0 auto;
    @media screen and (max-width: 568px) {
      border-radius: 15px;
      padding: 11px 52px;
    }
  }
  @media screen and (max-width: 568px) {
    padding: 15px 15px;
  }
}
