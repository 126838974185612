.contact-quest {
  margin-bottom: 163px;
  max-height: 416px;
  position: relative;
  @media screen and (max-width: 768px) {
    margin-bottom: 265px;
  }
  @media screen and (max-width: 560px) {
    margin-bottom: 318px;
  }
}

.contact-card {
  position: absolute;
  top: 186px;
  left: 10%;
  border-radius: 20px;
  box-shadow: 0 10px 23.5px 3.5px rgba(0,0,0,.09), 0 3px 7px 0 rgba(0,0,0,.35);
  max-width: 460px;
  padding-top: 36px;
  padding-left: 41px;
  padding-right: 123px;
  background-color: #fff;


  @media screen and (max-width: 1366px) {
    padding-left: 41px;
    padding-right: 130px;
  }
  @media screen and (max-width: 768px) {
    top: 80%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    max-width: 470px;
  }
  @media screen and (max-width: 560px) {
    top: 94%;
    width: 75%;
    padding: 15px 8px;
    left: -32px;
  }
  @media screen and (max-width: 320px) {
    width: 86%;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media screen and (max-width: 560px) {
      margin-bottom: 33px;
      &:nth-of-type(3) {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    width: 48px;
    min-width: 48px;
    height: 48px;
    background-color: $color-green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;

    & svg {
      fill: #fff;
      width: 30px;
      height: 17px;
    }
  }

  &__info {
    color: $color-grey;
    font-size: 18px;
    font-family: $helveticaneuecyrmedium;
    @media screen and (max-width: 768px) {
      font-size: 13px;
    }

    & a {
      text-decoration: none;
      transition: .3s;
      color: $color-grey;
      font-size: 18px;
      font-family: $helveticaneuecyrmedium;
      @media screen and (max-width: 768px) {
        font-size: 13px;
        line-height: 26px;
      }

      &:hover {
        color: $color-violet;
      }
    }

    &_col {
      display: flex;
      flex-direction: column;
      line-height: 25px;
    }
  }

  &_inherit {
    position: inherit;
    padding: 0;
    box-shadow: none;
    margin-left: 15px;
    background-color: inherit;
    box-shadow: none;

    .contact-card__info {
      font-size: 12px;
    }

    .contact-card__info a {
      font-size: 12px;
    }

    .contact-card__item {
      margin-bottom: 3px;

      & .contact-card__icon {
        width: 25px;
        height: 25px;
        min-width: 25px;

        & svg {
          width: 17px;
        }
      }
    }

    & a {
      text-decoration: none !important;
      font-family: $helveticaneuecyrmedium !important;
    }
  }
}

.quest-outdoore {
  background-repeat: no-repeat;
  background-size: cover;

  & .contact-card {
    left: 0;
  }

  & .contact-quest {
    height: 420px;
    @media screen and (max-width: 990px){
      height: auto;
      max-height: initial;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 40px
    }
    @media screen and (max-width: 320px){
      .contact-quest{

      }
    }
  }

  & .quest-outdoore-inner {
    max-width: 1040px;
    margin: 0 auto 5.875vw;
    width: 100%;
    position: relative;
    @media screen and (max-width: 1280px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    & .contact-card-custom {
      position: absolute;
      top: -25px;
      right: 0%;
      padding-left: 45px;
      padding-right: 45px;
      border-radius: 20px;
      box-shadow: 0px 10px 23.5px 3.5px rgba(0, 0, 0, 0.09), 0px 3px 7px 0 rgba(0, 0, 0, 0.35);
      max-width: 460px;
      padding-top: 39px;
      background-color: #fff;
      @media screen and (max-width: 1366px) {
        padding-left: 30px;
        padding-right: 30px;
      }
      @media screen and (max-width: 1024px) {
        top: 20px;
        right: 20px;
      }
      @media screen and (max-width: 990px) {
        position: initial;
        padding-bottom: 20px;
        margin-bottom: 40px;
      }
      //@media screen and (max-width: 768px) {
      //  top: 80%;
      //  position: absolute;
      //  margin-left: auto;
      //  margin-right: auto;
      //  left: 0;
      //  right: 0;
      //  padding-top: 40px;
      //  padding-left: 50px;
      //  padding-right: 50px;
      //  max-width: 470px;
      //}
      //@media screen and (max-width: 560px) {
      //  top: 94%;
      //  width: 75%;
      //  padding: 15px 8px;
      //  left: -32px;
      //}
      //@media screen and (max-width: 320px) {
      //  width: 86%;
      //}

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 48px;

        @media screen and (max-width: 560px) {
          margin-bottom: 33px;
          &:nth-of-type(3) {
            margin-bottom: 0;
          }
        }
      }

      &__icon {
        width: 48px;
        min-width: 48px;
        height: 48px;
        background-color: $color-green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;

        & svg {
          fill: #fff;
          width: 30px;
          height: 17px;
        }
      }

      &__info {
        color: $color-grey;
        font-size: 18px;
        font-family: $helveticaneuecyrmedium;
        @media screen and (max-width: 768px) {
          font-size: 13px;
        }

        & a {
          text-decoration: none;
          transition: .3s;
          color: $color-grey;
          font-size: 18px;
          font-family: $helveticaneuecyrmedium;
          @media screen and (max-width: 768px) {
            font-size: 13px;
            line-height: 26px;
          }

          &:hover {
            color: $color-violet;
          }
        }

        &_col {
          display: flex;
          flex-direction: column;
          line-height: 25px;
        }
      }

      &_inherit {
        position: inherit;
        padding: 0;
        box-shadow: none;
        margin-left: 15px;
        background-color: inherit;
        box-shadow: none;

        .contact-card__info {
          font-size: 12px;
        }

        .contact-card__info a {
          font-size: 12px;
        }

        .contact-card__item {
          margin-bottom: 3px;

          & .contact-card__icon {
            width: 25px;
            height: 25px;
            min-width: 25px;

            & svg {
              width: 17px;
            }
          }
        }

        & a {
          text-decoration: none !important;
          font-family: $helveticaneuecyrmedium !important;
        }
      }
    }

    & .contact-card {
      @media screen and (max-width: 1024px) {
        top: 20px;
        left: 20px;
      }
      @media screen and (max-width: 990px) {
        position: initial;
        padding-bottom: 20px;
        width: 100%;

      }
    }
  }
}


.contact-card__text{
  overflow-y: auto;
  height: 160px;
}