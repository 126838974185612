.related-banner {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 60px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;
  @media screen and (max-width: 575px) {
    height: 245px;
  }
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
    background-color: rgba(51, 50, 50, .30);
  }
  &__info {
    position: absolute;
    left: 15%;
    top: 30%;
    max-width: 820px;
    & .blog-date {
      color: #fff;
    }
    & .blog-rating {
      & .stars {
        &.hover {
          & svg {
            &.hover {
              stroke: #fff;
              fill: #fff;
            }
          }
        }
        & svg {
          stroke: #fff;
          fill: #fff;
          &:hover {
            stroke: #fff;
            fill: #fff;
          }

          &.active {
            stroke: #fff;
            fill: #fff;
            &.hover {
              stroke: #fff;
              fill: #fff;
            }
          }
        }
      }
    }
    & .blog-views {
      color: #fff;
      & svg {
        fill: #fff;
      }
    }
    & .blog-comment-count {
      color: #fff;
      & svg {
        fill: #fff;

      }
    }
  }
}

.related-title {
  & a {
    color: #fff;
    font-size: 38px;
    font-family: $gothampromedium;
    margin-bottom: 33px;
    text-decoration: none;
    transition: .3s;
  }
}

.related-wrapper {
  & .col-md-6.col-sm-6 {
    & .related-banner {
      height: 245px;
      margin-bottom: 10px;
      &__info {
        left: 10px;
        bottom: 0;
      }
      & .related-title {
        & a {
          font-size: 16px;
          font-family: $gothampromedium;
          text-transform: uppercase;
        }
      }
    }
  }
}

.big-post {
  padding-right: 0;
  padding-left: 0;
  @media screen and (max-width: 991px) {
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  & .related-banner {
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }
    &__info {
      max-width: 665px;
      left: 15px;
      bottom: 40px;
      top: auto;
      & .related-title {
        margin-bottom: 20px;
        @media screen and (max-width: 1055px) {
          max-width: 75%;
        }
        & a {
          font-size: 21px;
          text-transform: uppercase;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      & .blog-statistics {
        margin-bottom: 20px;

        & .blog-date {
          font-size: 14px;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
        & .blog-views {
          font-size: 14px;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }

        }
        & .blog-comment-count {
          font-size: 14px;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.related-wrapper {
  & .col-md-6.col-sm-6 {
    &.small-post {
      padding-right: 0;
      padding-left: 10px;
      @media screen and (max-width: 1055px) {
        padding-left: 5px;
        padding-right: 5px;
        overflow: hidden;
      }
      & .related-banner {
        &__info {
          left: 10px;
          bottom: 20px;
          top: auto;
          & .related-title {
            @media screen and (max-width: 1366px) {
              max-width: 90%;
            }

            @media screen and (max-width: 1120px) {
              max-width: 75%;
            }
            margin-bottom: 5px;
            & a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
          & .blog-statistics {
            margin-bottom: 20px;

            & .blog-date {
              font-size: 12px;
              font-size: 12px;
              margin-bottom: -7px;
              @media screen and (max-width: 1120px) {
                margin-right: 5%;
              }
            }
            & .blog-views {
              font-size: 12px;

            }
            & .blog-comment-count {
              font-size: 12px;
            }
          }
          & .blog-rating {
            & .stars {
              & svg {
                width: 13px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
}
