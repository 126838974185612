.rating-count {
  position: relative;
  width: 320px;
  & .star {
    position: absolute;
    left: -90px;
    top: -15px;
    fill: #e9f345;
    stroke: #e9f345;

    stroke-width: 1;
    display: flex;
    width: 100%;
    height: 28px;
    justify-content: flex-start;
    //cursor: pointer;
    @media screen and (max-width: 1024px) {
      height: 17px;
      left: -56px;
      top: -10px;
      overflow: hidden;
      width: 61%;
    }
    @media screen and (max-width: 768px) {
      width: 195px;
      top: -14px;
      left: -48px;
      height: 17px;
    }
    & use {
      margin-right: 5px;
    }
  }
}

/* Half-filled star using gradient
   --------------------------------
   (CSS gradient does not work here)
*/

//.star-25 {
//  fill:url(#halfGradient25);
//}
//.star-50 {
//  fill:url(#halfGradient50);
//}
//.star-75 {
//  fill:url(#halfGradient75);
//}

.blogstar-10 {
   fill: url(#ColorGreenHalfGradient10)
 }

.blogstar-20 {
  fill: url(#ColorGreenHalfGradient20)
}

.blogstar-30 {
  fill: url(#ColorGreenHalfGradient30)
}

.blogstar-40 {
  fill: url(#ColorGreenHalfGradient40)
}

.blogstar-50 {
  fill: url(#ColorGreenHalfGradient50)
}

.blogstar-60 {
  fill: url(#ColorGreenHalfGradient60 )
}

.blogstar-70 {
  fill: url(#ColorGreenHalfGradient70)
}

.blogstar-80 {
  fill: url(#ColorGreenHalfGradient80)
}

.blogstar-90 {
  fill: url(#ColorGreenHalfGradient90)
}

.blogstar--empty, .blogstar-0 {
  fill: none;
  stroke: #e9f345;
  stroke-width: 1px
}



.star-10 {
  fill: url(#halfGradient10)
}

.star-20 {
  fill: url(#halfGradient20)
}

.star-30 {
  fill: url(#halfGradient30)
}

.star-40 {
  fill: url(#halfGradient40)
}

.star-50 {
  fill: url(#halfGradient50)
}

.star-60 {
  fill: url(#halfGradient60)
}

.star-70 {
  fill: url(#halfGradient70)
}

.star-80 {
  fill: url(#halfGradient80)
}

.star-90 {
  fill: url(#halfGradient90)
}






.starColor-10 {
  fill: url(#ColorHalfGradient10)
}

.starColor-20 {
  fill: url(#ColorHalfGradient20)
}

.starColor-30 {
  fill: url(#ColorHalfGradient30)
}

.starColor-40 {
  fill: url(#ColorHalfGradient40)
}

.starColor-50 {
  fill: url(#ColorHalfGradient50);
}

.starColor-60 {
  fill: url(#ColorHalfGradient60)
}

.starColor-70 {
  fill: url(#ColorHalfGradient70)
}

.starColor-80 {
  fill: url(#ColorHalfGradient80)
}

.starColor-90 {
  fill: url(#ColorHalfGradient90)
}

.star--empty, .star-0 {
  fill: none;
  stroke: #e9f345;
  stroke-width: 1px
}

//.star--empty {
//  fill: none;
//  stroke: #fff;
//  stroke-width: 1;
//}

/* Not so important styles: */

//.star {
//  height: 5em;
//}

.star-source {
  position: absolute;
  width: 280px;
  height: 30px;
  top: 0;
  left: 0;
}

.stars-wrap {
  width: 100%;
  position: relative;
}

/* IE11 */

svg {
  display: inline-block;
  vertical-align: top;
}

.blog-rating {
  & .rating-count {
    position: initial;
    width: 125px;
    & .star {
      position: absolute;
      left: 0;
      top: -15px;
      fill: $color-green;
      stroke: $color-green;
      stroke-width: 1;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 19px;
      //cursor: pointer;
      @media screen and (max-width: 1024px) {
        height: 17px;
        left: -56px;
        top: -10px;
        overflow: hidden;
        width: 61%;
      }
      @media screen and (max-width: 768px) {
        width: 195px;
        top: -14px;
        left: -48px;
        height: 17px;
      }
      & use {
        margin-right: 5px;
      }
    }
  }
}