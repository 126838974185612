.blog-preview {
  margin-bottom: 78px;
  display: flex;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
  &__picture {
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
    & img {
      border-radius: 20px;
      max-width: 555px;
      @media screen and (max-width: 1150px) {
        max-width: 360px;
      }
      @media screen and (max-width: 991px) {
        max-width: 555px;
        height: auto;
        width: 100%;
      }
    }
  }
  &__content {
    max-width: 790px;
    width: 100%;
    padding-left: 46px;
    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }
  &__title {
    margin-bottom: 32px;
    & a {
      line-height: 35px;
      font-size: 26px;
      font-family: $gothampromedium;
      color: $color-grey;
      text-transform: uppercase;
      text-decoration: none;
      transition: .3s;
      @media screen and (max-width: 1366px){
        font-size: 22px;
      }
      @media screen and (max-width: 991px) {
        font-size: 21px;
      }
      &:hover {
        color: $color-violet;
      }
    }
  }
  &__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-direction: row;

    }
    @media screen and (max-width: 560px) {
      flex-direction: column;

    }
    & .blog-statistics {
      @media screen and (max-width: 560px) {
        margin-bottom: 10px;
      }
    }
    & .blog-rating-count {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;
      @media screen and (max-width: 560px) {
        align-items: flex-start;
      }
      & .blog-rating {
        @media screen and (max-width: 560px) {
          margin-bottom: 10px;
        }
        & .stars {
          justify-content: flex-end;
          margin-right: 13px;
          @media screen and (max-width: 560px) {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.blog-rating-count {
  @media screen and (max-width: 414px) {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
  }
}

.blog-text {
  font-size: 17px;
  color: $color-light;
  font-family: $gothamprolight;
  margin-bottom: 21px;
  line-height: 24px;
  position: relative;
  //&::after{
  //  content: "...";
  //  position: absolute;
  //  bottom: 0;
  //  right: 0;
  //  background-color: #fff;
  //  /* padding: 2px 10px; */
  //  width: 78px;
  //  text-align: left;
  //}
  @media screen and (max-width: 1366px) {
    font-size: 15px;
  }
}

.blog-statistics {
  display: flex;
  font-family: $helveticaneuecyrroman;
  font-size: 12px;
  @media screen and (max-width: 414px) {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
}

.blog-date {
  font-family: $helveticaneuecyrmedium;
  color: #302f30;
  font-size: 14px;
  text-align: left;
  font-weight: 800;
  margin-right: 55px;
  @media screen and (max-width: 414px) {
    margin-right: 30px;
  }
}

.blog-views {
  display: flex;
  margin-right: 22px;
  font-size: 12px;
  color: $color-light;
  font-family: $helveticaneuecyrroman;
  & svg {
    width: 24px;
    height: 16px;
    fill: $color-light;
  }
}

.blog-comment-count {
  display: flex;
  font-size: 12px;
  color: $color-light;
  font-family: $helveticaneuecyrroman;
  & svg {
    width: 24px;
    height: 16px;
    fill: $color-light;
  }
}

.blog-rating-count {
  & .blog-rating {

  }
  & .blog-votes {
    color: #302f30;
    font-family: $helveticaneuecyrmedium;
    font-size: 14px;
    font-weight: 800;
    @media screen and (max-width: 414px) {
      margin-right: 12px;
    }
  }
}

.blog-rating {
  margin-bottom: 17px;
  width: auto;
  //width: 160px;
  & .stars {
    display: flex;
    width: 280px;
    justify-content: flex-start;
    @media screen and (max-width: 768px) {
      width: 170px;
    }
    &.hover {
      .active {
        fill: none;
      }
      .hover {
        stroke: $color-green;
        fill: $color-green;
      }
    }
    svg {
      @include animate(0.1);
      stroke: $color-green;
      stroke-width: 2px;
      fill: none;
      width: 22px;
      height: 20px;
      margin-right: 2px;
      @media screen and (max-width: 991px) {
        width: 22px;
        height: 20px;
        margin-right: 2px;
      }
      @media screen and (max-width: 768px) {
        width: 22px;
        height: 20px;
        margin-right: 2px;
      }
      @media screen and (max-width: 640px) {
        width: 18px;
        height: 20px;
      }
      &.active,
      &:hover {
        fill: $color-green;
        stroke: $color-green;
      }
    }
  }
}

.blog-read-more{
  color: #fff;
  background-color: $color-green;
  transition: .3s;
  text-decoration: none;
  border-radius: 10px;
  font-family: $helveticaneuecyrmedium;
  font-size: 14px;
  padding: 10px 22px;
  margin-bottom: 26px;
  display: inline-block;
  &:hover{
    background-color: $color-violet;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
  }
}