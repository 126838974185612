.quest-promo{
  &__title{
    color: $color-grey;
    @include rfs(34px);
    font-family: $gothampro;
    margin-bottom: 45px;
    text-align: center;
    @media screen and (max-width: 1366px){
      font-size: 27px;
      margin-bottom: 23px;
    }
    @media screen and (max-width: 768px){
      @include rfs(18px);
      margin-bottom: 25px;
    }
  }
}


.quest-promo {
  margin-bottom: 70px;
  @media screen and (max-width: 1366px) {
    margin-bottom: -40px;
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 991px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    background-image: url(/img/booking-modal-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    //&::before {
    //  content: " ";
    //  display: block;
    //  position: absolute;
    //  left: 0;
    //  top: -17px;
    //  width: 100%;
    //  height: 30px;
    //  background-color: hsla(0,0%,50%,.06);
    //  background-image: -webkit-repeating-linear-gradient(top,#fff,transparent 50%,#fff);
    //  background-image: repeating-linear-gradient(180deg,#fff 0,transparent 50%,#fff);
    //  background-image: -ms-repeating-linear-gradient(top,#fff 0,transparent 50%,#fff 100%);
    //}
  }
  @media screen and (max-width: 560px) {
    background-image: none;
  }
}

.quest-promo{
  & .quest-item{
    & .q-top{
      height: 340px;
      @media screen and (max-width: 1366px){
        height: 256px;
      }
      @media screen and (max-width: 991px){
        height: 195px;
      }
    }
  }
}